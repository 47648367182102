const DEFAULT_FILTERS = {
  focusAges: [
    'Children (Under 10)',
    'Preteen (11-13)',
    'Teens (14-17)',
    'Young Adult (18-20)',
    'Adult (21-64)',
    'Senior (65+)',
  ],
  insurances: [
    'Aetna',
    'Anthem Inc',
    'Blue Cross Blue Shield',
    'Centene Corp',
    'Cigna Health',
    'Humana',
    'Insurance Not Accepted',
    'Medicare',
    'Molina Healthcare Inc.',
    'UnitedHealth',
    'Wellcare',
  ],
  languages: ['English', 'Spanish', 'Chinese', 'French', 'Tagalog', 'Vietnamese', 'Korean', 'German'],
  modalities: ['Individual', 'Couples', 'Family', 'Group'],
  professions: [],
  specialties: [
    'Abuse',
    'Addiction',
    'Adoption',
    'Alcohol Abuse',
    'Anorexia Nervosa',
    'Antisocial Personality Disorder',
    'Anxiety',
    'Attention Deficit Hyperactivity Disorder (ADHD)',
    'Behavioral Issues',
    'Binge Eating',
    'Bipolar Disorder',
    'Borderline Personality Disorder',
    'Bulimia',
    'Codependency',
    'Conduct Disorder',
    'Depression',
    'Divorce',
    'Drug Abuse',
    'Eating Disorders',
    'Family Conflict',
    'Grief',
    'Infidelity',
    'Internet Addiction',
    'Marital and Premarital',
    'Obsessive-compulsive disorder (OCD)',
    'Oppositional Defiant Disorder (ODD)',
    'Panic Disorder',
    'Phobias',
    'Porn Addiction',
    'Post-traumatic stress disorder (PTSD)',
    'Schizophrenia',
    'Seasonal Affective Disorder (SAD)',
    'Self-Harm',
    'Sexual Addiction',
    'Stress',
    'Substance Abuse',
    'Suicidal',
    'None',
  ],
  therapyTypes: [
    'Psychoanalysis and Psychodynamic Therapy',
    'Behavior Therapy',
    'Cognitive Behavior Therapy (CBT)',
    'Humanistic Therapy',
    'Integrative and Holistic Therapy',
  ],
}

export default DEFAULT_FILTERS
