import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '16px',
    padding: '32px',
    borderRadius: '6px',
    width: '418px',
    height: '264px',
    boxShadow:
      '0px 0px 0px 0px #0000001A, 0px 2px 5px 0px #0000001A, 0px 9px 9px 0px #00000017, 0px 21px 13px 0px #0000000D, 0px 38px 15px 0px #00000003, 0px 59px 16px 0px #00000000',
    background: '#F9FAFB',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '430px',
      height: 'auto',
    },
  },
  headerText: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color: 'black',
  },
  subText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#535457',
  },
}))

export default function RequirementBox(props) {
  const classes = useStyles()
  return (
    <div className={classes.box}>
      <Typography variant="h5" className={classes.headerText}>{props.header}</Typography>
      <Typography variant="body1" className={classes.subText}>
        {props.description}
        <Typography variant="body1" className={classes.subText} style={{ fontStyle: 'italic' }}>
          {props.italics}
        </Typography>
      </Typography>
    </div>
  )
}

RequirementBox.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  italics: PropTypes.string
}