/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import validator from 'validator'
import { Link as RouterLink } from '@reach/router'
import { useSnackbar } from 'notistack'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Typography,
  LinearProgress,
  Link,
} from '@material-ui/core'
import {
  AccountCircle as AccountCircleIcon,
  Payment as PaymentIcon,
  Assignment as AssignmentIcon,
} from '@material-ui/icons'
import clsx from 'clsx'
import { donate } from '../utils/auth-api'
import AccountDonateForm from './DonateForms/AccountDonateForm'
import BillingInfoForm from './DonateForms/BillingInfoForm'
import ReviewDetailsForm from './DonateForms/ReviewDetailsForm'
import FormErrors from './FormErrors'

const StyledConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(0,11,55,1) 0%, rgb(253,202,68,1) 60%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(0,11,55,1) 0%, rgb(253,202,68,1) 60%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient( 136deg, rgb(255,255,255,1) 0%, rgb(253,202,68,1) 30%, rgb(0,11,55,1) 90%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient( 136deg, rgb(255,255,255,1) 0%, rgb(253,202,68,1) 30%, rgb(0,11,55,1) 90%)',
  },
})

function StepIcon(props) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <AccountCircleIcon />,
    2: <PaymentIcon />,
    3: <AssignmentIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const steps = ['Account Details', 'Billing Info', 'Review']

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 400,
  },
  name: {
    color: theme.palette.primary.main,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  progressBar: {
    maxWidth: 400,
  },
}))

export default function DonateForm() {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [status, setStatus] = useState('idle')
  const [value, setValue] = useState({
    firstName: '',
    middleInitial: '',
    lastName: '',
    email: '',
    phone: '',
    cardFName: '',
    cardLName: '',
    cardNumber: '',
    expDate: '',
    cvc: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    amount: 0,
  })
  const [error, setError] = useState()
  const [formErrors, setFormErrors] = useState([])
  const [total, setTotal] = useState()
  const [invoiceNumber, setInvoiceNumber] = useState()
  // get the subscription plans available on mount

  useEffect(() => {
    let canceled = false
    if (status === 'validate') {
      // Validate inputs
      const errors = []
      if (validator.isEmpty(value.firstName)) {
        errors.push('First name is required')
      }
      if (validator.isEmpty(value.lastName)) {
        errors.push('Last name is required')
      }
      if (!validator.isEmail(value.email)) {
        errors.push('Email is not valid')
      }
      if (validator.isEmpty(value.phone)) {
        errors.push('Phone is required')
      }
      if (validator.isEmpty(value.cardFName)) {
        errors.push('Credit card is missing first name')
      }
      if (validator.isEmpty(value.cardLName)) {
        errors.push('Credit card is missing last name')
      }
      if (!validator.isCreditCard(value.cardNumber)) {
        errors.push('Credit card number is invalid')
      }
      if (value.expDate.length !== 5 || !validator.isNumeric(value.expDate.replace('/', ''))) {
        errors.push('Credit card expiration must use 2 digit month and year')
      }
      if (!validator.isLength(value.cvc, { min: 3, max: 4 })) {
        errors.push('Credit card CVC is required and should be between 3-4 digits')
      }
      if (validator.isEmpty(value.address1)) {
        errors.push('Billing street address is required')
      }
      if (validator.isEmpty(value.city)) {
        errors.push('Billing city is required')
      }
      if (validator.isEmpty(value.state)) {
        errors.push('Billing state is required')
      }
      // if (validator.isEmpty(value.zip)) {
      //   errors.push('Billing zipcode is required')
      // } else if (!validator.isPostalCode(value.zip, 'US')) {
      //   errors.push('Billing zipcode is not valid')
      // }
      // set errors
      setFormErrors(errors)
      // don't submit form if errors
      if (errors.length > 0) {
        setStatus('idle')
        setActiveStep(0)
      } else {
        setStatus('submit')
      }
    }
    if (status === 'submit') {
      setError()

      // format data for backend
      const body = {
        card: {
          firstName: value.cardFName,
          lastName: value.cardLName,
          number: value.cardNumber,
          expirationDate: value.expDate.replace('/', ''),
          code: value.cvc,
        },
        item: {
          price: value.amount,
        },
        person: {
          firstName: value.firstName,
          middleInitial: value.middleInitial,
          lastName: value.lastName,
          email: value.email,
          phone: value.phone,
          password: value.password,
          address: {
            street: value.address1,
            street2: value.address2,
            city: value.city,
            state: value.state,
            postalCode: value.zip,
          },
        },
      }
      donate(body)
        .then((res) => {
          setInvoiceNumber(res.invoiceNumber)
          enqueueSnackbar('Donation successful', { variant: 'success' })
          if (canceled) return
          setStatus('success')
        })
        .catch((err) => {
          enqueueSnackbar('Error in donation process', { variant: 'error' })
          if (canceled) return
          setStatus('failure')
          setError(err.response.statusText)
        })
    } else if (status === 'success') {
      setActiveStep(steps.length)
    } else if (status === 'failure') {
      // TODO: notify of failure to create account - contact admin
    }
    // eslint-disable-next-line consistent-return
    return () => (canceled = true)
  }, [status, value, enqueueSnackbar])

  const handleInputChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AccountDonateForm value={value} handleInputChange={handleInputChange} />
      case 1:
        return <BillingInfoForm value={value} handleInputChange={handleInputChange} />
      case 2:
        return (
          <ReviewDetailsForm
            value={value}
            handleInputChange={handleInputChange}
            updateTotal={setTotal}
            total={total}
          />
        )
      default:
        throw new Error('Unknown step')
    }
  }
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setStatus('validate')
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <div data-widget-src='https://secure.givelively.org/donate/lit-path-inc?ref=sd_widget' id="give-lively-widget"
           className="gl-branded-donation-widget"></div>
      {/*<Grid item xs={12}>*/}
      {/*  <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep} connector={<StyledConnector />}>*/}
      {/*    {steps.map((label) => (*/}
      {/*      <Step key={label}>*/}
      {/*        <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>*/}
      {/*      </Step>*/}
      {/*    ))}*/}
      {/*  </Stepper>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12}>*/}
      {/*  {activeStep === steps.length ? (*/}
      {/*    <>*/}
      {/*      {status === 'submit' && (*/}
      {/*        <div>*/}
      {/*          <Typography variant="h5" gutterBottom>*/}
      {/*            Creating your account*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="h5" gutterBottom>*/}
      {/*            Please wait...*/}
      {/*          </Typography>*/}
      {/*          <br />*/}
      {/*          <LinearProgress color="primary" className={classes.progressBar} />*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*      {status === 'success' && (*/}
      {/*        <>*/}
      {/*          <Typography variant="h5" gutterBottom>*/}
      {/*            Thank you for your donation for Lit Path.*/}
      {/*          </Typography>*/}
      {/*          {invoiceNumber && <Typography variant="h5" gutterBottom>Confirmation number: {invoiceNumber}</Typography>}*/}
      {/*          <Typography variant="subtitle1">*/}
      {/*            Your donation successfully.*/}
      {/*          </Typography>*/}
      {/*          <Link className={classes.button} component={RouterLink} to="/">*/}
      {/*            <Button variant="contained" color="secondary" className={classes.button}>*/}
      {/*              Home*/}
      {/*            </Button>*/}
      {/*          </Link>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {status === 'failure' && (*/}
      {/*        <>*/}
      {/*          <Typography variant="h5" gutterBottom>*/}
      {/*            There was an error.*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="subtitle1">*/}
      {/*            An error occured while donation. Error: {error}*/}
      {/*          </Typography>*/}
      {/*          <div className={classes.buttons}>*/}
      {/*            <Button onClick={handleBack} className={classes.button}>*/}
      {/*              Back*/}
      {/*            </Button>*/}
      {/*          </div>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <>*/}
      {/*      {getStepContent(activeStep)}*/}
      {/*      <FormErrors errors={formErrors} />*/}
      {/*      <div className={classes.buttons}>*/}
      {/*        {activeStep !== 0 && (*/}
      {/*          <Button onClick={handleBack} className={classes.button}>*/}
      {/*            Back*/}
      {/*          </Button>*/}
      {/*        )}*/}
      {/*        <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>*/}
      {/*          {activeStep === steps.length - 1*/}
      {/*            ? 'Donate'*/}
      {/*            : 'Next'}*/}
      {/*        </Button>*/}
      {/*      </div>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Grid>*/}
    </Grid>
  )
}
