import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemText, Collapse } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function CustomCategoryList({ category }) {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  if (!category) return null

  function renderListItem(item) {
    return (
      <ListItem key={item.title || item} className={classes.nested} dense>
        <ListItemText primary={`- ${item.title || item}`} />
      </ListItem>
    )
  }

  function handleClick() {
    setExpand(!expand)
  }

  function renderCollapsibleList(items) {
    return (
      <>
        {items.slice(0, 5).map((item) => renderListItem(item))}
        {!expand && (
          <ListItem button onClick={handleClick}>
            <ListItemText primary="...view more" align="center" />
          </ListItem>
        )}
        <Collapse in={expand} timeout="auto" unmountOnExit>
          {items.slice(5).map((item) => renderListItem(item))}
        </Collapse>
        {expand && (
          <ListItem button onClick={handleClick}>
            <ListItemText primary="...view less" align="center" />
          </ListItem>
        )}
      </>
    )
  }

  return (
    <React.Fragment key={category.name}>
      <ListItem>
        <ListItemText
          primary={category.name}
          className={classes.primaryText}
          primaryTypographyProps={{ variant: 'h6' }}
        />
      </ListItem>
      {!category.items || category.items.length === 0 ? (
        <ListItem component="li" className={classes.nested} dense>
          <ListItemText primary="- None specified" />
        </ListItem>
      ) : (
        <>
          {category.items.length > 5
            ? renderCollapsibleList(category.items)
            : category.items.map((item, index) => {
                return renderListItem(item)
              })}
        </>
      )}
    </React.Fragment>
  )
}
