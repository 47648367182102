import React from 'react'
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { graduationYears } from '../../utils/helper'
import withOtherOption from './otherHOC'

// TODO: pull the list values from database
const addictionSpecialtyList = [
  'Alcohol',
  'Adult Children of Alcoholics',
  'Drug',
  'Food',
  'Internet',
  'Porn',
  'Sex',
  'Gambling',
  'Shopping',
  'Technology',
  'Work', 
]
const otherSpecialtyList = [
  'Abuse',
  'Adoption',
  'Anorexia Nervosa',
  'Antisocial Personality Disorder',
  'Anxiety',
  'Attention Deficit Hyperactivity Disorder (ADHD)',
  'Behavioral Issues',
  'Binge Eating',
  'Bipolar Disorder',
  'Borderline Personality Disorder',
  'Bulimia',
  'Codependency',
  'Conduct Disorder',
  'Depression',
  'Divorce',
  'Family Conflict',
  'Grief',
  'Infidelity',
  'Marital and Premarital',
  'Obsessive-compulsive disorder (OCD)',
  'Oppositional Defiant Disorder (ODD)',
  'Panic Disorder',
  'Phobias',
  'Post-traumatic stress disorder (PTSD)',
  'Schizophrenia',
  'Seasonal Affective Disorder (SAD)',
  'Self-Harm',
  'Stress',
  'Substance Abuse',
  'Suicidal',
  'None',  
]
const modalityList = ['Individual', 'Couples', 'Family', 'Group']
const therapyTypeList = [
  'Psychoanalysis and Psychodynamic Therapy',
  'Behavior Therapy',
  'Cognitive Behavior Therapy (CBT)',
  'Humanistic Therapy',
  'Integrative and Holistic Therapy',
]
const years = graduationYears()

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}))

export function PrimaryEducation({ value, handleInputChange }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography variant="h6" align="left" gutterBottom>
        Primary Education
      </Typography>
      <Grid className={classes.title} container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            id="educationType"
            name="name"
            label="Degree or Program Name"
            value={value.name || ''}
            onChange={(e) => handleInputChange(e, 'primary')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            id="graduation-year"
            label="Year Graduated"
            name="completedOn"
            value={value.completedOn || ''}
            onChange={(e) => handleInputChange(e, 'primary')}
          >
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="schoolName"
            name="source"
            label="School Name"
            value={value.source || ''}
            onChange={(e) => handleInputChange(e, 'primary')}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export function AdditionalEducation({ id, value, handleInputChange }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Typography variant="h6" align="left" gutterBottom>
        Additional Education
      </Typography>
      <Grid className={classes.title} container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            id={`programName${id}`}
            name="name"
            label="Program Name"
            value={value.name || ''}
            onChange={(e) => handleInputChange(e, `additional${id}`)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            fullWidth
            id={`date${id}`}
            label="Year Graduated"
            name="completedOn"
            value={value.completedOn || ''}
            onChange={(e) => handleInputChange(e, `additional${id}`)}
          >
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id={`agencyName${id}`}
            name="source"
            label="Issuing Agency Name"
            value={value.source || ''}
            onChange={(e) => handleInputChange(e, `additional${id}`)}
          />
        </Grid>
        {/* onClick repeat fields above */}
      </Grid>
    </React.Fragment>
  )
}

export function AddictionSpecialties({ value, handleInputChange }) {
  return (
    <>
      <InputLabel id="specialty-checklist-label">Specialty</InputLabel>
      <Select
        fullWidth
        labelId="specialty-checklist-label"
        id="specialty-checklist"
        multiple
        name="addspecialty"
        value={value}
        onChange={handleInputChange}
        renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
      >
        {addictionSpecialtyList.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
export function OtherSpecialties({ value, handleInputChange }) {
  return (
    <>
      <InputLabel id="specialty-checklist-label">Specialty</InputLabel>
      <Select
        fullWidth
        labelId="specialty-checklist-label"
        id="specialty-checklist"
        multiple
        name="othspecialty"
        value={value}
        onChange={handleInputChange}
        renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
      >
        {otherSpecialtyList.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export function TherapyTypes({ value, handleInputChange }) {
  return (
    <>
      <InputLabel id="therapyType-checklist-label">Type of Therapy</InputLabel>
      <Select
        fullWidth
        labelId="therapyType-checklist-label"
        id="therapyType-checklist"
        multiple
        name="therapyType"
        value={value}
        onChange={handleInputChange}
        renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
      >
        {therapyTypeList.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export function Modalities({ value, handleInputChange }) {
  return (
    <>
      <InputLabel id="modality-checklist-label">Modality</InputLabel>
      <Select
        fullWidth
        labelId="modality-checklist-label"
        id="modality-checklist"
        multiple
        name="modality"
        value={value}
        onChange={handleInputChange}
        renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
      >
        {modalityList.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export const AddictionSpecialtyWithOtherOption = withOtherOption(AddictionSpecialties, 'addspecialty')
export const OtherSpecialtyWithOtherOption = withOtherOption(OtherSpecialties, 'othspecialty')
export const TherapyTypesWithOtherOption = withOtherOption(TherapyTypes, 'therapyType')
export const ModalitiesWithOtherOption = withOtherOption(Modalities, 'modality')
