import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  name: {
    color: theme.palette.primary.main,
  },
}))

export default function TherapistName({ name }) {
  const classes = useStyles()
  if (!name || typeof name !== 'string') return null
  return (
    <Typography variant="h5" className={classes.name}>
      {name}
    </Typography>
  )
}
