import { Paper, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react'

const useStyles = makeStyles(theme => ({
    paper: {
        width: "175px"
    },
    locationInput: {
        width: "100%",
    },
    filter: {
        background: "#F5F5F5",
        padding: 10,
        borderRadius: 8,
        overflow: "hidden",
        maxWidth: 300,
    }
}))

const CustomPaper = (props) => {
  return <Paper elevation={8} style={{width: "100%", marginTop: 10}} {...props} />;
};

const convertLocationToString = (location) => {
    if (!location.fields) {
        return "None"
    }
    return `${location.fields.city}, ${location.fields.state} ${location.fields.zip}`
}

export default function LocationAutocomplete({locations, locationInput, setLocationInput, locationSelected, setLocationSelected, forFilter}) {
    const classes = useStyles();
    return (
        <Autocomplete
            id="location-search"
            options={locations}
            title='Location'
            noOptionsText="No results for this location"
            className={`${classes.locationInput} ${forFilter ? classes.filter : ""}`}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => {
                return convertLocationToString(option);
            }}
            filterOptions={(options) => {
                if (locationInput.trim() == "") {
                    return [];
                }
                return options.filter(
                    (option) => {
                        return (
                            convertLocationToString(option).toLowerCase().indexOf(locationInput.toLowerCase().trim()) > -1 ||
                            option.fields.zip.toLowerCase().indexOf(locationInput.toLowerCase().trim()) == 0 ||
                            option.fields.city.toLowerCase().indexOf(locationInput.toLowerCase().trim()) == 0
                        )
                    }
                ).slice(0, 10);
            }}
            onChange={(event, newValue) => {
                 setLocationSelected(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setLocationInput(newInputValue);
            }}
            renderInput={(params) => {
                const inputProps = params.InputProps;
                inputProps.disableUnderline = true;
                return <TextField {...params} 
                                    disableUnderline={true}
                                    InputProps={inputProps}
                                    placeholder='Location'
                        />
            }}
        />
    )
}
