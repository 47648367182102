/* eslint-disable no-unused-vars */

// taken from https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
function tryParseJSON(jsonString) {
  try {
    const o = JSON.parse(jsonString)

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`🐛 JSON error, but no need to halt.`)
  }

  return undefined
}

const formatLocation = loc => {
  let location = loc.city
  if (loc.province && loc.city) {
    location += `, ${loc.province}`
  } else if (loc.province) {
    location = loc.province
  }
  if (loc.postalCode) {
    location += ` ${loc.postalCode}`
  }
  return location
}
const calcAverageRating = reviews => {
  if (!reviews || reviews.length === 0) {
    return 0
  } else {
    const sum = reviews.reduce((acc, review) => acc + parseInt(review.rating, 10), 0)
    return sum / reviews.length
  }
}
// possible input: 5553214567x888 --> output: (555) 321-4567 x 888
const formatPhone = p => {
  let phone = `(${p.slice(0, 3)}) ${p.slice(3, 6)}-${p.slice(6, 10)}`
  if (p.includes('x')) {
    phone += ` x ${p.split('x')[1]}`
  }
  return phone
}
const getCompanyPhone = phones => {
  const phone = phones.find(p => p.type === 'Business Phone')
  if (!phone) return null
  return formatPhone(phone.number)
}
const formatName = (f, m, l) => {
  return `${f}${m && ` ${m}`} ${l}`.trim()
}

const getPrimaryEducation = education => {
  const primaryEdu = education.find(edu => edu.classification === 'primary')
  if (!primaryEdu) return null
  return primaryEdu
}
const getAdditionalEducation = education => {
  const additionalEdu = education.filter(edu => {
    return edu.classification === 'additional'
  })
  if (!additionalEdu) return null
  return additionalEdu
}

// take an array of objects that includes a 'title' key
// returns an array of titles
function mapToTitlesArray(arr) {
  if (!arr || arr.length === 0) return null
  return arr.map(obj => obj.title)
}
// id is the phone id, phones is an array of phones
function getPhoneNumberById(phones, id) {
  if (!id || !phones) return null
  const phone = phones.find(p => p.id === id)
  if (!phone) return null
  return phone.number
}
// example getPhonNumberByType([], 'fax')
function getPhoneNumberByType(phones, type) {
  if (!type || !phones) return null
  const phone = phones.find(p => p.type === type)
  if (!phone) return null
  return phone.number
}

/**
 * Finds the url of a link based on type
 * @param {array} links - the array of Links from the database
 * @param {string} type - the "type" of link. ex: 'facebook', 'twitter', 'linkedin'
 * @returns {string} the url of the link if one of that type was found. Otherwise, returns null
 */
function getLink(links, type) {
  if (!type || links.length === 0) return null
  const link = links.find(l => l.type === type)
  if (!link || link.url.trim() === '') return null
  // if starts with http then good to go, otherwise add https://
  return link.url.indexOf('http') === -1 ? `https://${link.url}` : link.url
}

const formatTherapistData = therapist => {
  const d = {}
  // format data for display
  const { Person, WorkLocation, Reviews } = therapist
  const {
    firstName,
    lastName,
    middleInitial,
    // gender,
    Specialties,
    TherapyTypes,
    Insurances,
    Languages,
    FocusAges,
    Modalities,
    EmailAddress,
    Location,
    // Professions,
    SessionOption,
    Links,
    Education,
    Phones,
  } = Person
  d.raw = therapist
  d.id = therapist.id
  d.isClaimed = therapist.profileClaimed
  d.name = formatName(firstName, middleInitial, lastName)
  d.profession = therapist.professionName
  d.companyAddress = WorkLocation
  d.companyAddress.location = formatLocation(WorkLocation)
  d.companyPhone = getCompanyPhone(Phones)
  d.languages = Languages
  d.paymentMethods = Person.SessionOption?.paymentMethod || ''
  d.remoteSession = Person.SessionOption?.remoteSession || ''
  d.sessionFeeMin = Person.SessionOption?.sessionFeeMin || ''
  d.sessionFeeMax = Person.SessionOption?.sessionFeeMax || ''
  d.slidingScale = Person.SessionOption?.slidingScale || ''
  d.insurances = Insurances
  d.specialties = Specialties
  d.ages = FocusAges
  d.therapyTypes = TherapyTypes
  d.modalities = Modalities
  d.links = Links
  d.hours = WorkLocation.hours
  d.educations = Education
  d.website = getLink(Links, 'website')
  d.linkedin = getLink(Links, 'linkedin')
  d.facebook = getLink(Links, 'facebook')
  d.email = '' // therapist.email
  d.reviews = Reviews
  d.rating = calcAverageRating(Reviews)
  d.numRatings = Reviews ? Reviews.length : 0
  d.bio = Person.bio
  d.avatar = Person.avatar
  return d
}

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
function calcDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0
  } else {
    const radlat1 = (Math.PI * lat1) / 180
    const radlat2 = (Math.PI * lat2) / 180
    const theta = lon1 - lon2
    const radtheta = (Math.PI * theta) / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == 'K') {
      dist = dist * 1.609344
    }
    if (unit == 'N') {
      dist = dist * 0.8684
    }
    return dist
  }
}

// returns an array of years in descending order starting with the current year.
// default goes back 70 years from today.
function graduationYears(n = 70) {
  const years = []
  const start = new Date().getFullYear()
  for (let i = start; i > start - n; i--) {
    years.push(i)
  }
  return years
}

export {
  formatLocation,
  calcAverageRating,
  formatPhone,
  getCompanyPhone,
  formatName,
  formatTherapistData,
  mapToTitlesArray,
  getPhoneNumberById,
  getPhoneNumberByType,
  getLink,
  calcDistance,
  getPrimaryEducation,
  getAdditionalEducation,
  graduationYears,
  tryParseJSON,
}
