import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, InputBase, IconButton, Typography, Button, Grid } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  searchContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    // desktop view
    [theme.breakpoints.up('md')]: {
      borderRadius: 60,
      maxWidth: '865px',
    },
  },
  searchText: {
    color: theme.palette.common.white,
  },
  searchBar: {
    flexGrow: 1,
  },
  inputWrapper: {
    display: 'flex',
    padding: '2px 4px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

export default function SearchBarTherapist({ value, handleChange, handleSearch, handleReset, handleKeyPress }) {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      className={classes.searchContainer}
    >
      <Grid item xs={12} md={4}>
        <Typography className={classes.searchText}>Find an addiction therapist today</Typography>
      </Grid>
      <Grid item xs={12} md={6} container spacing={1} justify="flex-start">
        <Grid item xs={12} sm={10} className={classes.searchBar}>
          <Paper className={classes.inputWrapper}>
            <InputBase
              className={classes.input}
              placeholder="Search by name"
              inputProps={{ 'aria-label': 'search for a provider' }}
              value={value}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
            {handleReset && (
              <IconButton className={classes.iconButton} aria-label="search" onClick={handleReset}>
                <ClearIcon />
              </IconButton>
            )}
          </Paper>
        </Grid>
        <Grid container item xs={12} sm={2} className={classes.searchButton} justify="flex-end">
          <Button color="secondary" variant="contained" onClick={handleSearch}>
            Go
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
