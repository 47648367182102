import { Box, Input, Paper, TextField, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import searchIcon from "../../assets/img/search.svg"
import locationIcon from "../../assets/img/location_on.svg"
import plusIcon from "../../assets/img/local_hospital.svg"
import personSearchIcon from "../../assets/img/person_search.svg"
import LocationAutocomplete from './LocationAutocomplete'
import SpecialtyAutocomplete from "./SpecialtyAutocomplete"

const indigoBlue = "#1B3666";
const secondary500 ="#D49F02";
const dropShadow = "drop-shadow(0px 4px 30px rgba(0,0,0,.13))";
const useStyles = makeStyles(theme => ({
    marginSection: {
        marginTop: 80,
    },
    filterSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "left",
        backgroundColor: "#F9F9F9",
        color: "black",
        height: 430,
        marginTop: 72,
        padding: "0 20px",
        [theme.breakpoints.up('md')]: {
            marginTop: 100,
            paddingLeft: 64,
            paddingRight: 64,
        },
        [theme.breakpoints.up('lg')]: {
           marginTop: 80, 
           paddingLeft: 200,
           paddingRight: 200,
        },
    },
    searchTitle: {
        fontWeight: 700,
        fontSize: 24,
        color: indigoBlue,
        filter: dropShadow,
        [theme.breakpoints.up('lg')]: {
            fontSize: 40,
        },
    },
    subtitle: {
        color: secondary500,
        fontWeight: 600,
        marginTop: 10,
        fontSize: 14,
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
        },
    },
    searchBar: {
        display:"flex",
        flexDirection: "column",
        gap: 16,
        marginTop: 20,
        maxWidth: 600,
        overflow: "hidden",
        width: "100%",
        filter: dropShadow,
        [theme.breakpoints.up('md')]: {
            width:"100%",
            maxWidth: 1112,
            flexDirection: "row",
            height: 64,
            gap: 0,
            borderRadius: 8,
        },
    },
    entry: {
        width: "100%",
        display: "flex",
        alignItems:"center",
        backgroundColor: "white",
        paddingLeft: 26,
        paddingRight: 26,
        height: 45,
        borderRadius: 4,
        border: 'none',
        [theme.breakpoints.up('md')]: {
            borderRadius: 0,
            borderLeft: "1px solid #E6E6E6", 
            borderRight: "1px solid #E6E6E6",
            height: 64,
        },
        [theme.breakpoints.up('lg')]: {
            width: 325,
            padding: 20,
            borderRadius: 0,
            borderLeft: "1px solid #E6E6E6", 
            borderRight: "1px solid #E6E6E6"
        },
    },
    locationEntry: {
        [theme.breakpoints.up('lg')]: {
            width: 325,
        },
    },
    searchButton: {
        height: 45,
        display:"flex",
        backgroundColor: indigoBlue,
        alignItems: "center",
        gap: 8,
        padding: "0 28px",
        color: "white",
        cursor: "pointer",
        borderRadius: 4,
        [theme.breakpoints.up('md')]: {
            borderRadius: 0,
            height: 64,
        },
        [theme.breakpoints.up('lg')]: {
            width: 150,
        },
    },
    plusIcon: {
        minWidth: 20,
        maxWidth: 20,
        marginRight: 15,
    },
    personIcon: {
        minWidth: 20,
        maxWidth: 20,
    },
    locationIcon: {
        minWidth: 20,
        maxWidth: 20,
        height: 20,
        marginRight: 15,
    },
    searchInput: {
        padding: 15,
        width: "100%",
        height: "100%",
        maxWidth: 600,
        border: "none",
        outline: "none",
        fontFamily: "Poppins",
        fontSize: 16, 
        color: "#56575F",
        "&::-ms-input-placeholder": {
            color: "#56575F"
        },
        "&::placeholder": {
            color: "#56575F"
        }
    },
    locationInput: {
        width: "100%"
    },
    paper: {
        width: "min(100%, 400px)"
    }
}))

export default function TherapistFilter({locations, locationSelected, setLocationSelected, executeSearch,
                                        therapistInput, setTherapistInput, specialties, specialtySelected, setSpecialtySelected}) {
    const classes = useStyles();
    const [locationInput, setLocationInput] = useState("");
    const [specialtyInput, setSpecialtyInput] = useState("");

    
    const handleTherapistChange = (e) => {
        setTherapistInput(e.target.value)
    }                                        

    return (
        <Box className={classes.filterSection} align="center">
            <Typography className={classes.searchTitle}>Find a therapist near you</Typography>
            <Typography className={classes.subtitle}>Start your transformative journey here.</Typography>
            
            <Box className={classes.searchBar}>
                <Box className={classes.entry}>
                    <img src={plusIcon} className={classes.plusIcon} alt="plus"/>
                    <SpecialtyAutocomplete specialties={specialties} specialtyInput={specialtyInput} setSpecialtyInput={setSpecialtyInput} 
                                        specialtySelected={specialtySelected} setSpecialtySelected={setSpecialtySelected}/>
                </Box>
                <Box className={classes.entry}>
                    <img src={personSearchIcon} className={classes.personIcon} alt="search"/>
                    <Input value={therapistInput} onChange={handleTherapistChange} disableUnderline className={classes.searchInput} placeholder='Therapist'/>
                </Box>
                <Box className={`${classes.entry} ${classes.locationEntry}`}>
                    <img src={locationIcon} className={classes.locationIcon} alt="location"/>
                    <LocationAutocomplete locations={locations} locationInput={locationInput} setLocationInput={setLocationInput} 
                                        locationSelected={locationSelected} setLocationSelected={setLocationSelected} forFilter={false}/>
                </Box>
                 <Box className={classes.searchButton} onClick={executeSearch}>
                    <img src={searchIcon} className={classes.searchIcon} alt="search"/>
                    <Typography>Search</Typography>
                 </Box>
            </Box>
        </Box>              

    )
}

