import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'

const indigoBlue = '#1B3666'
const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    gap: '48px',
    background: 'white',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      width: '100%',
    },
  },
  circle: {
    maxWidth: '64px',
    maxHeight: '64px',
    minWidth: '64px',
    minHeight: '64px',
    borderRadius: '50%',
    border: '2px solid #1B3666',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #1B3666',
      maxWidth: '30px',
      maxHeight: '30px',
      minWidth: '30px',
      minHeight: '30px',
    },
  },
  numberText: {
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '150%',
    textAlign: 'center',
    color: indigoBlue,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  headerText: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '150%',
    textAlign: 'center',
    color: indigoBlue,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  connectorContainer: {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  timelineDot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: indigoBlue,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  timelineConnector: {
    width: 'calc(50% - 4px)',
    height: '2px',
    background: indigoBlue,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(50% - 2px)',
      height: '1px',
    },
  },
  emptyConnector: {
    width: 'calc(50% - 4px)',
    height: '2px',
    background: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(50% - 2px)',
      height: '1px',
    },
  },
  textBox: {
    width: '100%',
    maxWidth: '570px',
    minHeight: '102px',
    borderRadius: '10px',
    background: '#FFF',
    border: '2px solid #1B3666',
    display: 'flex',
    alignItems: 'center',
    padding: '26px 44px',
    [theme.breakpoints.down('sm')]: {
      border: '1px solid #1B3666',
      padding: '10px',
      marginTop: '40px',
      marginBottom: '40px',
      maxWidth: '312px',
    },
  },
  descriptionText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.005em',
    textAlign: 'left',
    color: indigoBlue,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

export default function TimelineComponent(props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let order = ''
  let align = ''
  if (props.side == "right") {
    order = 'row-reverse'
    align = 'left'

  } else {
    order = 'row'
    align = 'right'
  }

  if (isMobile) {
    order = 'column'
  }

  return (
    <div className={classes.itemContainer} style={{ flexDirection: `${order}` }}>
      <div className={classes.headerContainer} style={{ flexDirection: `${order}`, justifyContent: `${align}` }}>
        <Typography variant="h5" className={classes.headerText}>
          {props.header}
        </Typography>
        <div className={classes.circle}>
          <Typography variant="h5" className={classes.numberText}>
            {props.number}
          </Typography>
        </div>
      </div>

      <div className={classes.connectorContainer} style={{ flexDirection: `${order}` }}>
        <div className={classes.emptyConnector} />
        <div className={classes.timelineDot} />
        <div className={classes.timelineConnector} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', width: isMobile ? '100%' : '45%' }}>
        <div className={classes.textBox}>
          <Typography className={classes.descriptionText}>{props.description}</Typography>
        </div>
      </div>
    </div>
  )
}

TimelineComponent.propTypes = {
  number: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
}
