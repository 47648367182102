/* eslint-disable no-console */
import { call } from './client-api'
const localStorageKey = '__therapy_reviews_token__'

// if response contained user data and token, set token and then return user data
function handleUserResponse(r) {
  if (!r) return null
  if (r.token) {
    localStorage.setItem(localStorageKey, r.token)
  }

  return r.user
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  // return promise
  return call(`/auth/verify`).catch((error) => {
    logout()
    return Promise.reject(error)
  })
}

function login({ email, password }) {
  return call(`/auth/login`, { body: { email, password } }).then(handleUserResponse)
}

function forgotPassword(email) {
  return call(`/auth/password?action=reset`, {
    body: email,
    method: 'POST',
  })
}

// register for a user account
// person can contain: { email, password, firstName, lastName, middleInitial, gender, role }
function register(person) {
  return call(`/auth/registration`, {
    body: person,
  })
}

// used in the claim profile process - processes payment and creates therapist account
// id is the licensedTherapist id, body contains card, item, and therapist objects
function registerTherapist(id, body) {
  return call(`/api/v1/payment/${id}`, { body })
}

function donate(body) {
  return call('/api/v1/donate', { body })
}

function scholarship(body) {
  return call('/api/v1/scholarship', { body })
}

// id is the licensedTherapist id
function sendVerifyEmail(id) {
  return call(`/auth/registration/verify/${id}?type=email`, {
    method: 'POST',
  })
}
// id is the licensedTherapist id
function sendVerifyText(id) {
  return call(`/auth/registration/verify/${id}?type=text`, {
    method: 'POST',
  })
}
function verifyCode(id, code) {
  return call(`/auth/registration/verify/${id}?code=${code}`)
}

function logout() {
  localStorage.removeItem(localStorageKey)
  return call(`/auth/logout`)
}

// id is Person.id
function updatePassword(id, data) {
  return call(`/auth/password/${id}?action=update`, {
    body: data,
    method: 'POST',
  })
}
// retrieve JWT token form local storage
function getToken() {
  return localStorage.getItem(localStorageKey)
}

function setToken(token) {
  return localStorage.setItem(localStorageKey, token)
}

function checkToken(token) {
  if (!token) {
    return Promise.resolve(null)
  }
  setToken(token)
  return Promise.resolve(true)
}

export {
  login,
  register,
  logout,
  forgotPassword,
  getToken,
  getUser,
  sendVerifyEmail,
  sendVerifyText,
  updatePassword,
  verifyCode,
  registerTherapist,
  setToken,
  checkToken,
  donate,
  scholarship,
}
