import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  beliefsFeatureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px',
      justifyContent: 'center',
      borderRadius: '6px',
      boxShadow: '9px 15px 32px 5px rgba(0,0,0,0.1)',
    },
  },
  beliefsFeatureImage: {
    width: '488px',
    height: '331px',
    flexShrink: 0,
    borderRadius: '6px',
    boxShadow: '9px 15px 32px 5px rgba(0,0,0,0.1)',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '237px',
      borderRadius: '0px',
    },
  },
  beliefsFeatureTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    justifyContent: 'center',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  beliefsFeatureTitle: {
    color: '#000',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    textAlign: 'center',
    letterSpacing: '-0.48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  beliefsFeatureText: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '150%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      fontSize: '16px',
      color: '#535457',
      textAlign: 'left',
    },
  },
}))

const BeliefCard = ({ image, title, text, alt, altText }) => {
  const classes = useStyles()
  return (
    <div className={classes.beliefsFeatureContainer}>
      {alt ? (
        <>
          <div className={classes.beliefsFeatureTextContainer}>
            <Typography className={classes.beliefsFeatureTitle}>{title}</Typography>
            <Typography className={classes.beliefsFeatureText}>{text}</Typography>
          </div>
          <img className={classes.beliefsFeatureImage} src={image} alt={altText} />
        </>
      ) : (
        <>
          <img className={classes.beliefsFeatureImage} src={image} alt={altText} />
          <div className={classes.beliefsFeatureTextContainer}>
            <Typography className={classes.beliefsFeatureTitle}>{title}</Typography>
            <Typography className={classes.beliefsFeatureText}>{text}</Typography>
          </div>
        </>
      )}
    </div>
  )
}

export default BeliefCard
