import React from 'react'
import ReactGA from 'react-ga'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useUser } from './context/user-context'
// import Loading from './components/Loading'
import UnauthenticatedApp from './UnauthenticatedApp'
import './index.css'
ReactGA.initialize('UA-172594432-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
// const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))
const queryClient = new QueryClient()

export default function App() {
  const { user } = useUser()
  // pre-load the authenticated side in the background while the user's filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  return (
    <React.Suspense fallback={<UnauthenticatedApp />}>
      <QueryClientProvider contextSharing={true} client={queryClient}>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </QueryClientProvider>
    </React.Suspense>
  )
}
