import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import JoinCard from './JoinCard'
import ScholarshipImage from '../../assets/img/mantas-hesthaven-_g1WdcKcV3w-unsplash.jpg'
import TherapistImage from '../../assets/img/forTherapists.jpg'
import DonorImage from '../../assets/img/fund-scholarships.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '80px',
    padding: '80px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      gap: '32px',
    },
  },
  lowercaseTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    textAlign: 'center',
    letterSpacing: '-0.6px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  descriptionText: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '150%',
    fontFamily: 'Inter',
    textAlign: 'center',
    letterSpacing: '0.18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  titleContainer: {
    display: 'flex',
    width: '60%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  joinCardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '50px',
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      gap: '24px',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
}))

const joinCards = [
  {
    image: ScholarshipImage,
    title: 'For Scholarship Applicants',
    text: 'Start your recovery journey by applying for our therapy scholarship',
    alt: 'a person with a suitcase walking on a road',
    path: '/scholarships',
  },
  {
    image: TherapistImage,
    title: 'For Therapists',
    text: 'Register to be a participating therapist',
    alt: 'two men hugging each other infront of a therapist',
    path: '/therapist-application',
  },
  {
    image: DonorImage,
    title: 'For Donors',
    text: 'Donate today to support someone’s recovery journey',
    alt: 'a person holding a jar of coins with donate written on it',
    path: '/donate',
  },
]

const JoinToday = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography className={classes.lowercaseTitle} component="h2">
          Participate Today
        </Typography>
        <Typography className={classes.descriptionText} component="p">
          Join us in illuminating the path to recovery by donating to our organization, sharing our mission with others,
          or registering as a participating therapist. Together, we can make a difference and bring hope to those who
          need it most. Together, we can light the path to recovery.
        </Typography>
      </div>
      <div className={classes.joinCardsContainer}>
        {joinCards.map((card) => (
          <JoinCard key={card.title} {...card} />
        ))}
      </div>
    </div>
  )
}

export default JoinToday
