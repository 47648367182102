import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, Collapse, Box, Typography, IconButton, CardContent } from '@material-ui/core'
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons'
import TherapistAvatar from '../TherapistAvatar'
import { tryParseJSON } from '../../utils/helper'

const useStyles = makeStyles(() => ({
  card: { display: 'flex' },
  avatar: {
    width: 150,
  },
  meta: {
    // whiteSpace: 'pre-wrap',
    maxWidth: 450,
  },
}))

export default function SystemLogRow(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const person = props.row.Person
  return (
    <>
      <TableRow
        hover={props.hover}
        onClick={props.onClick}
        role="checkbox"
        aria-checked={props.isItemSelected}
        tabIndex={-1}
        selected={props.isItemSelected}
      >
        <TableCell>
          {person ? (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell component="th" id={props.id} scope="row">
          {props.row.id}
        </TableCell>
        <TableCell>{props.row.status}</TableCell>
        <TableCell>{props.row.message}</TableCell>
        <TableCell className={classes.meta}>{JSON.stringify(tryParseJSON(props.row.meta) || {}, null, 2)}</TableCell>
      </TableRow>
      {person && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Person details
                </Typography>
                <div className={classes.card}>
                  <TherapistAvatar src={person.avatar} />
                  <CardContent>
                    <Typography>{`Id: ${person.id}`}</Typography>
                    <Typography>{`Name: ${person.firstName} ${person.lastName}`}</Typography>
                    <Typography>{`Role: ${person.role}`}</Typography>
                  </CardContent>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
