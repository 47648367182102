import React from 'react'
import { Typography, Link } from '@material-ui/core'

export default function Copyright() {
  return (
    <>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          color: '#56575F',
        }}
        variant="body2"
        align="center"
      >
        {'Copyright © '}
        <Link color="inherit" href="https://litpath.org/" target="_blank" rel="noopener noreferrer">
          Lit Path
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  )
}
