import React, { useEffect }  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import JotformEmbed from 'react-jotform-embed';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

// eslint-disable-next-line max-lines-per-function
export default function BillingInfoForm({ value, handleInputChange }) {
  const classes = useStyles()
  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container maxWidth="md">
      <div className={classes.paper}>
        <JotformEmbed src="https://form.jotform.com/222606514268051" />
      </div>
    </Container>
  )
}
