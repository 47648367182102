/* eslint-disable max-lines-per-function */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import TermsAndConditions from '../components/TermsAndConditionsTherapist.jsx'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
}))

export default function TermsTherapist() {
  const classes = useStyles()

  return (
    <Grid container>
      <div className={classes.paper}>
        <TermsAndConditions />
      </div>
    </Grid>
  )

}
