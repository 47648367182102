import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
  },
}))

export default function Loading() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <CircularProgress color="primary" size={50} thickness={4} />
      <br />
      <Typography variant="h3">Loading...</Typography>
    </div>
  )
}
