import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { Radio, RadioGroup } from '@material-ui/core'
import withOtherOption from './otherHOC'

// TODO: pull list items from database
const languageList = ['English', 'Spanish', 'Chinese', 'French', 'Tagalog', 'Vietnamese', 'Korean', 'German']
const useStyles = makeStyles((theme) => ({
  radioGroup: {
    textAlign: 'left',
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}))

export function PersonalAddressForm({ value, handleInputChange }) {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Personal Infomation
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="firstName"
            name="firstName"
            label="First name *"
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            value={value.middleInitial}
            onChange={handleInputChange}
            id="middleInitial"
            name="middleInitial"
            label="Middle"
            fullWidth
            autoComplete="mname"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            value={value.lastName}
            onChange={handleInputChange}
            id="lastName"
            name="lastName"
            label="Last name *"
            fullWidth
            autoComplete="lname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.address1}
            onChange={handleInputChange}
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.address2}
            onChange={handleInputChange}
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.city}
            onChange={handleInputChange}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.state}
            onChange={handleInputChange}
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.zip}
            onChange={handleInputChange}
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.country}
            onChange={handleInputChange}
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="billing country"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="saveAddress" value="yes" />
            }
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </>
  )
}

export function BillingInformationForm({ value, handleInputChange }) {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Billing Infomation
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            value={value.middleInitial}
            onChange={handleInputChange}
            id="middleInitial"
            name="middleInitial"
            label="Middle"
            fullWidth
            autoComplete="mname"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            value={value.lastName}
            onChange={handleInputChange}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.billingAddress1}
            onChange={handleInputChange}
            id="billingAddress1"
            name="billingAddress1"
            label="Billing Address line 1"
            fullWidth
            autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.billingAddress2}
            onChange={handleInputChange}
            id="billingAddress2"
            name="billingAddress2"
            label="Billing Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.city}
            onChange={handleInputChange}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.state}
            onChange={handleInputChange}
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.zip}
            onChange={handleInputChange}
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.country}
            onChange={handleInputChange}
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="billing country"
          />
        </Grid>
      </Grid>
    </>
  )
}

export function AboutForm({ value, handleInputChange }) {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        About me
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.bio}
            onChange={handleInputChange}
            align="left"
            id="bio"
            name="bio"
            variant="outlined"
            label="Tell about yourself and your therapy approach to help others get to know you. This information will appear in the main section of your public profile page."
            multiline
            rows="8"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}

export function CheckGender({ value, handleInputChange }) {
  const classes = useStyles()
  return (
    // <div className={classes.root}>
    <FormControl component="fieldset">
      <Typography variant="h6" align="left" gutterBottom>
        Gender
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        aria-label="gender"
        name="gender"
        value={value.gender}
        onChange={handleInputChange}
      >
        <FormControlLabel value="f" control={<Radio />} label="Female" />
        <FormControlLabel value="m" control={<Radio />} label="Male" />
        <FormControlLabel value="o" control={<Radio />} label="Non-binary" />
      </RadioGroup>
    </FormControl>
    // </div>
  )
}

export function CheckLanguage({ value, handleInputChange }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FormControl variant="filled" align="left" fullWidth>
        <InputLabel id="language-checklist-label">Language</InputLabel>
        <Select
          labelId="language-checklist-label"
          id="language-checklist"
          multiple
          onChange={handleInputChange}
          name="language"
          value={value}
          renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
        >
          {languageList.map((language) => (
            <MenuItem key={language} value={language}>
              <Checkbox checked={value.indexOf(language) > -1} />
              <ListItemText primary={language} />
            </MenuItem>
          ))}
        </Select>

        <FormHelperText>Pick at least one</FormHelperText>
      </FormControl>
    </div>
  )
}

export function PersonalContactForm({ value, handleInputChange }) {
  // TODO: add regex to phone number and email
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Contact Me
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.primaryPhone}
            onChange={handleInputChange}
            id="primaryPhone"
            name="primaryPhone"
            label="Primary Phone"
            fullWidth
            autoComplete="phone"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.secondaryPhone}
            onChange={handleInputChange}
            id="secondaryPhone"
            name="secondaryPhone"
            label="Secondary Phone"
            fullWidth
            autoComplete="phone2"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.email}
            onChange={handleInputChange}
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
          />
        </Grid>
      </Grid>
    </>
  )
}

export function DigitalContactsForm({ value, handleInputChange }) {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Digital Contacts *
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.website}
            onChange={handleInputChange}
            id="website"
            name="website"
            label="Website"
            fullWidth
            autoComplete="website"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.linkedin}
            onChange={handleInputChange}
            id="linkedin"
            name="linkedin"
            label="Linkedin"
            fullWidth
            autoComplete="linkedin"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.facebook}
            onChange={handleInputChange}
            id="facebook"
            name="facebook"
            label="Facebook"
            fullWidth
            autoComplete="facebook"
          />
        </Grid>
      </Grid>
    </>
  )
}

export function BusinessInformationForm({ value, handleInputChange }) {
  return (
    <>
      <Typography variant="h6" align="left" gutterBottom>
        Billing Infomation
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="middleInitial"
            name="middleInitial"
            label="Middle"
            fullWidth
            autoComplete="mname"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="billingAddress1"
            name="billingAddress1"
            label="Billing Address line 1"
            fullWidth
            autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="billingAddress2"
            name="billingAddress2"
            label="Billing Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.firstName}
            onChange={handleInputChange}
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="billing country"
          />
        </Grid>
      </Grid>
    </>
  )
}

export const LanguageWithOtherOption = withOtherOption(CheckLanguage, 'language')
