import React from 'react'
import TherapistProfile from '../components/TherapistProfile'
import MainLayout from '../layouts/MainLayout'

// therapistId from url props
export default function TherapistInfo({ therapistId }) {
  return (
    <MainLayout>
      <TherapistProfile id={therapistId} />
    </MainLayout>
  )
}
