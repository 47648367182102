import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemAvatar } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { calcAverageRating, formatLocation } from '../utils/helper'
import TherapistAvatar from './TherapistAvatar'
import TherapistNameTag from './TherapistNameTag'

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 500,
  },
}))

export default function TherapistListItem({ data }) {
  const classes = useStyles()
  const name = `${data.Person.firstName}${data.Person.middleInitial && ` ${data.Person.middleInitial}`} ${
    data.Person.lastName
  }`
  const numRatings = data.Reviews ? data.Reviews.length : 0
  const averageRating = data.Reviews ? calcAverageRating(data.Reviews) : 0
  const location = formatLocation(data.WorkLocation)

  return (
    <ListItem button component={RouterLink} key={data.id} to={`/view/therapist/${data.id}`}>
      <ListItemAvatar>
        <TherapistAvatar className={classes.avatar} src={data.Person.avatar} />
      </ListItemAvatar>
      <TherapistNameTag
        name={name}
        profession={data.professionName}
        location={location}
        specialties={data.Person.specialties}
        // rating={{ value: averageRating, quantity: numRatings }}
      />
    </ListItem>
  )
}
