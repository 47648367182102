/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Slider } from '@material-ui/core'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { AddAPhoto as AddAPhotoIcon, ZoomOut as ZoomOutIcon, ZoomIn as ZoomInIcon } from '@material-ui/icons'
import { uploadAvatar } from '../utils/therapist-api'
import { useUser } from '../context/user-context'

const MAX_ZOOM = 400
const useStyles = makeStyles(theme => ({
  dropzone: {
    display: 'flex',
    width: 250,
    height: 250,
    color: theme.palette.grey[400],
    border: `3px dashed ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[200],
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  zoomContainer: {
    width: 200,
  },
}))

export default function UploadAvatar({ id, open, handleClose, admin }) {
  const classes = useStyles()
  const { reloadUser } = useUser()
  const [file, setFile] = useState()
  const [status, setStatus] = useState('idle')
  const [zoom, setZoom] = useState(20)
  const editorRef = useRef()
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      )
    },
  })

  useEffect(
    () => () => {
      if (file) {
        // Make sure to revoke the data uri to avoid memory leaks
        URL.revokeObjectURL(file.preview)
      }
    },
    [file],
  )

  useEffect(() => {
    let canceled = false
    const exportFromCanvas = (canvas, exportAsBlob) => {
      return exportAsBlob
        ? new Promise(resolve => {
            canvas.toBlob(f => {
              resolve(f)
            }, 'image/jpeg')
          })
        : canvas.toDataURL('image/jpeg')
    }
    async function getAndUploadImage() {
      const image = await exportFromCanvas(editorRef.current.getImageScaledToCanvas(), true)
      const formData = new FormData()
      formData.append('image', image, `avatar_person-${id}_${Date.now()}.jpeg`)
      uploadAvatar(id, formData)
        .then(() => {
          if (canceled) return
          setStatus('success')
          if (!admin) {
            reloadUser()
          }
        })
        .catch(() => {
          if (canceled) return
          setStatus('failure')
        })
    }
    if (status === 'upload') {
      getAndUploadImage()
    } else if (status === 'success') {
      setStatus('idle')
      setFile()
      handleClose()
    }
    return () => (canceled = true)
  }, [status, file, id, handleClose, reloadUser])

  const increaseZoom = () => {
    if (zoom < MAX_ZOOM - 10) {
      setZoom(prevZoom => prevZoom + 10)
    }
  }
  const decreaseZoom = () => {
    if (zoom > 10) {
      setZoom(prevZoom => prevZoom - 10)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Profile Photo</DialogTitle>
      <DialogContent>
        {file ? (
          <>
            <AvatarEditor
              ref={editorRef}
              image={file}
              width={250}
              height={250}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={zoom / 100 + 1}
              rotate={0}
            />
            <div className="zoomContainer">
              <Typography id="zoom-slider" gutterBottom>
                Zoom In/Out
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <ZoomOutIcon onClick={decreaseZoom} fontSize="large" color="primary" />
                </Grid>
                <Grid item xs>
                  <Slider
                    color="primary"
                    value={zoom}
                    onChange={(event, newValue) => setZoom(newValue)}
                    aria-labelledby="zoom-slider"
                    max={MAX_ZOOM}
                  />
                </Grid>
                <Grid item>
                  <ZoomInIcon onClick={increaseZoom} fontSize="large" color="primary" />
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <AddAPhotoIcon color="inherit" fontSize="large" className={classes.icon} />

            <Typography align="center">
              Click to select photo
              <br />
              or <br />
              Drag &apos;n drop photo
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {/* {file && status === 'idle' && ( */}
        <>
          <Button disabled={!file} onClick={() => setStatus('upload')}>
            Save Photo
          </Button>
          <Button onClick={() => setFile()}>Clear Image</Button>
        </>
        {/* )} */}
      </DialogActions>
    </Dialog>
  )
}
