/* eslint-disable max-lines-per-function */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Slider,
  Typography,
  Button,
  Grid,
  Collapse,
  IconButton,
  CardActions,
  Hidden,
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useFilters } from '../context/filter-context'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  textField: {
    minWidth: 170,
  },
  slider: {
    padding: 10,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  valueLabel: {
    // change the color of the text in the distance thumb label
    '& >span>span': {
      color: '#000',
    },
  },
  margin: {
    height: theme.spacing(3),
  },
  rating: {
    marginRight: 8,
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.secondary.light,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  filterMobile: {
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.up('md')]: {
    //   display: 'none',
    // },
  },
}))

const ratingList = [
  { component: 'stars 5', value: 5 },
  { component: 'stars 4 & up', value: 4 },
  { component: 'stars 3 & up', value: 3 },
  { component: 'stars 2 & up', value: 2 },
  { component: 'stars 1 & up', value: 1 },
]
const distanceMarks = [
  { value: 5, label: '5 mi' },
  { value: 10, label: '10 mi' },
  { value: 20, label: '20 mi' },
  { value: 30, label: '30 mi' },
]

export default function FilterBox({ onUpdate, onClear }) {
  const classes = useStyles()
  const [stars, setStars] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [insuranceName, setInsuranceName] = useState([])
  const [specialtyName, setSpecialtyName] = useState([])
  const [distance, setDistance] = useState(5)
  const { insurances, specialties } = useFilters()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleUpdate = () => {
    const filters = {
      rating: stars,
      zipcode,
      insurance: insuranceName,
      specialty: specialtyName,
      distance,
    }
    // gather the filter items to send to front end
    onUpdate(filters)
  }

  const handleClear = () => {
    // Reset inputs to initial values
    setStars('')
    setZipcode('')
    setInsuranceName([])
    setSpecialtyName([])
    // Reset data show to user to display all original results
    onClear()
  }

  return (
    <Card>
      <div className={classes.filterMobile}>
        <CardActions>
          <Typography>Filter Results</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={3} justify="center">
              <Grid item>
                <FormControl className={classes.textField}>
                  <InputLabel>Insurance</InputLabel>
                  <Select
                    multiple
                    value={insuranceName}
                    onChange={e => setInsuranceName(e.target.value)}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {insurances.map(ins => (
                      <MenuItem key={ins} value={ins}>
                        {ins}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.textField}>
                  <InputLabel>Specialty</InputLabel>
                  <Select
                    multiple
                    value={specialtyName}
                    onChange={e => setSpecialtyName(e.target.value)}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {specialties.map(spec => (
                      <MenuItem key={spec} value={spec}>
                        {spec}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container justify="center" spacing={3}>
                <Grid item className={classes.center}>
                  <Typography>Distance from Zipcode:</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.textField}
                    label="Zipcode"
                    value={zipcode}
                    onChange={e => setZipcode(e.target.value)}
                  />
                </Grid>
                <Grid item className={classes.center}>
                  <div className={classes.textField}>
                    <Slider
                      color="secondary"
                      classes={{ valueLabel: classes.valueLabel }}
                      defaultValue={10}
                      onChangeCommitted={(e, value) => setDistance(value)}
                      marks={distanceMarks}
                      step={null}
                      min={5}
                      max={30}
                      valueLabelDisplay="auto"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttons}>
                <Button onClick={handleUpdate} className={classes.button} variant="contained" color="secondary">
                  Update
                </Button>
                <Button onClick={handleClear} className={classes.button} variant="contained" color="secondary">
                  Clear
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  )
}
