import React, { useState, useEffect } from 'react'
import { getUser } from '../utils/auth-api'
import { useAuth } from './auth-context'

const UserContext = React.createContext()

function UserProvider(props) {
  const {
    data, //: { user, uid },
  } = useAuth()
  const [user, setUser] = useState(() => data.user || null)
  const [loadingUser, setLoadingUser] = useState(data.loading)

  useEffect(() => {
    setUser(data.user)
    setLoadingUser(data.loading)
  }, [data])

  const reloadUser = async () => {
    setLoadingUser(true)
    const res = await getUser()

    if (res) {
      setUser(res.user)
    } else {
      setUser(null)
    }
    setLoadingUser(false)
  }

  return <UserContext.Provider value={{ user, reloadUser, loadingUser }} {...props} />
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { UserProvider, useUser }
