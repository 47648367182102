import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Box from '@material-ui/core/Box'

const labels = {
  1: 'Not Helpful at All',
  2: 'Slightly helpful',
  3: 'Helpful',
  4: 'Very Helpful',
  5: 'Extremely Helpful',
}

const useStyles = makeStyles({
  root: {
    width: 300,
    display: 'flex',
    alignItems: 'center',
  },
})

export default function SetStarRating({ rating, handleInputChange }) {
  const [hover, setHover] = React.useState(-1)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Rating
        name="rating"
        value={parseInt(rating, 10)}
        precision={1}
        onChange={handleInputChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}
      />
      {rating !== null && (
        // eslint-disable-next-line no-negated-condition
        <Box ml={2}>{labels[hover !== -1 ? hover : rating]}</Box>
      )}
    </div>
  )
}
