import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { enrollEmailToNewsletter } from '../utils/client-api'
import { first, set } from 'lodash'

const useStyles = makeStyles((theme) => ({
  subscription: {
    width: '80%',
    padding: '42px',
    boxShadow:
      '0px 0px 2px 0px #0000001F, 0px 0px 3px 0px #0000001A, 0px 0px 5px 0px #00000017, 0px 0px 6px 0px #0000000D, 0px 0px 8px 0px #00000003, 0px 0px 8px 0px #00000001',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      padding: '32px 0px',
      gap: '32px',
    },
  },
  textContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'left',
    paddingRight: '20px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      padding: '0px',
    },
  },
  joinText: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '34px',
    color: 'black',
  },
  updatesText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
    color: '#535457',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  buttonContainer: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'right',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '80%',
    },
  },
  subscribeButton: {
    minWidth: '20%',
    height: '100%',
    padding: '12px 24px',
    borderRadius: '100px',
    background: '#1B3666',

    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#132648',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      width: '100%',
      maxWidth: '300px',
    },
  },
  inputBox: {
    width: '25%',
    height: '100%',
    backgroundColor: '#F9FAFB',
    padding: '10px 20px 10px 20px',
    borderRadius: '4px',
    border: '1px solid #181818',

    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '300px',
    },
  },
}))

export default function NewsletterForm() {
  const classes = useStyles()
  const [email, setEmail] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = () => {
    if (email === '' || firstName === '' || lastName === '') {
      enqueueSnackbar('Please fill all the fields', { variant: 'error' })
      return
    }
    let firstname = firstName.trim().toUpperCase()
    let lastname = lastName.trim().toUpperCase()
    enrollEmailToNewsletter(email, firstname, lastname)
      .then((res) => {
        enqueueSnackbar('Successfully enrolled to newsletter', { variant: 'success' })
        setEmail('')
        setFirstName('')
        setLastName('')
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }

  return (
    <div className={classes.subscription}>
      <div className={classes.textContainer}>
        <Typography variant="h5" className={classes.joinText}>
          Join Our Newsletter
        </Typography>
        <Typography className={classes.updatesText}>Get emails to stay updated.</Typography>
      </div>
      <div className={classes.buttonContainer}>
        <input
          type="text"
          placeholder="First Name"
          className={classes.inputBox}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          className={classes.inputBox}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Email Address"
          className={classes.inputBox}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button className={classes.subscribeButton} onClick={handleSubmit}>
          Subscribe
        </Button>
      </div>
    </div>
  )
}
