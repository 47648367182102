import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Divider, Button } from '@material-ui/core'
import Avatar from '../TherapistAvatar'
import UploadAvatar from '../UploadAvatar'
import VerticalUpdateTabs from '../VerticalUpdateTabs'
import { formatName } from '../../utils/helper'
import { getTherapistByPersonId } from '../../utils/admin-api'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}))

const EditTherapist = props => {
  const classes = useStyles()
  // const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  // const [reload, setReload] = useState(false)
  // get user data from api based on personId
  const { status, data, error, isFetching, refetch } = useQuery('therapist', () =>
    getTherapistByPersonId(props.personId).then(({ user }) => {
      return user
    }),
  )
    useEffect(() => {
      
    }, []);

  const handleCloseAvatar = () => {
    setOpen(false)
    refetch()
  }

  return (
    <div>
      {status === 'loading' ? (
        'Loading...'
      ) : status === 'error' ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          {isFetching ? <div>Refreshing...</div> : null}
          <Grid container direction="row" spacing={3}>
            {/* Therapist Profile Information*/}
            <Grid item xs={12} sm={12} md={12}>
              <Paper className={classes.paper}>
                <Grid container direction="column">
                  {/* Profile Header/title */}
                  <Grid item container alignItems="center" justify="space-around">
                    {/* Therapist Avatar and Image upload Button*/}
                    <Grid item>
                      <Avatar src={data.Person.avatar} />
                      <Button
                        className={classes.submitBtn}
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => setOpen(true)}
                      >
                        Edit Image
                      </Button>
                      <br />
                      <br />
                    </Grid>

                    {/* Title */}
                    <Grid item className={classes.titleCenter}>
                      <Typography align="center" className={classes.blueText} variant="h3" component="h1">
                        {`Update ${data.Person.firstName}'s Profile`}
                      </Typography>
                    </Grid>

                    {/* Space Holder right now*/}
                    <Grid item xs={12} sm={2} />
                  </Grid>
                  <Divider />
                  {/* Therapist Name */}
                  <Grid item container>
                    <Typography variant="h5" className={classes.primaryText}>
                      {formatName(data.Person.firstName, data.Person.middleInitial, data.Person.lastName)}
                    </Typography>
                    {/* Tab List */}
                    <Grid item xs={12} sm={12} className={classes.leftDetails}>
                      <VerticalUpdateTabs adminData={data} onRefetch={() => refetch()} />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <UploadAvatar id={data.Person.id} open={open} handleClose={handleCloseAvatar} admin />
        </>
      )}
    </div>
  )
}

export default EditTherapist
