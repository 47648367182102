import React from 'react'
import { Redirect } from '@reach/router'
import UpdateProfile from '../components/UpdateProfile'
import MainLayout from '../layouts/MainLayout'
import { useUser } from '../context/user-context'

export default function TherapistDashboard() {
  const { user } = useUser()

  if (user?.Person?.role === 'therapist') {
    return (
      <MainLayout>
        <UpdateProfile data={user} />
      </MainLayout>
    )
  } else {
    return <Redirect noThrow to="/" />
  }
}
