/* eslint-disable max-lines-per-function */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid,Container } from '@material-ui/core'
import TermsAndConditions from '../components/TermsAndConditionsScholarship.jsx'
import Layout from '../layouts/MainLayout'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
}))

export default function TermsScholarship() {
  const classes = useStyles()

  return (
    <Layout>
      <Container>
        <Grid container>
          <div className={classes.paper}>
          <TermsAndConditions />
          </div>
        </Grid>
      </Container>
    </Layout>
  )
}
