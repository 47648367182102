import { Avatar, Box, Button, Typography, makeStyles, styled, useMediaQuery, useTheme } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import verifiedIcon from "../../assets/img/verified.svg"
import React from 'react'
import ContactCardModal from './ContactCardModal';

const primary500 = "#1B3666";
const secondary500 ="#D49F02";

const useStyles = makeStyles(theme => ({
    therapistCard: {
        padding: "32px 16px",
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent:"space-between",
        border: "1px solid rgba(0,0,0, 0.35)",
        borderRadius: 8,
        maxWidth: 600,
        width: "100%",
        gap: 16,
        [theme.breakpoints.up('md')]: {
            gap: 100,
            maxWidth: 1200,
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 30,
            flexDirection: "row",
            border: "none",
            borderBottom: "1px solid rgba(0,0,0, 0.25)",
        },
    },
    avatar: {
        width: 64,
        height: 64,
        [theme.breakpoints.up('md')]: {
            width: 144,
            height: 144,
        },
    },
    therapistName: {
        fontSize: 18,
        fontWeight: "bold",
        fontFamily: "Inter",
        display: "inline",
        height: 27,
        lineHeight: "150%",
        color: primary500,
        [theme.breakpoints.up('md')]: {
            fontSize: 28,
        },
    },
    bio: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: "4",
        fontSize: 18,
        fontFamily: "Inter",
        WebkitBoxOrient: "vertical",
        letterSpacing: "0.2px"
    },
    verifiedIcon: {
        width: 16,
        height: 16,
        marginRight: 5,
        [theme.breakpoints.up('md')]: {
            width: 22,
            height: 22,
        },
    },
    verifiedText: {
        color: secondary500,
        fontWeight: 600,
        fontSize: 14,
    },
    verifiedBox: {
        marginLeft: 10,
        display: "inline-flex",
        alignItems: "center",
    },
    professionName: {
        fontSize: 12,
        fontFamily: "Inter",
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
    },
    specialtiesText: {
        fontFamily: "Inter",
        fontSize: 16
    },
    therapistButtons: {
        display: "flex", 
        flexDirection: "row",
        gap: 8,
        [theme.breakpoints.up('md')]: {
            flexDirection: "column",
            gap: 16,
        },
    }
}))

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "white",
    color: "black",
    border: "1px solid rgba(0,0,0,0.35)",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 15,
    fontFamily: "Inter",
    width: "100%",
    padding: "12px 24px",
    borderRadius: 8,
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
        width: 175,
        paddingLeft: 34.5,
        paddingRight: 34.5,
    },
}));

export default function TherapistInfoBox({therapist}) {
    const classes = useStyles();
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const Person = therapist.Person;
    const name = `${Person.firstName}${Person.middleInitial && ` ${Person.middleInitial}`} ${Person.lastName}`.trim();
    const addictions = Person.Specialties.filter((specialty)=> specialty.category === "Addiction");
    let specialties = addictions.length > 0 ? "Specializing in " : "";

    addictions.slice(0, 4).forEach(specialty =>{
        specialties = specialties + specialty.title.toLowerCase() + ", ";
    })
    if (addictions.length - 3 > 0) {
        specialties += `and ${addictions.length - 3} more`;
    }
    else if (addictions.length > 0) {
        specialties += `and more`;
    }
    const location = `${therapist.WorkLocation.city}, ${therapist.WorkLocation.province}`;

    const getWebsite = () => {
        for (let i = 0; i < therapist.Person.Links.length; i++) {
            const links = therapist.Person.Links;
            if (links[i].type == "website" && links[i].url !== "") {
                // if the url does not have https://, add it
                if (/^https?:\/\//i.test(links[i].url)) {
                    return links[i].url;
                }
                else {
                    return `http://${links[i].url}`;
                }
            }
        }
        return null;
    }

    const website = getWebsite();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box className={classes.therapistCard}>
            <Box sx={{display: "flex", justifyContent: "space-between", gap: 30, alignItems: "center"}}>
                <Box sx={{display:"flex", alignItems: "left", flexDirection:"column", gap: 30, textDecoration: "none", color: "black" }}  component={RouterLink} to={`/view/therapist/${therapist.id}`}>
                    <Box sx={{display: "flex", flexDirection: "row", gap: 16, alignItems: "center",
                             [theme.breakpoints.up('lg')]: { gap: 40 }}}>
                        <Avatar src={therapist.Person.avatar} className={classes.avatar}/>
                        <Box>
                            <Box sx={{ marginBottom: 6,
                                    [theme.breakpoints.up('lg')]: { marginBottom: 18,}}}>
                                <Typography className={classes.therapistName}>{name}</Typography>
                                <span className={classes.verifiedBox}>
                                    <img className={classes.verifiedIcon} src={verifiedIcon} alt="verified"/>
                                    <Typography className={classes.verifiedText}>Verified</Typography>
                                </span>
                            </Box>
                            <Box sx={{marginBottom: 8}}>
                                <Typography className={classes.professionName}>{therapist.professionName}</Typography>
                            </Box>
                            {greaterThanMid && <Typography className={classes.specialtiesText}>{specialties}</Typography>}
                        </Box>
                    </Box>
                    
                    {greaterThanMid && <Typography className={classes.bio}>{Person.bio}</Typography>}
                </Box>
            </Box>
                
            {!greaterThanMid && <Box>
                <Typography style={{fontSize: 14}}>{specialties}</Typography>
                <Typography style={{fontSize: 14, marginTop: 7}}>{location}</Typography>
            </Box>}

            <Box sx={{marginTop: 11, display: "flex", flexDirection:"column", gap: 30}}>
                <Box className={classes.therapistButtons}>
                    <ColorButton onClick={handleOpen}>Contact Now</ColorButton>
                    {website && <ColorButton href={website}
                          target="_blank"
                          rel="noopener noreferrer">View Website</ColorButton>}
                </Box>
                {greaterThanMid && <Box sx={{minWidth: 120, textAlign: "right"}}>
                    <Typography>{location}</Typography>
                </Box>}
            </Box>
            <ContactCardModal handleClose={handleClose} therapist={therapist} open={open} name={name}/>
        </Box>
    )
}
