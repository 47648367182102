import React from 'react'
import { Router } from '@reach/router'
import { mainRoutes, otherPublicRoutes, unAuthenticatedRoutes } from './routes'
import NotFound from './views/NotFound'

export default function UnauthenticatedApp() {
  const createRouteComponent = (route) =>
    route.param ? (
      <route.component path={`${route.path}/:${route.param}`} key={route.path} />
    ) : (
      <route.component path={route.path} key={route.path} />
    )

  return (
    <Router>
      {mainRoutes.map(createRouteComponent)}
      {unAuthenticatedRoutes.map(createRouteComponent)}
      {otherPublicRoutes.map(createRouteComponent)}
      <NotFound default />
    </Router>
  )
}
