import { Box, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import LocationAutocomplete from './LocationAutocomplete';
const indigoBlue = "#1B3666";

const useStyles = makeStyles(theme => ({
    resultDescription: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: 600,
        margin: "0 auto",
        [theme.breakpoints.up('md')]: {
            maxWidth: "100%",
            alignItems: "start",
            flexDirection: "column",
        },
    },
    filterLocationContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: 25,
        width: "75%",
        gap: 20,
        marginRight: 20,
        
        [theme.breakpoints.up('md')]: {
            justifyContent: "space-between",
            marginBottom: 32,
            width: "100%",
            marginRight: 0,
        },
    },
    showLocationSelectorButton: {
        textDecoration: "underline",
        cursor: "pointer"
    },
    resultsLocationHeading: {
        fontSize: 17,
        color: indigoBlue,
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
            fontSize: 40,
        },
    },
    allTherapists: {
        color: indigoBlue, 
        fontSize: "14px",
        [theme.breakpoints.up('md')]: {
            fontSize: "18px",
            fontWeight: 600, 
            marginBottom: 32,
        },
    },
    locationButton: {
        cursor: "pointer",
        textDecoration: "underline"
    }
}))

export default function FilterLocationSelector({locations, locationInput, setLocationInput, filterLocation, filteredTherapists, setFilterLocation}) {
    const [showLocationSelector, setShowLocationSelector] = useState(false);

    const handleClick = () => {
        setShowLocationSelector(oldShowLocationSelector => !oldShowLocationSelector)
    }
    const classes = useStyles();
    return (
        <Box className={classes.resultDescription}>
            <Box className={classes.filterLocationContainer}>
                {filterLocation ? 
                    <Typography className={classes.resultsLocationHeading}>Therapists near <span className={classes.locationButton} onClick={handleClick}>{`${filterLocation.fields.city}, ${filterLocation.fields.state}`}</span>
                    </Typography> :
                    <Typography className={classes.resultsLocationHeading}><span className={classes.showLocationSelectorButton} onClick={handleClick}>Enter a location</span></Typography>
                }
                {showLocationSelector && <LocationAutocomplete key={filterLocation} locations={locations} locationInput={locationInput} setLocationInput={setLocationInput} 
                                        locationSelected={filterLocation} setLocationSelected={setFilterLocation} forFilter={true}/>}
            </Box>
            <Typography className={classes.allTherapists}>{`${filteredTherapists.length} Results`} </Typography>
        </Box>
    )
}
