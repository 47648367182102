/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { getAllPartnersTherapists } from '../utils/client-api'
import TherapistInfoBox from '../components/ParticipatingTherapists/TherapistInfoBox'
import TherapistSearch from '../components/ParticipatingTherapists/TherapistSearch'
import TherapistPageButton from '../components/ParticipatingTherapists/TherapistPageButton'
import JoinToday from '../components/About Us/JoinToday'
import TopNavBar from '../components/TopNavBar'
import Footer from '../components/Footer'
import pic from '../assets/img/participating-therapist.jpg'
import { useFilters } from '../context/filter-context'
import FilterSelect from '../components/ParticipatingTherapists/FilterSelect'
import geoData from '../variables/latlng.json'
import Loading from '../components/Loading'
import { calcDistance } from '../utils/helper'
import DistanceFilter from '../components/ParticipatingTherapists/DistanceFilter'
import FilterLocationSelector from '../components/ParticipatingTherapists/FilterLocationSelector'
import mockTherapists from './therapistJson.json'
import Headers from '../components/Headers'

const indigoBlue = '#1B3666'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginText: {
    marginTop: 30,
  },
  topSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
    padding: '0 16px',
    paddingTop: 48,
    [theme.breakpoints.up('md')]: {
      padding: '0 50px',
      maxWidth: '100%',
      flexDirection: 'row',
      gap: 50,
      paddingTop: 100,
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      width: 1512,
      padding: '0 200px',
      paddingTop: 100,
      gap: 40,
    },
  },
  mainImage: {
    width: 'min(100%,600px)',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.10), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 32px 19px 0px rgba(0, 0, 0, 0.05), 0px 56px 23px 0px rgba(0, 0, 0, 0.01), 0px 88px 25px 0px rgba(0, 0, 0, 0.00)',
    height: 'auto',
    minHeight: 320,
    marginTop: 24,
    objectFit: 'cover',
    borderRadius: '6px',
    objectPosition: '40% 50%',
    [theme.breakpoints.up('md')]: {
      width: 454,
      height: 454,
      marginTop: 0,
      objectPosition: '40% 50%',
    },
  },
  mainTitle: {
    fontWeight: 700,
    fontSize: 28,
    color: indigoBlue,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: '130%',
      letterSpacing: '-1.5%',
    },
  },
  mainText: {
    marginTop: 40,
    fontWeight: 700,
    color: indigoBlue,
  },
  mainSection: {
    width: '100%',
    marginTop: 80,
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      padding: '0 64px',
      flexDirection: 'row',
      gap: 50,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1512,
      padding: '0 200px',
    },
  },
  description: {
    fontFamily: 'Inter',
    marginTop: 32,
    maxWidth: 700,
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  marginSection: {
    marginTop: 80,
  },
  therapistsSection: {
    textAlign: 'left',
  },
  filterContainer: {
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    gap: 20,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      flexDirection: 'row',
      margin: 0,
      gap: 16,
    },
  },
  resultsLocationHeading: {
    fontSize: 17,
    color: indigoBlue,
    fontWeight: 700,
    marginBottom: 25,
  },
  therapistContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 25,
    marginTop: 28,
    overflowX: 'auto',
    [theme.breakpoints.up('lg')]: {
      gap: 0,
    },
  },
  allTherapists: {
    color: indigoBlue,
    fontSize: '14px',
    marginBottom: 25,
    minWidth: 70,
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: 10,
    },
  },
  button: {
    border: '1px solid rgba(0,0,0,0.25)',
    color: 'black',
    backgroundColor: 'white',
    paddingLeft: 23,
    paddingRight: 23,
    height: 40,
    width: 120,
    fontSize: 20,
    fontFamily: 'Inter',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(0,0,0,0.1)',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  joinTodayText: {
    maxWidth: 600,
    marginTop: 20,
  },
  resetFilters: {
    textTransform: 'none',
    color: '#115CEF',
    fontSize: 16,
  },
  noResultsText: {
    paddingTop: 72,
    paddingBottom: 100,
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      paddingTop: 152,
    },
  },
  pageButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '50px 0',
    gap: 10,
    [theme.breakpoints.up('lg')]: {
      marginTop: 80,
      marginBottom: 0,
    },
  },
}))

const range = (start, end) => {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

const getPagesRange = (currPage, numPages, greaterThanMid) => {
  const maxPageInterval = greaterThanMid ? 5 : 3
  let start = greaterThanMid ? Math.max(currPage - 2, 1) : Math.max(currPage - 1, 1)
  let numLeft = maxPageInterval - (currPage - start + 1)
  const end = Math.min(currPage + numLeft, numPages)
  if (end - start + 1 < maxPageInterval) {
    numLeft = maxPageInterval - (end - start + 1)
    start = Math.max(start - numLeft, 1)
  }
  return range(start, end)
}

export default function ParticipatingTherapists() {
  const topOfTherapists = useRef(null)
  const goToTop = () => {
    topOfTherapists.current?.scrollIntoView({ behavior: 'smooth' })
  }

  // array of all therapists
  const { insurances, specialties } = useFilters()
  const addictions = specialties
    .filter((specialty) => specialty.category === 'Addiction')
    .map((addiction) => addiction.title)
  const sessionOptions = ['Remote']

  const [isLoading, setIsLoading] = useState(false)
  const [therapists, setTherapists] = useState([])
  const [searchedTherapists, setSearchedTherapists] = useState([])
  // array of therapists who match the filters
  const [filteredTherapists, setFilteredTherapists] = useState([])
  // array of therapists in the current page
  const [currPageTherapists, setCurrPageTherapists] = useState([])
  // number of pages of therapists (5 for each page)
  const [numPages, setNumPages] = useState(1)
  const [currPage, setCurrPage] = useState(1)

  // related to filters
  const [insuranceSelected, setInsuranceSelected] = useState([])
  const [specialtySelected, setSpecialtySelected] = useState([])
  const [sessionOptionSelected, setSessionOptionSelected] = useState([])
  const [filterLocation, setFilterLocation] = useState(null)
  const [filterLocationInput, setFilterLocationInput] = useState('')
  const [filterDistance, setFilterDistance] = useState(50)
  // related to search
  const [therapistInput, setTherapistInput] = useState('')
  const [searchSpecialtySelected, setSearchSpecialtySelected] = useState('')
  const [locationSelected, setLocationSelected] = useState(null)

  // function to filter out the therapists based on what the user selected
  const applyFilter = () => {
    const result = searchedTherapists.filter((therapist) => {
      const hasInsurance =
        insuranceSelected.length > 0
          ? therapist.Person.Insurances.some((insurance) => insuranceSelected.includes(insurance.title))
          : true
      const hasSpecialty =
        specialtySelected.length > 0
          ? therapist.Person.Specialties.some((specialty) => specialtySelected.includes(specialty.title))
          : true
      let hasSessionOptions = true
      if (sessionOptionSelected.indexOf('Remote') > -1) {
        hasSessionOptions = therapist.Person.SessionOption.remoteSession
      }
      let distance
      let isWithinDistance = true
      if (filterLocation) {
        if (filterLocation.fields.zip === therapist.WorkLocation.postalCode) {
          distance = 0
        } else {
          distance = getDistance(filterLocation.fields.zip, therapist.WorkLocation.postalCode)
        }
        // if the distance is less than or equal to 50 miles, return true
        isWithinDistance = distance <= filterDistance
      }
      return hasInsurance && hasSpecialty && hasSessionOptions && isWithinDistance
    })
    setFilteredTherapists(result)
  }

  // function to go to a page number and go to the top
  const goToPage = (pageNumber) => {
    setCurrPage(pageNumber)
    goToTop()
  }

  // removes all filters and resets the therapists
  const resetFilter = () => {
    setSearchedTherapists(therapists)
    setFilteredTherapists(therapists)
    setInsuranceSelected([])
    setSpecialtySelected([])
    setSessionOptionSelected([])
    setFilterLocation(null)
    setFilterDistance(50)
  }

  // calls the api for partner therapists
  async function fetchData() {
    const res = await getAllPartnersTherapists()
    return res
  }

  // get latitude and longitude of a zipcode
  function getLatLng(zipcode) {
    const entry = geoData.find((item) => {
      if (item.fields.zip === zipcode) {
        return true
      }
      return false
    })
    if (entry) {
      return {
        latitude: entry.fields.latitude,
        longitude: entry.fields.longitude,
      }
    }
    return null
  }

  // calculate the distance between two zipcodes
  function getDistance(zipcode1, zipcode2) {
    if (!zipcode1 || !zipcode2) return null
    const zip1 = getLatLng(zipcode1)
    const zip2 = getLatLng(zipcode2)
    if (zip1 && zip2) {
      const distance = calcDistance(zip1.latitude, zip1.longitude, zip2.latitude, zip2.longitude)
      return distance
    }
    return null
  }

  // executes the search, applying the filters specified in the search bar
  const executeSearch = () => {
    setIsLoading(true)
    fetchData().then((res) => {
      const searchResults = res.filter((therapist) => {
        const hasSpecialty =
          searchSpecialtySelected.trim().length > 0
            ? therapist.Person.Specialties.some((specialty) => specialty.title == searchSpecialtySelected)
            : true
        const hasName =
          therapistInput.trim().length > 0
            ? therapist.Person.firstName.toLowerCase().trim().includes(therapistInput.toLowerCase().trim()) ||
              therapist.Person.lastName.toLowerCase().trim().includes(therapistInput.toLowerCase().trim()) ||
              `${therapist.Person.firstName} ${therapist.Person.lastName}`
                .toLowerCase()
                .includes(therapistInput.toLowerCase().trim())
            : true
        let isWithinDistance = true
        let distance = null
        if (locationSelected) {
          setFilterLocation(locationSelected)
          if (locationSelected.fields.zip === therapist.WorkLocation.postalCode) {
            distance = 0
          } else {
            distance = getDistance(locationSelected.fields.zip, therapist.WorkLocation.postalCode)
          }
          // if the distance is less than or equal to 50 miles, return true
          if (distance === null) {
            isWithinDistance = false
          } else {
            isWithinDistance = distance <= 50
          }
        } else {
          setFilterLocation(null)
        }
        return hasSpecialty && hasName && isWithinDistance
      })
      resetFilter()
      setTherapists(res)
      setSearchedTherapists(searchResults)
      setFilteredTherapists(searchResults)
      setIsLoading(false)
      goToTop()
    })
  }

  // on load, get partnered therapists and display them
  useEffect(() => {
    //  On load, retrieve a snippet of therapists
    window.scroll(0, 0)
    setIsLoading(true)
    fetchData().then((res) => {
      setTherapists(res)
      setSearchedTherapists(res)
      setFilteredTherapists(res)
      const numOfPages = Math.max(Math.ceil(res.length / 5), 1)
      setNumPages(numOfPages)
      setIsLoading(false)
    })
  }, [])

  // whenever the current page changes, change the therapists displayed
  useEffect(() => {
    const offset = 5 * (currPage - 1)
    setCurrPageTherapists(filteredTherapists.slice(offset, offset + 5))
  }, [currPage])

  // when the filtered therapists change, calculate the new number of pages and got to page 1
  useEffect(() => {
    const numOfPages = Math.max(Math.ceil(filteredTherapists.length / 5), 1)
    setNumPages(numOfPages)
    setCurrPage(1)
    setCurrPageTherapists(filteredTherapists.slice(0, 5))
  }, [filteredTherapists])

  // whenever a filter is selected, apply them
  useEffect(() => {
    applyFilter()
  }, [insuranceSelected, specialtySelected, sessionOptionSelected, filterDistance, filterLocation])

  const classes = useStyles()
  const theme = useTheme()
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <>
      <Headers
        title="Find Our Partnered Therapists"
        type="website"
        description="Find a therapist who accepts our Lit Path addiction recovery scholarship. Therapists with specialized knowledge on sex, alcohol, technology, gambling, pornography, work, shopping, substance addiction, and/or food disorders."
      />
      <TopNavBar />
      <Box className={classes.mainContainer}>
        <Box className={classes.topSection}>
          <Box align="left" sx={{ width: 'min(600px, 100%)', maxWidth: 600 }}>
            <Typography variant="h1" className={classes.mainTitle}>
              Welcome to Our Network of Therapists
            </Typography>
            {!greaterThanMid && (
              <img className={classes.mainImage} src={pic} alt="a person sitting on a couch talking to a therapist" />
            )}
            <Typography variant="body1" component="h2" className={classes.description}>
              The therapists participating in our scholarship program are passionate about supporting individuals on
              their recovery journey. Each therapist brings a unique set of skills and expertise to help you on your
              recovery journey and find lasting healing. <br />
              <br />
              Explore this page to find the right therapist who resonates with you.
            </Typography>
          </Box>
          {greaterThanMid && (
            <img className={classes.mainImage} src={pic} alt="a person sitting on a couch talking to a therapist" />
          )}
        </Box>

        <TherapistSearch
          locations={geoData}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
          therapistInput={therapistInput}
          setTherapistInput={setTherapistInput}
          specialties={addictions}
          specialtySelected={searchSpecialtySelected}
          setSpecialtySelected={setSearchSpecialtySelected}
          executeSearch={executeSearch}
        />

        <Box ref={topOfTherapists} className={`${classes.mainText} ${classes.mainSection}`}>
          {isLoading && (
            <Box sx={{ width: '100%', height: '100%' }}>
              {' '}
              <Loading />{' '}
            </Box>
          )}
          {!isLoading && (
            <Box className={classes.therapistsSection}>
              <FilterLocationSelector
                locations={geoData}
                filterLocation={filterLocation}
                filteredTherapists={filteredTherapists}
                setFilterLocation={setFilterLocation}
                locationInput={filterLocationInput}
                setLocationInput={setFilterLocationInput}
              />
              <Box className={classes.filterContainer}>
                <FilterSelect
                  options={insurances}
                  setValue={setInsuranceSelected}
                  filterOption="Insurance"
                  selectedValue={insuranceSelected}
                />
                <FilterSelect
                  options={addictions}
                  setValue={setSpecialtySelected}
                  filterOption="Specialty"
                  selectedValue={specialtySelected}
                />
                {filterLocation && (
                  <DistanceFilter value={filterDistance} setValue={setFilterDistance} filterLocation={filterLocation} />
                )}
                <FilterSelect
                  options={sessionOptions}
                  setValue={setSessionOptionSelected}
                  filterOption="Session Options"
                  selectedValue={sessionOptionSelected}
                />
                <Button variant="text" className={classes.resetFilters} onClick={resetFilter}>
                  Reset Filters
                </Button>
              </Box>
              <Box className={classes.therapistContainer}>
                {currPageTherapists.length > 0 &&
                  currPageTherapists.map((therapist, ind) => <TherapistInfoBox key={ind} therapist={therapist} />)}
                {currPageTherapists.length === 0 && (
                  <Typography className={classes.noResultsText}>No Results</Typography>
                )}
                {currPageTherapists.length > 0 && (
                  <Box className={classes.pageButtons}>
                    {currPage !== 1 && (
                      <button className={classes.button} onClick={() => goToPage(currPage - 1)}>
                        Back{' '}
                      </button>
                    )}
                    {getPagesRange(currPage, numPages, greaterThanMid).map((currPageNum) => (
                      <TherapistPageButton
                        key={`page${currPageNum}`}
                        pageNumber={currPageNum}
                        goToPage={goToPage}
                        isActive={currPage == currPageNum}
                      />
                    ))}
                    {currPage !== numPages && (
                      <button className={classes.button} onClick={() => goToPage(currPage + 1)}>
                        Next{' '}
                      </button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <JoinToday />
      </Box>
      <Footer />
    </>
  )
}
