import React, {useState, useEffect, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import TimelineComponent from '../ScholarshipPage/TimelineComponent'

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1110px',
    height: '1580px',
    position: 'relative',
    margin: 'auto',
    overflow: 'clip',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '1900px',
    },
  },
  mainBranch: {
    width: '2px',
    background: '#1B3666',
    height: '100%',
    zIndex: 1,
    marginLeft: 'calc(50% - 1px)',
    position: 'absolute',
    marginTop: (props) => `${props.marginTop}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileBranch: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '1px',
      background: '#1B3666',
      height: '100%',
      zIndex: 1,
      marginLeft: 'calc(50% - 0.5px)',
      position: 'absolute',
      marginTop: '0px',
    },
  },
  textbox: {
    width: '50%',
    borderRadius: '10px',
    border: '2px solid #D49F02',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '26px 44px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px',
      border: '1px solid #D49F02',
      marginBottom: '40px',
      maxWidth: '312px',
    },
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.005em',
    textAlign: 'center',
    color: '#1B3666',
  },
  firstStep: {
    width: '100%',
    marginTop: '0px',
  },
}))

const FirstStep = React.forwardRef(({ className }, ref) => (
  <div className={className} ref={ref}>
    <TimelineComponent
      number="1"
      header="Review"
      description="Review the participation information and requirements available on our website."
      side="left"
    />
  </div>
))

FirstStep.displayName = 'FirstStep'

export default function ProcessTimeline() {
  const firstStepRef = useRef(null)
  const [marginTop, setMarginTop] = useState(0)

  const handleResize = () => {
    if (firstStepRef.current) {
      const firstStepHeight = (firstStepRef.current.offsetHeight / 2)
      setMarginTop(firstStepHeight)
    }
  }

  useEffect(() => {
    handleResize() 
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const classes = useStyles({ marginTop })

  return (
    <div className={classes.timelineContainer}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2,
          justifyContent: 'space-between',
        }}
      >
        <FirstStep
          className={classes.firstStep} ref={firstStepRef}
        />
        <TimelineComponent
          number="2"
          header="Submit Application"
          description="Submit your application through our website. Our team will carefully review your application."
          side="right"
        />
        <TimelineComponent
          number="3"
          header="Submit Documentation"
          description="Our team will carefully review your application, verifying your state license/registration and specialized certifications."
          side="left"
        />
        <div className={classes.textbox}>
          <Typography className={classes.text}>
            You will receive a decision on your application, along with subsequent communication from us.
          </Typography>
        </div>
        <TimelineComponent
          number="4"
          header="Complete Registration"
          description="Register on our website and update your profile with relevant information."
          side="right"
        />
        <TimelineComponent
          number="5"
          header="You're done!"
          description="Once registered, clients can start submitting scholarship applications for your services."
          side="left"
        />
        <div className={classes.textbox} style={{ marginBottom: '0px' }}>
          <Typography className={classes.text}>
            Join us in making a significant impact on the lives of those struggling with compulsive behavior(s).
            Participate today and become a vital part of our nationwide network of compassionate therapists. Together,
            we can make therapy more accessible and provide hope to those in need.
          </Typography>
        </div>
      </div>
      <div className={classes.mainBranch} />
      <div className={classes.mobileBranch}/>
    </div>
  )
}
