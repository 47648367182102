import React from 'react'
import validator from 'validator'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Grid, MenuItem, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useNavigate } from '@reach/router'
import { sendContactUs } from '../utils/client-api'
import FormErrors from './FormErrors'

const useStyles = makeStyles(theme => ({
  main: {
    textAlign: 'center',
  },
  submitBtn: {
    marginTop: theme.spacing(3),
  },
}))

export default function ContactForm() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [formErrors, setFormErrors] = React.useState([])

  const types = [
    'General Question',
    'Donate question',
    'Feedback',
    'Therapist Application Question',
    'Scholarship Application Question',
  ]
  const handleSubmit = e => {
    e.preventDefault()

    const body = {
      contactType: e.target.type.value,
      fromName: e.target.name.value.trim(),
      fromEmail: e.target.email.value.trim(),
      subject: e.target.subject.value.trim(),
      comments: e.target.comments.value,
    }

    // Validate input
    const errors = []
    if (validator.isEmpty(body.contactType)) {
      errors.push('Please select the type of request')
    }
    if (validator.isEmpty(body.fromName)) {
      errors.push('Name is required')
    }
    if (!validator.isEmail(body.fromEmail)) {
      errors.push('Email address is not valid')
    }
    if (validator.isEmpty(body.subject)) {
      errors.push('Subject is required')
    }
    if (validator.isEmpty(body.comments)) {
      errors.push('Comments is required')
    }

    // set errors
    setFormErrors(errors)
    // don't submit form if errors
    if (errors.length > 0) return

    sendContactUs(body)
      .then(() => {
        enqueueSnackbar('Request sent', { variant: 'success' })
        navigate('/')
      })
      .catch(() => {
        enqueueSnackbar('Request failed', { variant: 'error' })
      })
  }
  return (
    <form className={classes.main} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            select
            fullWidth
            align="left"
            defaultValue=""
            name="type"
            id="request"
            variant="outlined"
            label="Select the Request Type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {types.map(t => (
              <MenuItem key={t} value={t}>
                {t}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            variant="outlined"
            id="name"
            name="name"
            label="Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            variant="outlined"
            id="email"
            name="email"
            label="Email Address"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="subject"
            name="subject"
            variant="outlined"
            label="Subject"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            variant="outlined"
            id="comments"
            name="comments"
            label="Comments"
            multiline
            rows="6"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormErrors errors={formErrors} />
        </Grid>
      </Grid>
      <Button
        type="submit"
        className={classes.submitBtn}
        variant="contained"
        size="large"
        color="secondary"
      >
        Submit Request
      </Button>
    </form>
  )
}
