/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Button } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import DonateForm from '../components/DonateForm'
import HelpLayout from '../layouts/HelpLayout'
import MoreHelpSection from '../components/DonatePage/MoreHelpSection'
import SupporterLevels from '../components/DonatePage/SupporterLevels'
import DonateMobile from './DonateMobile'
//Images
import heroImage from '../assets/img/make-therapy-affordable-source.jpg'
import donorImage from '../assets/img/dmitry-ratushny-xsGApcVbojU-unsplash.jpg'
import sponsorImage from '../assets/img/nghia-le-V3DokM1NQcs-unsplash.jpg'
import foundingSponsorImage from '../assets/img/Lit-Path-Founding-Sponsorship.jpg'
import fundraiserImage from '../assets/img/2023-Fundraiser.jpg'
import Headers from '../components/Headers'

const primaryColor = '#1B3666'
const width = '1110px'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  section: {
    padding: '80px 200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '80px 112px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'left',
    width: '85%',
    margin: 'auto',
    marginBottom: '200px',
    gap: '20px',
    zIndex: 2,
  },
  header: {
    color: primaryColor,
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '52px',
    letterSpacing: '-0.015em',
    textAlign: 'center',
  },
  cardHeader: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-1.3%',
    color: 'black',
    textAlign: 'left',
  },
  cardText: {
    fontFamily: 'Inter',
    color: '#000000',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.01em',
    textAlign: 'left',
  },
  cardImage: {
    width: '454px',
    height: '454px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  button: {
    borderRadius: '100px',
    background: primaryColor,
    color: 'white',
    padding: '16px 32px',
    height: '59px',
    width: '190px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#132648',
    },
  },
  cardContainer: {
    gap: '10%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    maxWidth: width,
    justifyContent: 'space-between',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  heroTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    textAlign: 'left',
    justifyContent: 'center',
    width: '55%',
    color: primaryColor,
  },
  heroHeader: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '62px',
    letterSpacing: '-0.015em',
  },
  heroSubheader: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.013em',
    color: 'black',
  },
  heroText: {
    fontFamily: 'Inter',
    color: '#000000',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  contactButton: {
    width: '165px',
  },
  marketingImage: {
    width: '45%',
    height: 'auto',
    objectFit: 'contain',
    boxShadow:
      '0px 0px 0px 0px #0000001A, 0px 2.9271488189697266px 7.317872047424316px 0px #0000001A, 0px 13.17216968536377px 13.17216968536377px 0px #00000017, 0px 30.735063552856445px 19.02646827697754px 0px #0000000D, 0px 55.61582946777344px 21.953617095947266px 0px #00000003, 0px 86.35089111328125px 23.417190551757812px 0px #00000000',
  },
}))

const marketingImages = [
  { image: foundingSponsorImage, alt: 'Litpath sponsorship campagin card' },
  { image: fundraiserImage, alt: 'Litpath fundraiser campaign card' },
]

export default function Donate() {
  const classes = useStyles()
  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HelpLayout>
      <Headers
        title="Donate To Support Addiction Recovery!"
        type="website"
        description="Donate to support addiction recovery therapy today! Support those struggling with sex addiction, alcohol addiction, technology addiction, gambling addiction, pornography addiction, work addiction, shopping addiction, food disorders, and other addictions."
      />
      <div className={classes.mainContainer}>
        <div className={classes.section}>
          <div className={classes.cardContainer} style={{ gap: '32px' }}>
            <div className={classes.heroTextContainer}>
              <Typography className={classes.heroHeader} component="h1">
                Be the Beacon of Hope for Recovery
              </Typography>
              <Typography component="h3" className={classes.heroSubheader}>
                Your Support Creates Life-Changing Opportunities.
              </Typography>
              <Typography component="p" className={classes.heroText}>
                At Lit Path, we are on a mission to light the path to recovery for individuals struggling with
                compulsive behavior(s). By supporting us, you can make a profound difference in the lives of those
                seeking healing and transformation. Together, we can empower individuals to overcome compulsive
                behavior(s), find hope, and rebuild their lives.
              </Typography>
              <Link component={RouterLink} to="/donate-form">
                <Button className={classes.button}>Donate Today</Button>
              </Link>
            </div>
            <img
              align="right"
              className={classes.cardImage}
              style={{ width: '450px', height: '390px' }}
              alt="a glass cup with coins and a plant growing out of it"
              src={heroImage}
            />
          </div>
        </div>

        <div className={classes.section}>
          <Typography className={classes.header} component="h2">
            Make a personal impact
          </Typography>
          <div className={classes.cardContainer}>
            <img
              align="left"
              className={classes.cardImage}
              style={{ width: '360px', height: '425px' }}
              alt="a close up of a child's face looking through a small hole with light shining through"
              src={donorImage}
            />
            <div className={classes.columnContainer} style={{ gap: '32px', width: '58%' }}>
              <Typography component="h5" className={classes.cardHeader}>
                Every Donation Counts
              </Typography>
              <Typography className={classes.cardText}>
                Your individual donation can have a direct and tangible impact on someone's recovery journey. By
                contributing to Lit Path, you are helping us provide partial scholarships for psychotherapy, ensuring
                that individuals receive the support they need to heal and thrive. <br /> <br />
                No amount is too small, and every dollar brings us closer to our goal of making recovery therapy
                accessible for all. Your generosity not only transforms lives but also fosters a stronger, more
                compassionate community. Make a personal impact today by donating to Lit Path and being a catalyst for
                change.
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.section}>
          <Typography className={classes.header} component="h2">
            Inspire transformation through sponsorships
          </Typography>
          <div className={classes.cardContainer}>
            <div className={classes.columnContainer} style={{ gap: '32px', width: '58%' }}>
              <Typography component="h5" className={classes.cardHeader} style={{ fontWeight: 'bold' }}>
                Ignite Change on a Larger Scale
              </Typography>
              <Typography component="body1" className={classes.cardText}>
                Sponsorships play a vital role in fueling the transformational work we do at Lit Path. By becoming a
                sponsor, you have the power to ignite change on a larger scale and support multiple individuals on their
                recovery journeys. <br /> <br />
                Through sponsorships, we can expand our scholarship program, reach more therapists, and extend our
                impact to those who need it the most.
              </Typography>
              <Link component={RouterLink} to="/contact" style={{ marginRight: 'auto' }}>
                <Button className={clsx(classes.button, classes.contactButton)}>Contact Us</Button>
              </Link>
            </div>
            <img
              align="right"
              className={classes.cardImage}
              style={{ width: '360px', height: '760px' }}
              alt="a person standing on a mountain top with their thumbs up seeing the sun shining through the clouds"
              src={sponsorImage}
            />
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <MoreHelpSection />
        </div>
      </div>
      <DonateMobile />
    </HelpLayout>
  )
}
