import { Box, Container, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  image: {
    height: '235px',
    width: '100%',
    objectFit: 'cover',
  },
  card: {
    width: 'min(100%,352px)',
    overflow: 'hidden',
    borderRadius: 6,
    filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.25));',
  },
  textBox: {
    height: 250,
    padding: '20px 20px',
    [theme.breakpoints.up('xl')]: {
      padding: '20px 45px',
    },
  },
  cardSubtitle: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 16,
  },
  cardBody: {
    fontFamily: 'Inter',
    fontSize: 15,
  },
}))

export default function HomePageCard({ img, title, description, alt }) {
  const classes = useStyles()
  return (
    <Paper className={classes.card}>
      <img className={classes.image} src={img} alt={alt} />
      <Box className={classes.textBox}>
        <Typography className={classes.cardSubtitle}>{title}</Typography>
        <Typography className={classes.cardBody}>{description}</Typography>
      </Box>
    </Paper>
  )
}
