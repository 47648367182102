import axios from 'axios'
const localStorageKey = '__therapy_reviews_token__'
// Formats any api calls to add header if exists and any other data or configuration
function call(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey)
  const headers = {
    'content-type': 'application/json',
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  // Using Axios
  const config = {
    url: endpoint,
    // REACT_APP_SERVER_URL is defined in webpack
    // eslint-disable-next-line no-undef
    baseURL: REACT_APP_SERVER_URL,
    method: body ? 'POST' : 'GET',
    ...customConfig,
    withCredentials: true,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.data = body instanceof FormData ? body : JSON.stringify(body)
  }
  // return the results from api
  // no error handling so that a .catch can be used on the method calling this function
  return axios(config).then((r) => r.data)
}

function searchLicensedTherapists(query) {
  return call(`/api/v1/public/search/?search=${query}`)
}
function searchPartnersTherapists(query) {
  return call(`/api/v1/public/search/?search=${query}&partner=true`)
}
function getAllLicensedTherapists() {
  return call(`/api/v1/public/therapist/`)
}
function getAllPartnersTherapists() {
  return call(`/api/v1/public/therapist?partner=true`)
}
function getPublicLicensedTherapist(id) {
  return call(`/api/v1/public/therapist/${id}`)
}
// review object contains: { rating, comment, age, gender, therapistId }
function postReview(review) {
  return call(`/api/v1/review`, {
    body: review,
  })
}
function reportReview(id) {
  return call(`/api/v1/review/${id}/flag`, { method: 'PUT' })
}
function sendContactUs(body) {
  return call(`/api/v1/public/contact`, { body })
}
function sendMessage(body) {
  return call(`/api/v1/public/message`, { body })
}
function getFilters() {
  return call(`/api/v1/public/search/filters`)
}
function validateCoupon(code) {
  return call(`/api/v1/coupon/verify?code=${code}`)
}
function getActiveSubscriptionPlans() {
  return call(`/api/v1/plan?status=active`)
}

function saveWebsiteVisit(website) {
  const body = { message: `Website click to ${website}`, meta: { type: 'statistic', link: website } }
  return call(`/api/v1/system/log`, { body })
}

function enrollEmailToNewsletter(email, firstName, lastName) {
  const body = { email, firstName, lastName }
  return call(`/api/v1/newsletter`, { body })
}

export {
  call,
  getAllLicensedTherapists,
  getAllPartnersTherapists,
  getPublicLicensedTherapist,
  postReview,
  reportReview,
  searchLicensedTherapists,
  searchPartnersTherapists,
  sendContactUs,
  sendMessage,
  getFilters,
  validateCoupon,
  getActiveSubscriptionPlans,
  saveWebsiteVisit,
  enrollEmailToNewsletter,
}
