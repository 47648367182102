import { getUser } from './auth-api'

// Get user data for initial app load if access token exists
async function bootstrapAppData() {
  const data = await getUser()
  if (!data) {
    return { user: null }
  }
  const { user } = data
  return {
    user,
  }
}

export { bootstrapAppData }
