import { Button, withStyles, makeStyles, Box } from '@material-ui/core';
import React from 'react'

const ColorButton = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0,0,0,0.25)",
    color: "black",
    backgroundColor: "white",
  },
}))(Button);

const useStyles = makeStyles(theme => ({
    button: {
        border: "1px solid rgba(0,0,0,0.25)",
        color: "black",
        backgroundColor: "white",
        width: 40,
        height: 40,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        fontSize: 20,
        "&:hover": {
        backgroundColor: 'rgb(0,0,0,0.1)'
        }
    },
    active: {
        color: "white",
        backgroundColor: "#D49F02",
        "&:hover": {
            backgroundColor: '#D49F02'
        }
    },
}))


export default function TherapistPageButton({goToPage, pageNumber, isActive}) {
    const classes = useStyles();
    return (
        <Box className={`${classes.button} ${isActive ? classes.active : ""}`}
            onClick={()=>{
                if (!isActive) {
                    goToPage(pageNumber);
                }
            }}>
            {pageNumber}
        </Box>
    )
}
