import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { LocalPhoneOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '90%',
    padding: '20px',
    backgroundColor: '#2E416B',
    borderRadius: '10px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
  },
}))

const HelpLineBox = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <div className={classes.icon}>
            <LocalPhoneOutlined fontSize="large" />
          </div>
        </Grid>
        <Grid item xs={10}>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}

export default HelpLineBox
