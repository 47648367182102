import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { Link, Button, useMediaQuery, useTheme } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import clsx from 'clsx'
import scholarshipsImg from '../../assets/img/kal-visuals-aK4iPNYipnU-unsplash.jpg'
import whatItCoversImg from '../../assets/img/jeremy-bishop-jPPHYSez-s4-unsplash.jpg'
import findTherapistsImg from '../../assets/img/linkedin-sales-solutions-W3Jl3jREpDY-unsplash.jpg'
import TitleSlide from './TitleSlide'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  overflowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifySelf: 'left',
    overflowX: 'hidden',
    alignItems: 'flex-start',
    zIndex: 1,
  },
  slideContainer: {
    minWidth: '100%',
    transform: (index) => `translateX(${-index * 100}%)`,
    transition: 'all 0.5s ease',
  },
  buttonContainer: {
    display: 'flex',
    width: '205px',
    height: '64px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 80px 0px',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px',
      position: 'absolute',
      top: '630px',
    },
  },
  active: {
    width: '20px',
    height: '20px',
    flexShrink: 0,
    background: '#D49F02',
    borderRadius: '50%',
    boxShadow: '0px 4px 4px 0px #00000040',
    border: 'none',
  },
  inactive: {
    width: '20px',
    height: '20px',
    flexShrink: 0,
    background: '#F0F0F0',
    borderRadius: '50%',
    boxShadow: '0px 4px 4px 0px #00000040',
    border: 'none',
  },
  applyButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      borderRadius: '100px',
      background: '#1B3666',
      color: 'white',
      padding: '16px 32px',
      height: '59px',
      width: '275px',
      textTransform: 'none',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '27px',
      boxShadow:
        '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
      '&:hover': {
        backgroundColor: '#132648',
      },
    },
  },
  arrowButton: {
    background: 'transparent',
    color: '#1B3666',
    border: 'none',
    cursor: 'pointer',
    padding: '20px',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      position: 'unset',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  leftArrow: {
    left: '100px',
    [theme.breakpoints.down('md')]: {
      left: '25px',
    },
  },
  rightArrow: {
    right: '100px',
    [theme.breakpoints.down('md')]: {
      right: '25px',
    },
  },
}))

const slideContent = [
  {
    image: scholarshipsImg,
    header: 'Scholarships for Compulsive Behavior(s) Recovery',
    description:
      'Available for both process (food, gambling, sex, pornography, and others) and/or substance compulsive behavior(s) recovery therapy sessions.',
    alt: 'a person standing on a cliff with a smoke cloud in the air with mountains in the background',
  },
  {
    image: whatItCoversImg,
    header: 'What it Covers',
    description:
      'Scholarship are PARTIAL: Covers up to $65 per session, totaling up to $1040 for the program. Includes 16 sessions to be utilized within a 22-week period.',
    alt: 'a person in a white dress in a field of flowers',
  },
  {
    image: findTherapistsImg,
    header: 'Find Therapists',
    description: 'Enables scholarship recipients to choose any participating therapist in the program.',
    alt: 'a person sitting at a table talking with another person',
  },
]

const Slideshow = () => {
  const [index, setIndex] = useState(0)
  const classes = useStyles(index)
  const [intervalId, setIntervalId] = useState(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const startInterval = () => {
    clearInterval(intervalId)
    const newIntervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % slideContent.length)
    }, 15000)
    setIntervalId(newIntervalId)
  }

  useEffect(() => {
    startInterval()
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', width: '100%' }}>
        {!isMobile && (
          <button
            className={
              index > 0
                ? clsx(classes.arrowButton, classes.leftArrow)
                : clsx(classes.arrowButton, classes.hidden, classes.leftArrow)
            }
            onClick={() => setIndex(index - 1)}
          >
            <ArrowBackIos fontSize="medium" />
          </button>
        )}
        <div className={classes.overflowContainer}>
          {slideContent.map((slide) => (
            <div className={classes.slideContainer} key={slide}>
              <TitleSlide {...slide} />
            </div>
          ))}
        </div>
        {!isMobile && (
          <button
            className={
              index < 2
                ? clsx(classes.arrowButton, classes.rightArrow)
                : clsx(classes.arrowButton, classes.hidden, classes.rightArrow)
            }
            onClick={() => setIndex(index + 1)}
          >
            <ArrowForwardIos fontSize="medium" />
          </button>
        )}
      </div>

      <div className={classes.buttonContainer}>
        {isMobile && (
          <button
            className={index > 0 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index - 1)}
          >
            <ArrowBackIos fontSize="medium" />
          </button>
        )}
        <button
          className={index == 0 ? classes.active : classes.inactive}
          onClick={() => {
            setIndex(0)
            startInterval()
          }}
        />
        <button
          className={index == 1 ? classes.active : classes.inactive}
          onClick={() => {
            setIndex(1)
            startInterval()
          }}
        />
        <button
          className={index == 2 ? classes.active : classes.inactive}
          onClick={() => {
            setIndex(2)
            startInterval()
          }}
        />
        {isMobile && (
          <button
            className={index < 3 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index + 1)}
          >
            <ArrowForwardIos fontSize="medium" />
          </button>
        )}
      </div>
      <Link component={RouterLink} to="/scholarship-form">
        <Button className={classes.applyButton}>Scholarship Application </Button>
      </Link>
    </div>
  )
}

export default Slideshow
