import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Grid } from '@material-ui/core'
import MainLayout from '../layouts/MainLayout'
import ContactForm from '../components/ContactForm'
import image from '../assets/img/contact-us-hello.jpg'
import Headers from '../components/Headers'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  margin: {
    height: theme.spacing(6),
  },
  subTitle: {
    color: theme.palette.grey[600],
    marginBottom: 4,
  },
  formContainer: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(3),
    background: `rgba(255,255,255,0.8)`,
  },
  image: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    height: 200,

    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
}))

export default function Contact() {
  const classes = useStyles()
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <MainLayout>
      <Headers
        title="Contact Us | Lit Path"
        type="website"
        description="Contact us for any questions or concerns you may have."
      />
      <Grid container>
        <Grid item xs={12} md={7} className={classes.image} />
        <Grid item xs={12} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography align="center" variant="h4" component="h1">
              How may we help you?
            </Typography>
            <Typography align="center" className={classes.subTitle} variant="h4" component="h3">
              Submit a request
            </Typography>
            <div className={classes.margin} />
            <ContactForm />
          </div>
        </Grid>
      </Grid>
    </MainLayout>
  )
}
