import React from 'react'
import { Box, Container, Typography, Grid, makeStyles, withStyles, Button } from '@material-ui/core'
import discoverIcon from '../../assets/img/discoverIcon.png'
import resourceIcon from '../../assets/img/resourceIcon.png'
import LinkButton from '../LinkButton'

const primary400 = '#495E85'
const useStyles = makeStyles((theme) => ({
  getMoreSupportSection: {
    padding: '56px 14.5px',
    backgroundColor: primary400,
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '80px',
    },
  },
  marginText: {
    marginTop: 64,
  },
  smallMarginTop: {
    marginTop: 10,
  },
  bolded: {
    fontWeight: 600,
  },
  headingText: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 24,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      marginBottom: 48,
    },
  },
  supportLayout: {
    marginTop: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 56,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '0 80px 0 80px',
      gap: 100,
    },
  },
}))

export default function GetMoreSupportSection() {
  const classes = useStyles()
  return (
    <Box className={classes.getMoreSupportSection}>
      <Box
        sx={{
          width: 'min(776px, 100%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: 'min(590px, 100%)' }}>
          <Typography className={classes.headingText}>GET MORE SUPPORT</Typography>
          <Typography className={classes.smallMarginTop} style={{ fontFamily: 'Inter' }}>
            Take the next step in your recovery journey by exploring our
            <span style={{ fontWeight: 600 }}> More Resources </span>
            page. Unlock the possibilities and find the support you need to thrive.
          </Typography>
        </Box>
        <Box className={classes.supportLayout}>
          <Box sx={{ width: 332 }}>
            <img src={discoverIcon} alt="discover" />
            <Typography
              className={`${classes.smallMarginTop} ${classes.bolded}`}
              style={{ fontSize: 24, fontWeight: 700 }}
            >
              Discover Programs and <br /> Fellowships
            </Typography>
            <Typography className={classes.smallMarginTop} style={{ fontFamily: 'Inter' }}>
              Get additional support and guidance on your path to healing
            </Typography>
          </Box>
          <Box sx={{ width: 332 }}>
            <img src={resourceIcon} alth="resources" />
            <Typography className={classes.smallMarginTop} style={{ fontSize: 24, fontWeight: 700 }}>
              Access to Resources
            </Typography>
            <Typography className={classes.smallMarginTop} style={{ fontFamily: 'Inter' }}>
              Look through a diverse range of resources designed to empower your recovery and help you navigate the
              challenges you may face.
            </Typography>
          </Box>
        </Box>
        <LinkButton
          className={classes.marginText}
          fontColor="#1B3666"
          bgColor="white"
          text="See More Resources"
          variant="medium"
          route="/more-resources"
        />
      </Box>
    </Box>
  )
}
