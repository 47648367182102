import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
})

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

export default function ReviewFormRadioButtons({
  userType,
  age,
  gender,
  displayName,
  handleInputChange,
}) {
  return (
    <div>
      {/*<Grid container spacing={3} justify="space-evenly" alignItems="flex-start">*/}
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item style={{marginRight: '60px'}}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Recommended By</FormLabel>
            <RadioGroup
              aria-label="userType"
              name="userType"
              value={userType}
              onChange={handleInputChange}
              align="left"
            >
              <FormControlLabel
                value="client"
                control={<StyledRadio />}
                label="Client"
              />
              <FormControlLabel
                value="therapist"
                control={<StyledRadio />}
                label="Therapist"
              />
              <FormControlLabel
                value="professional"
                control={<StyledRadio />}
                label="Professional"
              />
              <FormControlLabel
                value="religiousLeader"
                control={<StyledRadio />}
                label="Religious Leader"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Your Age Range</FormLabel>
            <RadioGroup
              aria-label="age"
              name="age"
              value={age}
              onChange={handleInputChange}
              align="left"
            >
              <FormControlLabel
                value="1"
                control={<StyledRadio />}
                label="< 25 years"
              />
              <FormControlLabel
                value="2"
                control={<StyledRadio />}
                label="25 - 45"
              />
              <FormControlLabel
                value="3"
                control={<StyledRadio />}
                label="> 45 years"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {/*<Grid container spacing={3} justify="space-evenly" alignItems="flex-start">*/}
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item style={{marginRight: '60px'}}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleInputChange}
              align="left"
            >
              <FormControlLabel
                value="f"
                control={<StyledRadio />}
                label="Female"
              />
              <FormControlLabel
                value="m"
                control={<StyledRadio />}
                label="Male"
              />
              <FormControlLabel
                value=""
                control={<StyledRadio />}
                label="Prefer not to provide"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Make my recommendation anonymous</FormLabel>
            <RadioGroup
              aria-label="displayName"
              name="displayName"
              value={displayName}
              onChange={handleInputChange}
              align="left"
            >
              <FormControlLabel
                value="yes"
                control={<StyledRadio />}
                label="Yes"
              />
              <Typography style={{fontSize: '0.7rem', marginTop: '-7px', marginLeft: '24px'}}>We will not display your name with your recommendation</Typography>
              <FormControlLabel
                value="no"
                control={<StyledRadio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}
