import React, {useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { Button, Link, Popover, MenuItem } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  applyButton: {
    background: 'white',
    boxShadow: 'none',
    border: '1px solid #1B3666',
    borderRadius: '100px',
    padding: '10px 20px',

    color: '#1B3666',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  text: {
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
    textTransform: 'capitalize',
  },
  triangleTip: {
    position: 'absolute',
    top: '1px',
    left: '12px',
    borderLeft: '7.5px solid transparent',
    borderRight: '7.5px solid transparent',
    borderBottom: '10px solid white',
    zIndex: 1,
  },
  triangleOutline: {
    position: 'absolute',
    top: '0px',
    left: '7.5px',
    borderLeft: '12px solid transparent',
    borderRight: '12px solid transparent',
    borderBottom: '16px solid #56575F',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}))

export default function ApplyNowButton() {
  const classes = useStyles()
  

    const [popover, setPopover] = useState(false)
    const anchor = useRef(null)

    const handleOpen = ({currentTarget}) => {
        setPopover(true)
    }

    const handleClose = ({currentTarget}) => {
        setPopover(false)
    }

  return (
    <div>
      <Button
        ref={anchor}
        variant="contained"
        size="medium"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        className={clsx(classes.text, classes.applyButton)}
      >
        Apply Now
      </Button>
      <Popover
        anchorEl={anchor.current}
        open={popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popover}
        PaperProps={{
          style: {
            boxShadow: 'none',
            paddingTop: '10px',
            borderRadius: '0px',
            background: 'transparent',
          },
          onMouseEnter: handleOpen,
          onMouseLeave: handleClose,
        }}
        classes={{ paper: classes.popoverContent }}
      >
        <div className={classes.triangleOutline} />
        <div className={classes.triangleTip} />
        <div style={{ position: 'relative', border: '1px solid #56575F', padding: '18px 5px', background: 'white' }}>
          <MenuItem onClick={handleClose}>
            <Link
              key="/therapist-application"
              component={RouterLink}
              to="/therapist-application"
              className={classes.text}
            >
              Therapist Application
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link key="/scholarships" component={RouterLink} to="/scholarships" className={classes.text}>
              Scholarship Application
            </Link>
          </MenuItem>
        </div>
      </Popover>
    </div>
  )
}
