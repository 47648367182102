import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Grid, Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}))

export default function AccountRegistration({ value, handleInputChange }) {
  const classes = useStyles()

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form className={classes.form}>
          <Grid container spacing={2} justify="center" align="flex-start">
            <Grid item xs={10}>
              <TextField
                required
                value={value.firstName}
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={value.middleInitial}
                id="middleInitial"
                name="middleInitial"
                label="M.I."
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={value.lastName}
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={value.email}
                id="email"
                name="email"
                label="Email"
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={value.password}
                id="password"
                name="password"
                label="Password"
                fullWidth
                onChange={handleInputChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={value.confirmPassword}
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                fullWidth
                onChange={handleInputChange}
                type="password"
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
