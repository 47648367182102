import React from 'react'
import { Link } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Drawer, Toolbar, List, ListItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core'
// Icons
import {
  RateReview as RateReviewIcon,
  SupervisorAccount as AccountsIcon,
  ListAlt as LogsIcon,
  Search,
} from '@material-ui/icons'
import TopNavBar from '../components/TopNavBar'
import Copyright from '../components/Copyright'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 64,
  },
  main: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    flexGrow: 1,
  },
  appBar: {
    width: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  footer: {
    // padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: 'white',
    color: theme.palette.common.white,
  },
}))

const dashboardNav = [
  { text: 'Accounts', icon: <AccountsIcon />, path: 'accounts' },
  //{ text: 'Reviews', icon: <RateReviewIcon />, path: 'reviews' },
  { text: 'Logs', icon: <LogsIcon />, path: 'logs' },
  { text: 'Search Therapists', icon: <Search />, path: 'licensed-therapists' },
]

export default function DashboardLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* navbar */}
      <div className={classes.appBar}>
        <TopNavBar />
      </div>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem>
              <ListItemText primary="Admin Dashboard" />
            </ListItem>
          </List>
          <Divider />
          <List>
            {dashboardNav.map((item, index) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      {/* main content */}
      <div className={classes.content}>
        <Container className={classes.main}>{children}</Container>
        <div className={classes.footer}>
          <Copyright />
        </div>
      </div>
    </div>
  )
}
