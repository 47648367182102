import React from 'react'
import { Typography } from '@material-ui/core'

export default function FormErrors({ errors = [] }) {
  return (
    <div>
      {errors.map((error, i) => (
        <Typography key={i} color="error" align="left">
          - {error}
        </Typography>
      ))}
    </div>
  )
}
