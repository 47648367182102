import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import clsx from 'clsx'
const useStyles = makeStyles(theme => ({
  avatar: {
    height: 100,
    width: 100,
    marginRight: theme.spacing(1),
    border: '1px solid black',
  },
}))

export default function TherapistAvatar({ src, className }) {
  const classes = useStyles()
  return (
    <Avatar
      className={clsx(classes.avatar, className)}
      alt="therapist"
      src={src}
    />
  )
}
