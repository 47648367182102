/* eslint-disable max-lines-per-function */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import withOtherOption from './otherHOC'
import InputAdornment from '@material-ui/core/InputAdornment';

// TODO pull list data from database
const insuranceList = [
  'Aetna',
  'Anthem Inc',
  'Blue Cross Blue Shield',
  'Centene Corp',
  'Cigna Health',
  'Humana',
  'Insurance Not Accepted',
  'Medicare',
  'Molina Healthcare Inc.',
  'UnitedHealth',
  'Wellcare',
]
const paymentList = ['Visa', 'American Express', 'Master Card', 'Venmo', 'Zelle', 'Paypal', 'Cash', 'HSA', 'FSA', 'Check'].sort()
const focusAgeList = [
  'Children (Under 10)',
  'Preteen (11-13)',
  'Teens (14-17)',
  'Young Adult (18-20)',
  'Adult (21-64)',
  'Senior (65+)',
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: '200px',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  days: {
    minWidth: '85px',
  },
  margin: {
    marginBottom: theme.spacing(3),
  },
  time: {
    justifyContent: 'space-between',
  },
  leftAlign: {
    textAlign: 'left',
  },
}))

export function BusinessLocationForm({ value, handleInputChange }) {
  return (
    <React.Fragment>
      <Typography variant="h6" align="left" gutterBottom>
        Business Infomation
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            value={value.businessName}
            onChange={handleInputChange}
            id="businessName"
            name="businessName"
            label="Business Name"
            fullWidth
            autoComplete="bname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.address1}
            onChange={handleInputChange}
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={value.address2}
            onChange={handleInputChange}
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            value={value.city}
            onChange={handleInputChange}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField value={value.state} onChange={handleInputChange} id="state" name="state" label="State" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            value={value.zip}
            onChange={handleInputChange}
            id="zip"
            name="zip"
            label="Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.phoneNumber}
            onChange={handleInputChange}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            autoComplete="phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.faxNumber}
            onChange={handleInputChange}
            id="faxNumber"
            name="faxNumber"
            label="Fax Number"
            fullWidth
            autoComplete="fax number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.businessEmail}
            onChange={handleInputChange}
            id="businessEmail"
            name="businessEmail"
            label="Business Email"
            fullWidth
            autoComplete="email"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export function SessionFeeForm({ value, handleInputChange, handleToggle }) {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            value={value.sessionFeeMin}
            onChange={handleInputChange}
            id="sessionFeeMin"
            name="sessionFeeMin"
            label="Average Session Fee"
            fullWidth
            autoComplete=""
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            value={value.sessionFeeMax}
            onChange={handleInputChange}
            id="sessionFeeMax"
            name="sessionFeeMax"
            label="Maximum Session Fee"
            fullWidth
            autoComplete="$"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  )
}
export function ClientAge({ value, handleInputChange }) {
  const classes = useStyles()

  const isChecked = (age) => {
    return value.indexOf(age) > -1
  }

  const error = value.length < 1

  return (
    <div className={classes.root}>
      <FormControl error={error} align="left" variant="filled" className={classes.formControl}>
        <InputLabel id="focusAge-checklist-label">Client Focus Age</InputLabel>
        <Select
          fullWidth
          labelId="focusAge-checklist-label"
          id="focusAge-checklist"
          multiple
          onChange={handleInputChange}
          name="focusAge"
          value={value}
          renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
        >
          {focusAgeList.map((focusAge) => (
            <MenuItem key={focusAge} value={focusAge}>
              <Checkbox checked={isChecked(focusAge)} />
              <ListItemText primary={focusAge} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select at least one</FormHelperText>
      </FormControl>
    </div>
  )
}
export function AcceptedInsurance({ value, handleInputChange }) {
  const classes = useStyles()

  const isChecked = (insurance) => {
    return value.indexOf(insurance) > -1
  }

  return (
    <div className={classes.root}>
      <FormControl align="left" variant="filled" className={classes.formControl}>
        <InputLabel id="insurance-checklist-label">Accepted Insurance</InputLabel>
        <Select
          fullWidth
          labelId="insurance-checklist-label"
          id="insurance-checklist"
          multiple
          onChange={handleInputChange}
          name="insurance"
          value={value}
          renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
        >
          {insuranceList.map((insurance) => (
            <MenuItem key={insurance} value={insurance}>
              <Checkbox checked={isChecked(insurance)} />
              <ListItemText primary={insurance} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Choose all that apply</FormHelperText>
      </FormControl>
    </div>
  )
}
export function PaymentMethod({ value, handleInputChange }) {
  const classes = useStyles()

  const isChecked = (paymentMethod) => {
    return value.indexOf(paymentMethod) > -1
  }

  const error = value.length < 1

  return (
    <div className={classes.root}>
      <FormControl error={error} align="left" variant="filled" className={classes.formControl}>
        <InputLabel id="payment-checklist-label">Payment Method</InputLabel>
        <Select
          fullWidth
          labelId="payment-checklist-label"
          id="payment-checklist"
          multiple
          onChange={handleInputChange}
          name="paymentMethod"
          value={value}
          renderValue={(selected) => ` ${selected[0]}(+${selected.length - 1})`}
        >
          {paymentList.map((payment) => (
            <MenuItem key={payment} value={payment}>
              <Checkbox checked={isChecked(payment)} />
              <ListItemText primary={payment} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Select at least one</FormHelperText>
      </FormControl>
    </div>
  )
}
const useHoursStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  days: {
    minWidth: '85px',
  },
}))
// Add Business hours
export function BusinessHoursForm({ value, handleInputChange }) {
  const classes = useHoursStyle()
  const list = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  const getDayHours = (day, index) => {
    return value.hours[day][index]
  }
  const capitalizeFirstLetter = (string) => (string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : '')
  return (
    <React.Fragment>
      <Typography className={classes.title} variant="h6" align="left" gutterBottom>
        Business Hours
      </Typography>
      <Grid container spacing={2}>
        {list.map((day) => (
          <Grid container item key={day} direction="row" spacing={2}>
            <Grid item>
              <Typography className={classes.days} xs={12} sm={3} variant="subtitle1" align="left">
                {capitalizeFirstLetter(day)}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                value={getDayHours(day, 0)}
                name={`${day}-0`}
                onChange={handleInputChange}
                label="Open"
                type="time"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800, // 30 min
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                value={getDayHours(day, 1)}
                name={`${day}-1`}
                onChange={handleInputChange}
                label="Close"
                type="time"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800, // 30 min
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export function CheckRemoteSesssion({ value, handleInputChange }) {
  const classes = useStyles()
  return (
    <FormControl component="fieldset">
      Remote Session via Phone or Video
      <RadioGroup onChange={handleInputChange} name='remoteSession' value={value.remoteSession}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  )
}
export function CheckSlidingScale({ value, handleInputChange }) {
  const classes = useStyles()
  return (
    <FormControl component="fieldset">
      Sliding Scale Available
      <RadioGroup onChange={handleInputChange} name='slidingScale' value={value.slidingScale}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  )
}

export const ClientAgeWithOtherOption = withOtherOption(ClientAge, 'focusAge')
export const AcceptedInsuranceWithOtherOption = withOtherOption(AcceptedInsurance, 'insurance')
export const PaymentMethodWithOtherOption = withOtherOption(PaymentMethod, 'paymentMethod')
