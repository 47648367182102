/* eslint-disable complexity */
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
// import { useUser } from '../../context/user-context'
import { updateBio } from '../../utils/therapist-api'
import { AboutForm } from './UpdatePersonalForms'

const useStyles = makeStyles(theme => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export default function AboutInfo({ user, reloadUser, nextTab }) {
  const { enqueueSnackbar } = useSnackbar()
  // const { user, reloadUser } = useUser()
  const classes = useStyles()
  const initialState = {
    bio: user.Person.bio || '',
  }
  const [value, setValue] = useState(() => initialState)
  const [status, setStatus] = useState('idle')
  useEffect(() => {
    let canceled = false
    if (status === 'save') {
      // TODO: validate inputs
      // TODO: API call
      updateBio(user.personId, value)
        .then(() => {
          enqueueSnackbar('Update successful', { variant: 'success' })
          if (canceled) return
          setStatus('success')
          reloadUser()
          nextTab(0)
          window.scrollTo(0, 0)
        })
        .catch(() => {
          enqueueSnackbar('Error updating profile', { variant: 'error' })
          if (canceled) return
          setStatus('failure')
        })
    }
    return () => (canceled = true)
  }, [enqueueSnackbar, reloadUser, status, user.id, user.personId, value])

  const handleInputChange = event => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }

  return (
    <>
      <AboutForm value={value} handleInputChange={handleInputChange} />
      <div className={classes.btnContainer}>
        <Button
          disabled={status === 'save'}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => setStatus('save')}
        >
          {status === 'save' ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}
