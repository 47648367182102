import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMachine } from '@xstate/react'
import { Typography, Container, Paper } from '@material-ui/core'
import claimProfileMachine from '../utils/claimMachine'
import ClaimProfileInfo from '../components/ClaimProfileInfo'
import EnterCode from '../components/EnterCode'
import RegistrationForm from '../components/RegistrationForm'
import VerifyProfile from '../components/VerifyProfile'
import MainLayout from '../layouts/MainLayout'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(4),
  },
}))

export default function ClaimProfile({ location }) {
  const classes = useStyles()
  const data = location.state
  const [current, send] = useMachine(claimProfileMachine)

  const handleContinue = () => {
    send('CONTINUE')
  }

  if (!data) {
    return (
      <MainLayout>
        <Typography>Select a Therapist from the search list to claim the profile</Typography>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <div className={classes.header}>
        <Typography variant="h4" component="h1">
          Apply to be a partner therapist
        </Typography>
        <Typography variant="h6" component="h2">
        Apply to be a partner therapist by following the steps below and help your clients find you today!
        </Typography>
      </div>
      <Container maxWidth="md">
        <Paper className={classes.content}>
          {current.value === 'info' && (
            <div>
              <ClaimProfileInfo onContinue={handleContinue} name={data.Person.firstName} />
            </div>
          )}
          {current.value === 'verify' && (
            <div>
              <VerifyProfile data={data} onSubmit={() => send('SUBMIT')} onGoBack={() => send('GOBACK')} />
            </div>
          )}
          {current.value === 'code' && (
            <div>
              <EnterCode data={data} onResolve={() => send('RESOLVE')} onReject={() => send('REJECT')} />
            </div>
          )}
          {current.value === 'claimed' && (
            <div>
              <RegistrationForm data={data} />
            </div>
          )}
          {current.value === 'failure' && (
            <div>
              Too many attempts using invalid code. Request a new code or contact an administrator for help claiming
              your profile.
            </div>
          )}
        </Paper>
      </Container>
    </MainLayout>
  )
}
