import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { Typography, ButtonBase } from '@material-ui/core'

export default function SimpleDialogSpan(props) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <span>
      <ButtonBase onClick={handleClickOpen}>
        <Typography
          style={{
            color: '#56575F',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
          }}
          variant="body1"
        >
          {props.title}
        </Typography>
      </ButtonBase>
      <Dialog onClose={handleClose} open={open}>
        {props.children}
      </Dialog>
    </span>
  )
}

SimpleDialogSpan.propTypes = {
  title: PropTypes.string.isRequired,
}
