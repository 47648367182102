import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Typography, Divider } from '@material-ui/core'
import clsx from 'clsx'
import { useUser } from '../context/user-context'

import example from '../assets/img/example.png'

const useStyles = makeStyles(() => ({
  placeholder: {
    width: 500,
    height: 500,
    border: '1px solid grey',
    backgroundImage: `url(${example})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  bold: {
    fontWeight: 600,
  },
  leftAlign: {
    textAlign: 'left',
  },
}))

export default function ClaimProfileInfo({ onContinue, name }) {
  const classes = useStyles()

  const { user, reloadUser } = useUser()
  return (
    <Grid container spacing={2} justify="space-around" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          Profile Example
        </Typography>
        <Typography className={classes.placeholder} />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <Typography variant="h5" color="primary" align="center">
          How to Claim and Pricing
        </Typography>
        <Typography className={classes.leftAlign} gutterBottom>
          Hello {name}!<br />
          Thank you for taking the steps to claim your profile with Lit Path, a place where people can help
          encourage others in finding their new therapist. Before we get started, we wanted to let you know that there
          are 2 ways to claim your profile: Automatically or Manually.
        </Typography>

        <Typography className={clsx(classes.leftAlign, classes.bold)}>Automatic:</Typography>
        <Typography className={classes.leftAlign} gutterBottom>
          Most users will be able to claim their profile automatically, as long as their email or cell phone number
          matches the information in the state where their license was issued. If your information matches the States
          information, then getting your profile set up will be quick and easy. Simply click Continue below, and on the
          next step, you will confirm either your email or phone number. You will quickly receive a numerical code to
          input on the next screen to proceed with claiming your profile.
        </Typography>
        <Typography className={clsx(classes.leftAlign, classes.bold)}>Manual:</Typography>
        <Typography className={classes.leftAlign} gutterBottom>
          For those whose cell phone and email no longer matches what the state has listed on their records, will be
          using the manual approach. This process will require additional documentation to confirm that the identity or
          the user belongs to the correct profile. Click the button below to begin this process.
        </Typography>
        <br />
        {user ? (
          <Button size="large" color="warning" variant="contained">
            You must Logout before claiming a profile
          </Button>
        ) : (
          <Button size="large" color="secondary" variant="contained" onClick={onContinue}>
            CONTINUE
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
