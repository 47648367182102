import React from 'react'
import { Typography } from '@material-ui/core'
import MainLayout from '../layouts/MainLayout'
import { useUser } from '../context/user-context'
import Loading from '../components/Loading'

export default function NotFound() {
  const { loadingUser } = useUser()
  return (
    <MainLayout>
      {loadingUser ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h1">404</Typography>
          <Typography>Cannot find the URL</Typography>
        </>
      )}
    </MainLayout>
  )
}
