import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// Material UI core components
import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

// const headCells = [
//   { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
//   { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
//   { id: 'rating', numeric: false, disablePadding: false, label: 'Rating' },
// ]

// eslint-disable-next-line no-lone-blocks
{
  /* <EnhancedTableHead
      headCells={headCells}
      numSelected={selected.length}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={handleSelectAllClick}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
    /> */
}

export default function EnhancedTableHead(props) {
  const classes = useStyles()
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, checkbox } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {checkbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        ) : (
          <TableCell />
        )}

        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
