import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { Typography, Link} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Add } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '52px 60px',
    position: 'relative',
    borderBottom: '2px solid #E8EBF0',
    background: (props) => (props.isOpen ? '#ECF1FA' : '#F8FAFE'),
    boxShadow: (props) => (props.isOpen ? '0px 4px 4px 0px #00000040' : 'unset'),
    zIndex: (props) => (props.isOpen ? 2 : 1),
    '&:hover': {
      backgroundColor: '#ECF1FA',
      color: '#1B3666',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },
  textBox: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  numberText: {
    fontFamily: 'Inter',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '58px',
    letterSpacing: '0.25px',
    color: (props) => (props.isOpen ? '#1B3666' : '#1B366680'),
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '70%',
  },
  questionText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '29px',
    textAlign: 'left',
    color: '#1B3666',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      letterSpacing: '0.01em',
    },
  },
  iconBox: {
    width: '15%',
    maxWidth: '150px',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '32px',
      minWidth: '32px',
      maxHeight: '32px',
      minHeight: '32px',
    },
  },
  closedIcon: {
    background: 'white',
    color: '#000000',
    transition: 'transform 0.3s ease',
  },
  openedIcon: {
    transform: 'rotate(45deg)',
    background: '#D49F02',
    color: 'white',
    transition: 'transform 0.3s ease',
  },
  answerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  answerText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#5A5365',
  },
  mobileTextBox: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '10px',
    },
  },
  mobileQuestionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}))

export default function FaqBox(props) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles({ isOpen })

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const parseText = (text) => {
    const lines = text.split('||')
    const linkRegex = /\[(.*?)\]\((.*?)\)/
    const elements = []

    for (const line of lines) {
      const match = line.match(linkRegex)
      const listMatch = line.match(/li\[(.*?)\]/)
      if (match) {
        const label = match[1]
        const path = match[2]

        elements.push(
          <Link component={RouterLink} to={path} key={path} style={{ fontWeight: 'bold' }}>
            {label}
          </Link>,
        )
      } else if (line.match(/br/)) {
        elements.push(<br />)
      } else if (listMatch) {
        elements.push(<br />)
        elements.push(<React.Fragment> • {listMatch[1]}</React.Fragment>)
      } else {
        elements.push(<React.Fragment>{line}</React.Fragment>)
      }
    }
    return <React.Fragment>{elements}</React.Fragment>
  }

  return (
    <div className={classes.container} onClick={toggleDropdown}>
      <div className={classes.textBox}>
        <div className={classes.iconBox}>
          <Typography variant="h3" className={classes.numberText}>
            {props.number}
          </Typography>
        </div>
        <div className={classes.questionBox}>
          <Typography variant="h4" className={classes.questionText}>
            {props.question}
          </Typography>
          {isOpen && <Typography className={classes.answerText}> {parseText(props.answer)}</Typography>}
        </div>
        <div className={classes.iconBox}>
          <div className={isOpen ? clsx(classes.icon, classes.openedIcon) : clsx(classes.icon, classes.closedIcon)}>
            <Add />
          </div>
        </div>
      </div>
      <div className={classes.mobileTextBox}>
        <Typography variant="h3" className={classes.numberText}>
          {props.number}
        </Typography>
        <div className={classes.mobileQuestionBox}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4" className={classes.questionText}>
              {props.question}
            </Typography>
            <div className={isOpen ? clsx(classes.icon, classes.openedIcon) : clsx(classes.icon, classes.closedIcon)}>
              <Add />
            </div>
          </div>
          {isOpen && <Typography className={classes.answerText}> {parseText(props.answer)}</Typography>}
        </div>
      </div>
    </div>
  )
}

FaqBox.propTypes = {
  number: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}
