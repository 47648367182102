import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    flexGrow: 1,
    background: '#D49F02',
    maxWidth: '1px',
    marginRight: '80px',
    marginLeft: '80px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '40px',
      marginLeft: '40px',
    },
  },
  text: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
}))

const labels = [
  'Guiding Star 🌟',
  'Brilliant Aurora  🌟',
  'Rising Sun  🌟',
  'Light Keeper ★',
  'Lantern Leader ★',
  'Torch Bearer ★',
  'Candle Lighter',
  'Spark Igniter',
]

const values = [
  '$50,000',
  '$35,000',
  '$20,000',
  '$10,000',
  '$5,000',
  '$1,000',
  '$500',
  '$100'
]

export default function SupporterLevels() {
  const classes = useStyles()
  return (
    <div className={classes.mainContainer}>
      <div className={classes.textContainer} >
        {labels.map((label, index) => (
          <Typography key={index} className={classes.text}>
            {label}
          </Typography>
        ))}
      </div>
      {/* <Divider className={classes.divider} orientation="vertical" /> */}
      <div className={classes.divider}/>
      <div className={classes.textContainer}>
        {values.map((value, index) => (
          <Typography key={index} className={classes.text}>
            {value}
          </Typography>
        ))}
      </div>
    </div>
  )
}
