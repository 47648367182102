/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { useLocation, navigate, Link as RouterLink } from '@reach/router'
import queryString from 'query-string'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Link, Typography, Container, Grid, Paper, Box, Button, withStyles, useMediaQuery } from '@material-ui/core'

import HomeLayout from '../layouts/HomeLayout'
import { useAuth } from '../context/auth-context'
import HomePageCard from '../components/HomePage/HomePageCard'
// import images
import makeTherapyAfforableImg from '../assets/img/make-therapy-affordable-source.jpg'
import fundScholarshipImg from '../assets/img/fund-scholarships.png'
import therapistsImg from '../assets/img/therapists img.png'
import whoWeAreImg from '../assets/img/who_we_are.jpg'
import homeTherapistsImg from '../assets/img/homeTherapists.jpg'
import homeDonorsImg from '../assets/img/homeDonors.jpg'
import homeScholarshipImg from '../assets/img/homeScholarship.jpg'
import LinkButton from '../components/LinkButton'
import Headers from '../components/Headers'

const primary = '#1B3666'
const secondary = '#D49F02'
const tertiary = '#FFFBE8'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignContent: 'space-around',
  },
  mainContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: '0px 200px',
    },
  },
  marginSection: {
    marginTop: 56,
    [theme.breakpoints.up('md')]: {
      marginTop: 160,
    },
  },
  marginText: {
    marginTop: 32,
  },
  subtitle: {
    fontWeight: 600,
    color: primary,
    marginBottom: 40,
  },
  text: {
    marginTop: '24px',
  },
  headingText: {
    fontSize: 28,
    fontWeight: 700,
    marginBottom: 24,
    color: primary,
    marginTop: 64,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      marginBottom: 24,
      marginTop: 80,
    },
  },
  bodyText: {
    fontSize: 16,
    fontFamily: 'Inter',
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: 24,
    },
  },
  whoWeAre: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 32,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 590,
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      gap: 56,
    },
  },
  whoWeAreImage: {
    width: 'min(590px, 100%)',
    height: 'auto',
    aspectRatio: 'auto',
    borderRadius: 6,
    filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.25));',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'min(50%,600px)',
      height: 'auto',
    },
  },
  whoWeAreText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'min(453px, 50%)',
    },
  },
  homeImage: {
    width: 'min(590px, 100%)',
    height: 'auto',
    aspectRatio: 'auto',
    borderRadius: 6,
    filter: 'drop-shadow(0px 4px 4px rgba(0,0,0,0.25));',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'min(500px, 50%)',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 32,
    alignItems: 'center',
    marginTop: 32,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '0 30px',
      marginTop: 96,
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      justifyContent: 'center',
      gap: 150,
    },
  },
  reverse: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  togetherContainer: {
    width: '100%',
    backgroundColor: tertiary,
    marginTop: 32,
    padding: '40px 16px',
    [theme.breakpoints.up('md')]: {
      marginTop: 100,
      padding: '64px 16px',
    },
  },
  togetherText: {
    width: 'min(579px, 100%)',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    gap: 24,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  togetherHeading: {
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
}))

const cardInfo = [
  {
    img: makeTherapyAfforableImg,
    title: 'We Make Therapy Affordable',
    description:
      'Provide financial assistance to those struggling with compulsive behaviors through scholarships for mental health therapy to support recovery.',
    alt: 'a glass cup with coins and a plant growing out of it',
  },
  {
    img: fundScholarshipImg,
    title: 'We Fund Scholarships for Recovery Therapy',
    description: 'Raise funds for mental health therapy scholarships.',
    alt: 'a person holding a jar of coins with donate written on it',
  },
  {
    img: therapistsImg,
    title: 'We Partner with Qualified Therapists',
    description: 'Partner with therapy specialists to provide therapy for scholarship recipients.',
    alt: 'a person sitting on a couch talking to a therapist',
  },
]

export default function Home() {
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation()
  const { checkToken } = useAuth()

  useEffect(() => {
    const q = queryString.parse(location.search)
    console.log('Home -> queryString', queryString)

    if (q.token) {
      checkToken(q.token).then(() => navigate(q.redirectPath ? q.redirectPath : '/'))
    }
  }, [location.search])
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <HomeLayout>
      <Headers
        title="Lit Path | Lighting the Path to Recovery"
        type="website"
        description="Lit Path nonprofit provides partial scholarships for psychotherapy to support recovery from gambling, food, sex, pornography, shopping, tech, substance, etc addictions. FREE to participate."
      />
      <Box align="center" className={classes.mainContainer}>
        <Box sx={{ width: 'min(590px, 100%)' }}>
          <Typography component="h1" className={classes.headingText}>
            How Lit Path Makes a Difference
          </Typography>
          <Typography
            className={classes.text}
            align="center"
            style={{ fontSize: 18, fontFamily: 'Inter' }}
            component="p"
          >
            At Lit Path, we offer partial scholarships for psychotherapy sessions with certified professionals, making
            recovery therapy more accessible and affordable for everyone.
          </Typography>
        </Box>

        <Box sx={{ marginTop: '32px', [theme.breakpoints.up('md')]: { marginTop: '64px' } }}>
          <Box className={classes.cardContainer}>
            {cardInfo.map((card) => {
              return <HomePageCard {...card} key={card} />
            })}
          </Box>
        </Box>

        <Box className={classes.marginSection}>
          <Typography component="h2" className={classes.headingText}>
            Who We Are
          </Typography>
          <Box className={classes.whoWeAre}>
            <Box className={classes.whoWeAreText}>
              <Typography
                style={{ fontSize: 18, fontFamily: 'Inter', letterSpacing: '1%', lineHeight: '150%' }}
                className={classes.bodyText}
                component="p"
              >
                Lit Path is a nonprofit 501(c)(3) organization dedicated to supporting individuals on their journey to
                recovery from compulsive behaviors.
              </Typography>
              <Typography
                style={{ fontSize: 18, fontFamily: 'Inter', letterSpacing: '1%', lineHeight: '150%' }}
                component="p"
                className={`${classes.bodyText} ${classes.marginText}`}
              >
                We provide partial scholarships for psychotherapy with certified professionals, making it more
                accessible and affordable for everyone. Through our programs and resources, we strive to break down
                barriers, offer compassionate support, and empower individuals to reclaim their lives from the grips of
                compulsive behaviors.
              </Typography>
              {greaterThanMid && (
                <LinkButton
                  className={classes.marginText}
                  fontColor="white"
                  bgColor="#1B3666"
                  variant="medium"
                  text="Learn More"
                  route="about"
                />
              )}
            </Box>
            <img
              className={classes.whoWeAreImage}
              src={whoWeAreImg}
              alt="two people's feet on a tile floor with Passion Led Us Here written on it"
            />
          </Box>
          {!greaterThanMid && (
            <LinkButton
              className={classes.marginText}
              fontColor="white"
              bgColor="#1B3666"
              variant="medium"
              text="Learn More"
              route="about"
            />
          )}
        </Box>

        <Box sx={{ width: 'min(100%, 590px)' }} className={classes.marginSection}>
          <Typography component="h2" className={`${classes.text} ${classes.headingText}`}>
            Together,
            <br /> We Can Light The Path To Recovery
          </Typography>
          <Typography
            component="p"
            style={{ marginTop: 24, textAlign: greaterThanMid ? 'center' : 'left', fontFamily: 'Inter' }}
          >
            Whether you're a therapist looking to make a difference, someone seeking support on their recovery journey,
            or an individual wanting to contribute to our cause, there are multiple ways you can get involved.
          </Typography>
          <Typography className={classes.marginText} component="p" style={{ fontWeight: 600, fontSize: 24 }}>
            Take the first step today:
          </Typography>
        </Box>

        <Box className={classes.feature}>
          <img
            className={classes.homeImage}
            src={homeTherapistsImg}
            alt="a person sitting in a chair talking to a therapist"
          />
          <Box sx={{ height: '100%', width: 'min(384px, 100%)' }}>
            <Typography style={{ fontWeight: 700, fontSize: 24 }}>Therapists</Typography>
            <Typography className={`${classes.marginText} ${classes.bodyText}`}>
              Register as a therapist with Lit Path to offer scholarships to your clients.
            </Typography>
            <LinkButton
              className={classes.marginText}
              fontColor="white"
              bgColor="#1B3666"
              variant="medium"
              text="Register as a Therapist"
              route="/therapist-application"
            />
          </Box>
        </Box>

        <Box className={`${classes.feature} ${classes.reverse}`}>
          <Box sx={{ height: '100%', width: 'min(384px, 100%)' }}>
            <Typography style={{ fontWeight: 700, fontSize: 24 }}>Donors</Typography>
            <Typography className={`${classes.marginText} ${classes.bodyText}`}>
              Donate to help us support more people with compulsive behavioral condition(s) and break down financial
              barriers to recovery.
            </Typography>
            <LinkButton
              className={classes.marginText}
              fontColor="white"
              bgColor="#1B3666"
              variant="medium"
              text="Make a Donation Here"
              route="/donate"
            />
          </Box>
          <img className={classes.homeImage} src={homeDonorsImg} alt="a person holding money in their hands" />
        </Box>

        <Box className={classes.feature}>
          <img
            className={classes.homeImage}
            src={homeScholarshipImg}
            alt="a person making a heart with their hands with sunshine shining through the heart"
          />
          <Box sx={{ height: '100%', width: 'min(384px, 100%)' }}>
            <Typography style={{ fontWeight: 700, fontSize: 24 }}>Scholarship Applicants</Typography>
            <Typography className={`${classes.marginText} ${classes.bodyText}`}>
              Apply for a scholarship to receive the support you need on your recovery journey.
            </Typography>
            <LinkButton
              className={classes.marginText}
              fontColor="white"
              bgColor="#1B3666"
              variant="medium"
              text="Apply for a Scholarship"
              route="/scholarships"
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.togetherContainer}>
        <Box className={classes.togetherText}>
          <Typography className={classes.togetherHeading}>
            Together, we can make a difference in the lives of individuals and families affected by compulsive
            behaviors.
          </Typography>
          <Box sx={{ width: 100, height: 5, backgroundColor: secondary }} />
          <Typography className={classes.bodyText}>
            We can bring hope, healing, and transformation to those struggling with compulsive behaviors. Join us on
            this empowering journey and let's light the path towards a brighter future.
          </Typography>
        </Box>
      </Box>
    </HomeLayout>
  )
}
