/* eslint-disable max-lines-per-function */
import React from 'react'
import { Link as RouterLink } from '@reach/router'
// Material-UI Core components
import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// Material-UI Icons
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
// Context
import clsx from 'clsx'
import { useUser } from '../context/user-context'
import Logo from '../assets/img/lit-logo.png'
// Custom components
import Terms from '../views/Terms'
import TermsTherapist from '../views/TermsTherapist'
import TermsScholarship from '../views/TermsScholarship'
import Privacy from '../views/Privacy'
import Copyright from './Copyright'
import SimpleDialogSpan from './SimpleDialogSpan'
import NewsletterForm from './NewsletterForm'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    backgroundColor: 'white',
    color: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '75px 0px',
    boxShadow: '0px 4px 4px 0px #00000040 inset',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
      gap: '0px',
    },
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70%',
    padding: '50px 0px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '24px 0px',
      width: '100%',
    },
  },
  linkColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      fontSize: '14px',
      padding: '24px 0px',
    },
  },
  linkText: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '150%',
    color: '#535457',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  headerText: {
    color: '#1B3666',
    fontWeight: 700,
  },
  sectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    gap: '40px',
    [theme.breakpoints.down('sm')]: {
      gap: '32px',
    },
  },
  captionContainer: {
    width: '70%',
    height: '75px',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
    },
  },
  captionText: {
    fontFamily: 'Inter',
    color: 'black',
    fontSize: '12px',
    lineHeight: '16px',
  },
  sectionDivider: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '30px',
    borderTop: '2px solid #E8EBF0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      gap: '24px',
    },
  },
  policyContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    maxWidth: '600px',
    gap: '10px',
    justifyContent: 'left',
  },
  copyrightContainer: {
    display: 'flex',
    justifyContent: 'left',
  },
  termsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexWrap: 'wrap',
      columnGap: '10px',
    },
  },
  socialLogoContainer: {
    display: 'flex',
    width: '7%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      gap: '10px',
    },
  },
  socialLogo: {
    color: '#B28302',
    cursor: 'pointer',
    width: '25px',
    height: '25px',
    alignItems: 'center',
  },
  logo: {
    height: '75px',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  badge: {
    width: '75px',
    height: '75px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
  divider: {
    height: '1rem',
    background: '#56575F',
    width: '0.5px',
  },
  width100: {
    width: '100%',
  },
  width20: {
    width: '20%',
  },
  mobileSection: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  mobileColumn: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
  },
  largeGap: {
    [theme.breakpoints.down('sm')]: {
      gap: '32px',
    },
  },
  smallGap: {
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const { user } = useUser()

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <NewsletterForm />

        <div className={classes.linkContainer}>
          <div className={classes.linkColumn}>
            <Typography className={clsx(classes.linkText, classes.headerText)}>Lit Path</Typography>
            <Link component={RouterLink} to="/" onClick={handleClick} className={classes.linkText}>
              Home
            </Link>
            <Link component={RouterLink} to="/about" onClick={handleClick} className={classes.linkText}>
              About Us
            </Link>
            <Link component={RouterLink} to="/contact" onClick={handleClick} className={classes.linkText}>
              Contact Us
            </Link>
          </div>
          <div className={classes.linkColumn}>
            <Typography className={clsx(classes.linkText, classes.headerText)}>Therapists</Typography>
            <Link component={RouterLink} to="/find" onClick={handleClick} className={classes.linkText}>
              Participating Therapists
            </Link>
            <Link component={RouterLink} to="/therapist-application" onClick={handleClick} className={classes.linkText}>
              Apply as a Therapist
            </Link>
            <Link component={RouterLink} to="/login/:display" onClick={handleClick} className={classes.linkText}>
              Therapist Log In
            </Link>
          </div>
          <div className={classes.linkColumn}>
            <Typography className={clsx(classes.linkText, classes.headerText)}>Donors</Typography>
            <Link component={RouterLink} to="/donate" onClick={handleClick} className={classes.linkText}>
              Donate Now
            </Link>
            <Link component={RouterLink} to="/donate" onClick={handleClick} className={classes.linkText}>
              Become a Sponsor
            </Link>
          </div>
          <div className={classes.linkColumn}>
            <Typography className={clsx(classes.linkText, classes.headerText)}>Scholarship Applicants</Typography>
            <Link component={RouterLink} to="/scholarships" onClick={handleClick} className={classes.linkText}>
              Apply for a Scholarship
            </Link>
            <Link component={RouterLink} to="/find" onClick={handleClick} className={classes.linkText}>
              Find a Therapist
            </Link>
            <Link component={RouterLink} to="/more-resources" onClick={handleClick} className={classes.linkText}>
              More Resources
            </Link>
          </div>
        </div>

        <div className={clsx(classes.bottomContainer, classes.mobileSection)}>
          <div className={clsx(classes.sectionContainer, classes.width100, classes.mobileColumn, classes.largeGap)}>
            <div className={clsx(classes.sectionContainer, classes.width20, classes.mobileSection)}>
              <Link className={classes.logo} component={RouterLink} to="/">
                <img src={Logo} alt="logo" className={classes.logo} />
              </Link>
              <a
                className={classes.badge}
                href="https://www.guidestar.org/profile/shared/03c3b1b0-80b5-4fc0-82f6-d0817ea13592"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://widgets.guidestar.org/TransparencySeal/9966042"
                  className={classes.badge}
                  alt="Silver Transparency 2023 Candid"
                />
              </a>
            </div>

            <div className={clsx(classes.sectionContainer, classes.captionContainer)}>
              <Typography variant="caption" className={classes.captionText}>
                Lit Path is a nonprofit 501(c)(3) organization focused on providing access to therapy for those who want
                to break free of compulsive behavior(s).
              </Typography>
            </div>
          </div>

          <div className={classes.sectionDivider}>
            <div className={clsx(classes.policyContainer, classes.mobileColumn, classes.smallGap)}>
              <div className={classes.copyrightContainer}>
                <Copyright />
              </div>
              <div className={classes.termsContainer}>
                <Typography className={classes.btn}>
                  <SimpleDialogSpan title="Terms of Service">
                    <Terms />
                  </SimpleDialogSpan>
                </Typography>
                <div className={classes.divider} />
                <Typography className={classes.btn}>
                  <SimpleDialogSpan title="Participating Therapist Agreement">
                    <TermsTherapist />
                  </SimpleDialogSpan>
                </Typography>
                <div className={classes.divider} />
                <Typography className={classes.btn}>
                  <SimpleDialogSpan title="Scholarship Recipient Agreement">
                    <TermsScholarship />
                  </SimpleDialogSpan>
                </Typography>
                <div className={classes.divider} />
                <Typography className={classes.btn}>
                  <SimpleDialogSpan title="Privacy Policy">
                    <Privacy />
                  </SimpleDialogSpan>
                </Typography>
              </div>
            </div>

            <div className={classes.socialLogoContainer}>
              <a href="https://www.facebook.com/LitPathNonProfit" target="_blank" rel="noreferrer">
                <FacebookIcon className={classes.socialLogo} />
              </a>
              <a href="https://www.linkedin.com/company/litpath/" target="_blank" rel="noreferrer">
                <LinkedInIcon className={classes.socialLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
