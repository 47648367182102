import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Divider,
  Grid,
  Container,
  Link,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { validateCoupon } from '../../utils/client-api'
import { useEffect } from 'react'
import SimpleDialogSpan from '../SimpleDialogSpan'
import TermsTherapist from '../../views/TermsTherapist'
import Privacy from '../../views/Privacy'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: 'blue',
  },
  coupon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  couponInput: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 200,
  },
}))

export default function ReviewDetailsForm({
  value,
  handleCouponChange,
  coupon,
  handleInputChange,
  plans,
  updateTotal,
  total,
}) {
  const classes = useStyles()
  const [code, setCode] = React.useState('')
  const [couponMsg, setCouponMsg] = React.useState('')
  const [discount, setDiscount] = React.useState(0)

  useEffect(() => {
    let disc = 0
    if (coupon && value.plan) {
      // calculate discount and message
      if (coupon.type === 'percentOff') {
        disc = ((value.plan.payment * coupon.value) / 100).toFixed(2)
      } else if (coupon.type === 'finalPrice') {
        disc = (value.plan.payment - coupon.value).toFixed(2)
      } else if (coupon.type === 'totalOff') {
        disc = coupon.value
      } else {
      }
      setCouponMsg(coupon.description)
    }
    setDiscount(disc)
    updateTotal(value.plan.payment - disc > 0 ? (value.plan.payment - disc).toFixed(2) : '0.00')
  }, [coupon, value.plan])

  const handleAddCoupon = () => {
    setCouponMsg('')
    // TODO api call to verify coupon
    validateCoupon(code)
      .then(res => {
        if (res.coupon) {
          handleCouponChange(res.coupon)
        } else {
          setCouponMsg('Invalid')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleRemoveCoupon = () => {
    handleCouponChange()
    setCouponMsg('')
    setCode('')
  }

  return (
    <div>
      <Container maxWidth="sm">
        <Typography variant="h6">Account Details</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Display name:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{`${value.firstName} ${value.middleInitial} ${value.lastName}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Account Email:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.email}</Typography>
          </Grid>
        </Grid>
        {/*<Divider className={classes.divider} />*/}
        {/*<Typography variant="h6">Billing Details</Typography>*/}
        {/*<Grid container spacing={2}>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Name on Card:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="left">{`${value.cardFName} ${value.cardLName}`}</Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Card Number:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="left">{value.cardNumber}</Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Card Expiration:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="left">{value.expDate}</Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Security Code:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="left">{value.cvc}</Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container spacing={2}>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Billing Address:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="left">*/}
        {/*      {value.address1}*/}
        {/*      <br />*/}
        {/*      {value.address2 && `${value.address2}<br/>`}*/}
        {/*      {`${value.city}, ${value.state} ${value.zip}`}*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Divider className={classes.divider} />*/}
        {/*<Typography variant="h6">Billing Summary</Typography>*/}
        {/*<Grid container spacing={2} alignItems="center">*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Therapy Reviews Service Fee:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <FormControl className={classes.formControl}>*/}
        {/*      <InputLabel id="plan">Choose a Plan</InputLabel>*/}
        {/*      <Select*/}
        {/*        labelId="planId-select"*/}
        {/*        id="planId-select"*/}
        {/*        name="plan"*/}
        {/*        value={value.plan || ''}*/}
        {/*        onChange={handleInputChange}*/}
        {/*      >*/}
        {/*        {plans.map(plan => (*/}
        {/*          <MenuItem value={plan} key={plan.id}>*/}
        {/*            {plan.type.toUpperCase()} - ${plan.payment}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}
        {/*    /!* <Typography align="left">${originalPrice} per month</Typography> *!/*/}
        {/*  </Grid>*/}
        {/*  {discount ? (*/}
        {/*    <>*/}
        {/*      <Grid item sm={6}>*/}
        {/*        <Typography align="right">Coupon Discount:</Typography>*/}
        {/*      </Grid>*/}
        {/*      <Grid item sm={6}>*/}
        {/*        <Typography align="left">- {discount}</Typography>*/}
        {/*      </Grid>*/}
        {/*    </>*/}
        {/*  ) : null}*/}
        {/*  <Grid item sm={6}>*/}
        {/*    <Typography align="right">Total Due at Signup:</Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item sm={6}>*/}
        {/*    {value.plan ? <Typography align="left">${total}</Typography> : <Typography>Select a plan</Typography>}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container spacing={2} justify="center">*/}
        {/*  <Grid item className={classes.coupon}>*/}
        {/*    {coupon ? (*/}
        {/*      <>*/}
        {/*        <Typography color="textSecondary" variant="subtitle2">*/}
        {/*          {coupon.code} - {coupon.description}*/}
        {/*        </Typography>*/}
        {/*        <Button*/}
        {/*          className={classes.button}*/}
        {/*          variant="contained"*/}
        {/*          color="secondary"*/}
        {/*          size="small"*/}
        {/*          onClick={handleRemoveCoupon}*/}
        {/*        >*/}
        {/*          Remove*/}
        {/*        </Button>*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <>*/}
        {/*        <Typography>Have a promo code?</Typography>*/}
        {/*        <TextField*/}
        {/*          className={classes.couponInput}*/}
        {/*          id="coupon"*/}
        {/*          name="coupon"*/}
        {/*          label="Promo Code"*/}
        {/*          value={code}*/}
        {/*          onChange={e => setCode(e.target.value.toUpperCase())}*/}
        {/*        />*/}
        {/*        <Button*/}
        {/*          className={classes.button}*/}
        {/*          variant="contained"*/}
        {/*          color="secondary"*/}
        {/*          size="small"*/}
        {/*          onClick={handleAddCoupon}*/}
        {/*        >*/}
        {/*          Add*/}
        {/*        </Button>*/}
        {/*        {couponMsg && (*/}
        {/*          <Typography variant="subtitle1" color="error">*/}
        {/*            {couponMsg}*/}
        {/*          </Typography>*/}
        {/*        )}*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        <br />
        <Typography variant="caption">
          By clicking &quot;Register Account&quot;, you are agree to Lit Path&quot;s{' '}
          <Link className={classes.link}>
            <SimpleDialogSpan title="Terms of Service">
              <TermsTherapist />
            </SimpleDialogSpan>
          </Link>{' '}
          and acknowledge Lit Path&#39;s{' '}
          <Link className={classes.link}>
            <SimpleDialogSpan title="Privacy Policy">
              <Privacy />
            </SimpleDialogSpan>
          </Link>
          .
        </Typography>
      </Container>
    </div>
  )
}
