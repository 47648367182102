import React, { useRef } from 'react'
import { useSnackbar } from 'notistack'
import { Typography, TextField, Button, Grid } from '@material-ui/core'
import { verifyCode } from '../utils/auth-api'

export default function EnterCode({ data, onResolve, onReject }) {
  const { enqueueSnackbar } = useSnackbar()
  const inputRef = useRef()
  const handleSubmit = () => {
    verifyCode(data.id, inputRef.current.value)
      .then(res => {
        enqueueSnackbar('Code verified', { variant: 'success' })
        onResolve()
      })
      .catch(error => {
        enqueueSnackbar('Invalid Code', { variant: 'error' })
        onReject()
      })
  }
  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Typography variant="h6">Enter Verification Code</Typography>
      </Grid>
      <Grid item>
        <TextField label="6-digit code" inputRef={inputRef} />
      </Grid>
      <Grid item>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}
