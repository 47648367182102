import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { useSnackbar } from 'notistack'
// Material UI components
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Container } from '@material-ui/core/'
// Core components
import LoginForm from '../components/LoginForm'
import MainLayout from '../layouts/MainLayout'

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
}))

export default function SignIn(props) {
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(()=>{
    window.scroll(0,0)
  }, [])

  useEffect(() => {
    const q = queryString.parse(location.search)
    if (q.error) {
      enqueueSnackbar(`Error: ${q.error}`, { variant: 'error', persist: true })
    }
  }, [location.search])

  return (
    <MainLayout>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <LoginForm title="Sign in" successRedirect="/me" display={props.display} />
        </Paper>
      </Container>
    </MainLayout>
  )
}
