import React, { useState } from 'react'
import { Container, Avatar, Button, TextField, Link, Grid, Box, FormHelperText, Typography } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { useSnackbar } from 'notistack'
import KeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined'
import { makeStyles } from '@material-ui/core/styles'
import Copyright from '../components/Copyright'
import { forgotPassword } from '../utils/auth-api'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  main: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 500,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helperText: {
    marginLeft: 12,
  },
  successMessage: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonGroup: {
    margin: theme.spacing(3, 2),
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export default function ForgotPasswordForm({ title }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [status, setStatus] = useState({ success: false, message: '' })

  function emailIsValid() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  function handleSubmit(event) {
    event.preventDefault()
    setStatus({ success: false, message: '' })
    if (emailIsValid()) {
      setEmailError('')
      const data = { email: email.trim() }
      forgotPassword(data)
        .then((response) => {
          setStatus({ success: true, message: response.message })
        })
        .catch((error) => {
          setStatus({ success: true, message: error.response.data.error })
        })
    } else {
      setEmailError('Enter a valid email address!')
    }
  }
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <KeyOutlinedIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

        {status.success ? (
          <Box className={classes.main}>
            <br />
            <Typography variant="h6">You entered:</Typography>
            <Typography variant="h6">{email}</Typography>
            <br />
            <Typography>
              {`If we have your email address in the system, you'll receive a message with a link to reset your password. If you don't see an email from us, check spam or junk mail.`}
            </Typography>
            <div className={classes.buttonGroup}>
              <Button
                component={RouterLink}
                to="/login/therapist"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Done
              </Button>
              <Button
                onClick={() => setStatus({ success: false, message: '' })}
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Retry
              </Button>
            </div>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">Enter your email address to reset your password.</Typography>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                autoComplete="email"
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => setEmail(e.target.value.trim())}
                error={emailError.length > 0}
                helperText={emailError}
              />
              {!status.success && status.message.length > 0 && (
                <FormHelperText className={classes.helperText} error>
                  {status.message}
                </FormHelperText>
              )}
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Continue
              </Button>
            </form>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Sign In
                </Link>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={8}>
          <Copyright />
        </Box>
      </div>
    </Container>
  )
}
