import { createMuiTheme } from '@material-ui/core/styles'
// Color Tool: https://material.io/resources/color/#!/?view.left=0&view.right=1&secondary.color=FFCA28
// Materi UI color tool (light value of secondary): https://material-ui.com/customization/color/#color-tool

const theme = createMuiTheme({
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#000b37',
      light: '#2d3261',
      dark: '#000014',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fdca44',
      light: '#fdd469',
      dark: '#c69a00',
      contrastText: '#000',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#f00',
    },
    background: {
      default: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Josefin Sans", "Roboto", "Helvetica", "Arial", sans-serif, "Inter"',
  },
})

export default theme
