import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import HorizontalSlider from './HorizontalSlider'
import BethesdaImage from '../../assets/img/Bethesda.jpg'
import BanyanImage from '../../assets/img/Banyan.jpg'
import HopeRayImage from '../../assets/img/Hope.jpg'
import PCSImage from '../../assets/img/PCS-Color-Logo.jpg'
import BRImage from '../../assets/img/BRLogo.png'
import BAIImage from '../../assets/img/BAILogo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  orgImage: {
    width: '200px',
    height: '100px',
    objectFit: 'scale-down',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '75px',
    },
  },
}))

const organizationImages = [
  { name: 'Bethesda Workshops', img: BethesdaImage, link: 'https://www.bethesdaworkshops.org/' },
  { name: 'Hope Ray Therapy', img: HopeRayImage, link: 'https://www.hoperaytherapy.com/' },
  { name: 'Banyan Therapy', img: BanyanImage, link: 'https://www.banyantherapy.com/intensives/' },
  { name: 'Psychological Counseling Services', img: PCSImage, link: 'https://pcsintensive.com/' },
  { name: 'Boulder Recovery', img: BRImage, link: 'https://beginagaininstitute.com/programs/14-day-christian-intensive/' },
  { name: 'Begin Again Institute', img: BAIImage, link: 'https://beginagaininstitute.com/' },
]

const OrganizationSlider = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <HorizontalSlider>
        {organizationImages.concat(organizationImages).map((org) => (
          <a href={org.link} target="_blank">
            <img src={org.img} alt={org.name + ' logo'} className={classes.orgImage} />
          </a>
        ))}
      </HorizontalSlider>
    </div>
  )
}

export default OrganizationSlider
