import { call } from './client-api'

function getFlaggedReviews() {
  return call(`/api/v1/admin/reviews?flag=true`)
}

/**
 * Delete reviews
 * @param {array} reviewIds array containing review id's to be 'deleted' from database
 */
function deleteReviews(reviewIds) {
  return call(`api/v1/admin/reviews`, { method: 'DELETE', body: { idArray: reviewIds } })
}

/**
 * Approve reviews
 * @param {array} reviewIds array containing review id's to be 'deleted' from database
 */
function approveReviews(reviewIds) {
  return call(`api/v1/admin/reviews`, { method: 'PUT', body: { idArray: reviewIds } })
}

function getSystemLogs() {
  return call(`/api/v1/admin/system/log`)
}

function getUsers() {
  return call(`/api/v1/admin/users`)
}
function getUserById(id) {
  return call(`/api/v1/admin/users/${id}`)
}
function getTherapists() {
  return call(`/api/v1/admin/therapists`)
}
function getTherapists2(pageNum,pageSize,query) {
  return call(`/api/v1/admin/therapists2/?page=${pageNum}&limit=${pageSize}&search=${query}`)
}
function getClaimedTherapists() {
  return call(`/api/v1/admin/claimed-therapists`)
}
function getTherapistByPersonId(id) {
  return call(`/api/v1/admin/therapists/${id}`)
}
export { getFlaggedReviews, deleteReviews, approveReviews, getSystemLogs, getUsers, getTherapistByPersonId,getTherapists,getUserById,getClaimedTherapists,getTherapists2 }
