import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { Link } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  joinCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    borderRadius: '6px',
    background: 'FFF',
    cursor: 'pointer',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    transitionDuration: '0.2s',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '400px',
      gap: '16px',
    },
  },
  joinCardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    padding: '24px',
    paddingTop: '0px',
  },
  joinCardTitle: {
    color: '#000',
    fontSize: '24px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.18px',
  },
  joinCardText: {
    color: '#535457',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.08px',
    fontFamily: 'Inter',
  },
  joinCardImage: {
    width: '300px',
    height: '235px',
    objectFit: 'cover',
    borderRadius: '6px 6px 0px 0px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
    },
  },
  link: {
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const JoinCard = ({ image, title, text, path, alt }) => {
  const classes = useStyles()
  return (
    <div className={classes.joinCardContainer}>
      <Link component={RouterLink} to={path} className={classes.link} style={{ textDecoration: 'none' }}>
        <img src={image} className={classes.joinCardImage} alt={alt} />

        <div className={classes.joinCardTextContainer}>
          <Typography className={classes.joinCardTitle}>{title}</Typography>
          <Typography className={classes.joinCardText}>{text}</Typography>
        </div>
      </Link>
    </div>
  )
}

export default JoinCard
