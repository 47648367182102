/* eslint-disable complexity */
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Container, IconButton, ListItem, ListItemText } from '@material-ui/core'
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons'
import { updateProfile } from '../../utils/therapist-api'
import { mapToTitlesArray, getPhoneNumberByType, getLink } from '../../utils/helper'
import {
  PersonalAddressForm,
  CheckGender,
  LanguageWithOtherOption,
  PersonalContactForm,
  DigitalContactsForm,
} from './UpdatePersonalForms'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  red: {
    color: 'red',
  },
  addIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.dark,
    height: '25px',
    borderRadius: '50px',
  },
}))

const convertUserToValues = (user) => {
  return {
    firstName: user.Person.firstName || '',
    middleInitial: user.Person.middleInitial || '',
    lastName: user.Person.lastName || '',
    address1: user.HomeLocation.streetAddress1 || '',
    address2: user.HomeLocation.streetAddress2 || '',
    city: user.HomeLocation.city || '',
    state: user.HomeLocation.province || '',
    zip: user.HomeLocation.postalCode || '',
    country: user.HomeLocation.country || '',
    gender: user.Person.gender || '',
    language: mapToTitlesArray(user.Person.Languages) || [],
    primaryPhone: getPhoneNumberByType(user.Person.Phones, 'Primary Phone') || '',
    secondaryPhone: getPhoneNumberByType(user.Person.Phones, 'Secondary Phone') || '',
    email: user.Person.PersonPrimaryContact?.Email?.address || '',
    website: getLink(user.Person.Links, 'website') || '',
    linkedin: getLink(user.Person.Links, 'linkedin') || '',
    facebook: getLink(user.Person.Links, 'facebook') || '',
  }
}
export default function PersonalContactInfo({ user, reloadUser,nextTab }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [value, setValue] = useState(convertUserToValues(user))
  const [langHidden, setLangHidden] = useState(true)
  const [status, setStatus] = useState('idle')

  useEffect(() => {
    let canceled = false

    if (status === 'save') {
      // TODO: validate inputs
      updateProfile(user.id, value, 'personalContact')
        .then(() => {
          enqueueSnackbar('Update successful', { variant: 'success' })
          if (canceled) return
          setStatus('success')
          reloadUser()
          nextTab(1)
          //scroll to top
          window.scrollTo(0, 0)
        })
        .catch(() => {
          enqueueSnackbar('Error updating profile', { variant: 'error' })
          if (canceled) return
          setStatus('failure')
        })
    }

    return () => (canceled = true)
  }, [enqueueSnackbar, reloadUser, status, user.id, value])

  const handleClose = () => {
    setLangHidden(true)
  }
  const handleInputChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }

  const getContent = () => {
    if (!langHidden) {
      return <LanguageWithOtherOption value={value.language} handleInputChange={handleInputChange} />
    }
    return null
  }
  function displayLang() {
    if (langHidden) {
      setLangHidden(false)
    } else {
      handleClose()
    }
  }

  const handleDeleteItem = (key, item) => {
    const index = value[key].indexOf(item)
    const newValue = value[key].filter((_, i) => i !== index)
    setValue({ ...value, [key]: newValue })
  }
  return (
    <>
      <Typography variant="h3" align="left" gutterBottom>
        Personal Contact Information
      </Typography>
      <Typography variant="subtitle2" align="left" gutterBottom>
        This information will not be made public and is for internal purposes only unless noted otherwise with an
        asterisk (*).
      </Typography>
      <Container maxWidth="sm" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PersonalAddressForm value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} />

          <Grid item xs={12} sm={6}>
            <PersonalContactForm value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DigitalContactsForm value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <CheckGender value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" align="left" gutterBottom>
              Languages *
              <Button id="languagesBtn" onClick={displayLang}>
                <AddIcon className={classes.addIcon} />
              </Button>
            </Typography>

            {getContent()}

            {value.language.map((language) => (
              <ListItem key={language} value={language}>
                <ListItemText primary={language} />
                <IconButton size="small" className={classes.red} onClick={() => handleDeleteItem('language', language)}>
                  <ClearIcon />
                </IconButton>
              </ListItem>
            ))}
          </Grid>
        </Grid>
      </Container>

      <div className={classes.btnContainer}>
        <Button
          disabled={status === 'save'}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => setStatus('save')}
        >
          {status === 'save' ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}
