import React, { useRef, useEffect,useCallback } from 'react'
import { Link as RouterLink } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { Typography,Link, Container, Paper } from '@material-ui/core'
import RegistrationForm from '../components/RegistrationForm'
import MainLayout from '../layouts/MainLayout'
import { getPublicLicensedTherapist } from '../utils/client-api'

import { useMachine } from '@xstate/react'
import claimProfileMachine from '../utils/claimMachine'
import ClaimProfileInfo from '../components/ClaimProfileInfo'
import EnterCode from '../components/EnterCode'
import VerifyProfile from '../components/VerifyProfile'

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(4),
  },
  dark: {
    color: "darkblue",
  }
}))

export default function InviteToRegister({ therapistId }) {
  const classes = useStyles()
  const [data, setData] = React.useState()
  const [status, setStatus] = React.useState('loading')
  const [current, send] = useMachine(claimProfileMachine)
  const mountedRef = useRef(true)

  const fetchData = useCallback(async () => {
    try {
      const res = await getPublicLicensedTherapist(therapistId)
      if (res) {
        try {
          if (!mountedRef.current) return null
          setData(res)
          if (!res.profileClaimed)
          {
            setStatus('info')
            send('CONTINUE')
            //send('SUBMIT')
          }
          else
          {
            setStatus('alreadyclaimed')
          }
        } catch (error) {
          console.log(error)
          if (!mountedRef.current) return null
        }
      }
    } catch (err) {
      setStatus('error')
    }
  }, [mountedRef,setStatus,setData,send,therapistId]) 

  useEffect(() => {
    if (therapistId) {
      fetchData()
    }
  }, [therapistId, fetchData])

  useEffect(() => {
    return () => {
      mountedRef.current = false;   // clean up function
    };
  }, [mountedRef])

  if (status === 'loading') {
    return null
  }
  if (status === 'error' || !data) {
    return (
      <>
        <Typography variant="h5">
          Uh oh! There were problems retrieving the therapist.
          <br />
          Please refresh the page or contact support.
        </Typography>
      </>
    )
  }
  return (
    <MainLayout>
      <div className={classes.header}>
        <Typography variant="h4" component="h1">
        Register your account
        </Typography>
        <Typography variant="h6" component="h2">
        Register your account to participate in the Lit Path Scholarship Program
        </Typography>
      </div>
      <Container maxWidth="md">
        <Paper className={classes.content}>
        {/* {current.value === 'info' && (
            <div>
              <ClaimProfileInfo onContinue={handleContinue} name={data.Person.firstName} />
            </div>
          )} */}
          {current.value === 'verify' && (
            <div>
              <VerifyProfile data={data} onSubmit={() => send('SUBMIT')} onGoBack={() => send('GOBACK')} />
            </div>
          )}
          {current.value === 'code' && (
            <div>
              <EnterCode data={data} onResolve={() => send('RESOLVE')} onReject={() => send('REJECT')} />
            </div>
          )}
          {current.value === 'claimed' && (
            <div>
              <RegistrationForm data={data} />
            </div>
          )}
          {(status === 'alreadyclaimed' || current.value === 'failure') && (
            <div>
              This profile has already been claimed or there have been too many attempts using invalid code. Please <Link className={classes.dark} component={RouterLink} to="/contact">contact us</Link> or e-mail <a href="mailto:info@litpath.org?subject=Profile already claimed message">info@litpath.org</a> for assistance.
            </div>
          )}
        </Paper>
      </Container>
    </MainLayout>
  )
}
