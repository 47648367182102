import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { Typography, Link, Button } from '@material-ui/core'
//import ScholarshipForm from '../components/ScholarshipForm/ScholarshipJotForm'
import clsx from 'clsx'
// import JotformEmbed from 'react-jotform-embed'
import FaqBox from '../components/FaqBox'
import Slideshow from '../components/ScholarshipPage/Slideshow'
import RequirementBox from '../components/ScholarshipPage/RequirementBox'
import ProcessTimeline from '../components/ScholarshipPage/ProcessTimeline'
import cardImage from '../assets/img/vladislav-babienko-703701-unsplash.jpg'
import helpUsImage from '../assets/img/youssef-naddam-iJ2IG8ckCpA-unsplash.jpg'
import Headers from '../components/Headers'

const primaryColor = '#1B3666'
const width = '1110px' //Restrict max-width to maintain reasonable gap

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      background: 'white',
      marginTop: '64px',
      marginBottom: '64px',
      gap: '64px',
    },
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0px 16px',
  },
  headerText: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
    color: primaryColor,
    textAlign: 'center',
    marginBottom: '16px',
  },
  gridContainer: {
    display: 'flex',
    maxWidth: '850px',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '32px',
    justifyContent: 'center',
    margin: 'auto',
  },
  cardContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
  },
  cardText: {
    fontFamily: 'Inter',
    color: '#000000',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.005em',
    textAlign: 'left',
  },
  cardImage: {
    width: '100%',
    height: '320px',
    maxWidth: '420px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  button: {
    borderRadius: '100px',
    background: primaryColor,
    color: 'white',
    padding: '16px 32px',
    height: '59px',
    width: '275px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#132648',
    },
  },
}))

/**
 * @type {import('../components/Faq').FaqItem[]}
 */
const faqItems = [
  {
    number: '01',
    question: 'Can anyone apply?',
    answer: 'Yes, as long as they meet the minimum requirements listed above.',
  },
  {
    number: '02',
    question: 'How often are applications reviewed?',
    answer: 'Normally within 48 hours of submission, however it can take up to a week.',
  },
  {
    number: '03',
    question: 'Does the scholarship cover any compulsive behavior(s) therapy support?',
    answer:
      'No, at this time our scholarships only cover up to 16 one-hour therapy sessions with any therapist listed on our website. ||br|| (Lit Path would like to expand our funding to cover more types of therapy like workshops, intensive and inpatient therapy. If you are interested in helping us to offer these services, please visit our ||[Sponsorships page](/donate))||.',
  },
  {
    number: '04',
    question: 'Does the scholarship cover spouses or family members therapy sessions?',
    answer:
      'No, at this time our scholarships only cover therapy sessions for the addict. ||br|| (Lit Path would like to expand our funding to cover spouses and family members. If you are interested in helping us to offer these services, please visit our ||[Sponsorships page](/donate))||.',
  },
  {
    number: '05',
    question: 'Do you have to have multiple compulsive behavior(s) (example: drug & pornography)?',
    answer:
      'No, anyone suffering from any compulsive behavior(s) is welcome to apply as long as they meet the minimum requirements listed above',
  },
  {
    number: '06',
    question: 'Can I use the scholarship with any therapist?',
    answer:
      'No, the scholarship may only be used with a therapist participating in our program. These therapists are found on our ||[“participating therapists”](/find)|| page.  However, your therapist is welcome to apply.  There is no cost to them for participating in the scholarship program.  They can apply ||[here](/scholarships)||.',
  },
  {
    number: '07',
    question: 'What happens at the end of the scholarship?',
    answer:
      'You may apply for another scholarship. Approval of another scholarship is dependent on the scholarship funds available, number of applicants waiting for a scholarship, and/or your therapists’ recommendation.',
  },
  {
    number: '08',
    question: 'If I am not approved for a scholarship, can I apply again?',
    answer:
      'Yes, you may apply once you meet all of the requirements and/or 6 months after the last application submission.',
  },
  {
    number: '09',
    question: 'How does payment to the therapist work?',
    answer:
      'If granted a scholarship, Lit Path will pay up to $65 a session directly to the therapist’s practice after the completion of each session. The scholarship recipient is responsible for the remaining cost for each session and must make payment directly to the therapist’s practice for their remaining balance.',
  },
]

export default function Scholarships() {
  const classes = useStyles()

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Headers
        title="Scholarship Application"
        type="website"
        description="Need assistance to help pay for the cost of recovery therapy? Apply to the Lit Path addiction recovery scholarship program. Available for sex addicts, alcoholics, tech addicts, gambling addicts, pornography addicts, work addicts, shopping addicts, substance addicts, those with food disorders, and other addictions."
      />

      <div className={classes.mainContainer}>
        <div className={classes.section} style={{ padding: '0px' }}>
          <Slideshow />
        </div>

        <div className={classes.section}>
          <div className={classes.cardContainer}>
            <Typography className={classes.cardText} align="left">
              At Lit Path, we are dedicated to supporting your recovery journey. We understand the challenges you face
              and want to help you overcome them. By offering partial scholarships for compulsive behavior(s) recovery
              therapy, we aim to make treatment more accessible and affordable. <br /> <br /> Our scholarships cover a
              portion of the session costs, providing financial support while allowing you to choose a therapist from
              our program who best suits your needs. We believe in your commitment to recovery and are here to assist
              you every step of the way.
            </Typography>
            <img
              align="center"
              className={classes.cardImage}
              src={cardImage}
              alt="a person standing on a dirt path surrounded by trees"
            />
            <Link component={RouterLink} to="/scholarship-form" style={{ margin: 'auto' }}>
              <Button className={classes.button}>Apply for a Scholarship </Button>
            </Link>
          </div>
        </div>

        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography className={classes.headerText} component="h2">
            Requirements for Applying
          </Typography>
          <div className={classes.gridContainer}>
            <RequirementBox
              header="Demonstrated Commitment"
              description="Recently completed intensive or inpatient program OR a recommendation letter from a therapist."
              italics="Note: Documentation or letter will be requested."
            />
            <RequirementBox
              header="Capability"
              description="Financial ability to cover the remaining cost of treatment. Reliable transportation to attend sessions."
            />
            <RequirementBox
              header="Demonstrated Need"
              description="Thin household net margin (Income - Expenses)."
              italics="Note: Documentation will be requested via 2 months bank statements."
            />
            <RequirementBox
              header="Accountability"
              description="No more than 3 missed sessions within the 22-week period."
            />
          </div>
        </div>

        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography component="h2" className={classes.headerText}>
            Scholarship Process
          </Typography>
          <ProcessTimeline style={{ margin: 'auto' }} />
        </div>

        <div className={classes.section}>
          <div className={classes.cardContainer}>
            <img
              align="center"
              className={classes.cardImage}
              src={helpUsImage}
              alt="a hand reaching out to another hand"
            />
            <Typography className={classes.cardText} align="left">
              Help us spread the word about the amazing work of Lit Path by sharing your experience with others.
              Together, we can inspire hope and support more individuals on their recovery journeys.
            </Typography>
            <Link component={RouterLink} to="/scholarship-form" style={{ margin: 'auto' }}>
              <Button className={classes.button}>Apply for a Scholarship </Button>
            </Link>
          </div>
        </div>

        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography component="h2" className={classes.headerText}>
            Frequently Asked Questions
          </Typography>
          <div style={{ width: '100%', maxWidth: width }}>
            {faqItems.map((faq, index) => (
              <FaqBox key={index} {...faq} />
            ))}
            <div style={{ width: '100%', height: '2px', color: 'white', marginTop: '-2px', zIndex: '2' }} />
          </div>
        </div>

        <div className={classes.section} style={{ flexDirection: 'column' }}>
          <Typography component="h2" className={classes.headerText}>
            Still Have Questions?
          </Typography>
          <Link key="/contact" component={RouterLink} to="/contact" style={{ margin: 'auto' }}>
            <Button className={classes.button} style={{ width: '165px' }}>
              Contact Us
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}
