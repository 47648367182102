import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tab, Tabs, Typography, Box } from '@material-ui/core'
import { useUser } from '../context/user-context'
import PersonalContactInfo from './UpdateForms/PersonalContactInfo'
import BusinessContactInfo from './UpdateForms/BusinessContactInfo'
import EducationInfo from './UpdateForms/EducationInfo'
import AboutInfo from './UpdateForms/AboutInfo'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '200px',
    paddingTop: theme.spacing(2),
  },
  tabWrapper: {
    alignItems: 'flex-end',
  },
  tab: {
    marginBottom: theme.spacing(3),
    textAlign: 'right',
  },
  check: {
    paddingTop: theme.spacing(3),
  },
  pages: {
    width: '100vw',
    padding: theme.spacing(2),
  },
  forms: {
    padding: theme.spacing(2),
  },
}))

export default function VerticalTabs({ adminData, onRefetch}) {
  const classes = useStyles()
  const { user, reloadUser } = useUser()
  const [tabValue, setTabValue] = useState(0)
  const data = adminData || user
  const handleReload = adminData ? onRefetch : reloadUser
  const handleNextTab = (nextTabValue) => {
    setTabValue(nextTabValue||0)
  } 

  const tabList = [
    {
      name: 'Personal Contact Information',
      component: <PersonalContactInfo key={data.id} user={data} reloadUser={handleReload} nextTab={handleNextTab}/>,
    },
    {
      name: 'Business Contact Information',
      component: <BusinessContactInfo key={data.id} user={data} reloadUser={handleReload} nextTab={handleNextTab} />,
    },
    {
      name: 'Education and Qualifications',
      component: <EducationInfo key={data.id} user={data} reloadUser={handleReload} nextTab={handleNextTab} />,
    },
    {
      name: 'About Me',
      component: <AboutInfo key={data.id} user={data} reloadUser={handleReload} nextTab={handleNextTab} />,
    },
  ]

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className={classes.tabs}
      >
        {tabList.map((tab, index) => (
          <Tab
            key={index}
            className={classes.tab}
            classes={{ wrapper: classes.tabWrapper }}
            label={tab.name}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      {tabList.map((tab, index) => (
        <TabPanel key={index} className={classes.pages} value={tabValue} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  )
}
