import { Paper, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react'

const useStyles = makeStyles(theme => ({
    paper: {
        width: "175px"
    },
    specialtyInput: {
        width: "100%"
    },
}))

const CustomPaper = (props) => {
  return <Paper elevation={8} style={{width: "100%", marginTop: 10}} {...props} />;
};

export default function SpecialtyAutocomplete({specialties, specialtyInput, setSpecialtyInput, specialtySelected, setSpecialtySelected}) {
    const classes = useStyles();
    return (
        <Autocomplete
            id="location-search"
            options={specialties}
            noOptionsText="No results"
            title='Condition'
            className={classes.specialtyInput}
            PaperComponent={CustomPaper}
            getOptionLabel={(option) => {
                return option;
            }}
            filterOptions={(options) => {
                return options.filter(
                    (option) => {
                        return option.toLowerCase().indexOf(specialtyInput.toLowerCase().trim()) == 0
                    }
                ).slice(0, 10);
            }}
            onChange={(event, newValue) => {
                if (newValue == null) {
                    setSpecialtySelected("");
                    return;
                }
                setSpecialtySelected(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setSpecialtyInput(newInputValue);
            }}
            renderInput={(params) => {
                const inputProps = params.InputProps;
                inputProps.disableUnderline = true;
                return <TextField {...params} 
                                    disableUnderline={true}
                                    InputProps={inputProps}
                                    placeholder='Condition'
                        />
            }}
        />
    )
}
