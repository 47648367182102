import React, { useEffect } from 'react'
import { getFilters } from '../utils/client-api'
import DEFAULT_FILTERS from '../variables/defaultFilters'

const FilterContext = React.createContext()

function FilterProvider(props) {
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS)

  useEffect(() => {
    let canceled = false

    async function fetchFilters() {
      try {
        const data = await getFilters()
        if (canceled) return
        setFilters(data)
      } catch (error) {
        if (canceled) return
        // use the backup default filters if there is an error retrieving
        setFilters(DEFAULT_FILTERS)
      }
    }
    fetchFilters()
    return () => {
      canceled = true
    }
  }, [])

  return <FilterContext.Provider value={filters} {...props} />
}

function useFilters() {
  const context = React.useContext(FilterContext)
  if (context === undefined) {
    throw new Error('useFilter must be within a FilterProvider')
  }
  return context
}

export { FilterProvider, useFilters }
