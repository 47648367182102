/* eslint-disable no-negated-condition */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, FormControl, IconButton, Container, ListItem, ListItemText } from '@material-ui/core'
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons'
import { updateProfile } from '../../utils/therapist-api'
import { mapToTitlesArray, getPrimaryEducation, getAdditionalEducation } from '../../utils/helper'
import {
  PrimaryEducation,
  AdditionalEducation,
  AddictionSpecialtyWithOtherOption,
  OtherSpecialtyWithOtherOption,
  TherapyTypesWithOtherOption,
  ModalitiesWithOtherOption,
} from './UpdateEducationForms'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  contentType: {
    marginBottom: theme.spacing(3),
  },
  addIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.dark,
    height: '25px',
    borderRadius: '50px',
  },
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(3),
  },
  red: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

function mapAddSpecToTitlesArray(arr) {
  if (!arr || arr.length === 0) return null
  return arr.filter(obj => obj.category.includes("Addiction")).map(obj => obj.title)
}
function mapOthSpecToTitlesArray(arr) {
  if (!arr || arr.length === 0) return null
  return arr.filter(obj => obj.category.includes("Other")).map(obj => obj.title)
}

const convertUserToValues = (user) => {
  const additionalEdu = getAdditionalEducation(user.Person.Education) || []
  return {
    primaryEducation: getPrimaryEducation(user.Person.Education) || {
      classification: 'primary',
    },
    additionalEdu1: additionalEdu[0] || { classification: 'additional' },
    additionalEdu2: additionalEdu[1] || { classification: 'additional' },
    addspecialty: mapAddSpecToTitlesArray(user.Person.Specialties) || [],
    othspecialty: mapOthSpecToTitlesArray(user.Person.Specialties) || [],
    therapyType: mapToTitlesArray(user.Person.TherapyTypes) || [],
    modality: mapToTitlesArray(user.Person.Modalities) || [],
  }
}

export default function EducationInfo({ user, reloadUser,nextTab }) {  
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [value, setValue] = useState(convertUserToValues(user))
  const [status, setStatus] = useState('idle')
  const [addspecHidden, setAddSpecHidden] = useState(true)
  const [othspecHidden, setOthSpecHidden] = useState(true)
  const [modHidden, setModHidden] = useState(true)
  const [typeHidden, setTypeHidden] = useState(true)
  const [addEducation, setAddEducation] = useState(value.additionalEdu2.id ? true:false)

  function refreshEducation() {
    return new Promise((resolve) => {          
      const newadditionalEdu = getAdditionalEducation(user.Person.Education) || []
      setValue({
        ...value,
        primaryEducation: getPrimaryEducation(user.Person.Education) || { classification: 'primary' }
        })
      setValue({
        ...value,
        additionalEdu1: newadditionalEdu[0] || { classification: 'additional' }
      })
      setValue({
        ...value,
        additionalEdu2: newadditionalEdu[1] || { classification: 'additional' }
      })
      resolve();
    });
  }

  useEffect(() => {
    let canceled = false
    if (status === 'reloading'){
      reloadUser()
      .then(() => {
        refreshEducation()
        setStatus('success')
        nextTab(3)
        window.scrollTo(0, 0)
      })
      .catch(() => {
        enqueueSnackbar('Error refreshing profile', { variant: 'error' })
        if (canceled) return
        setStatus('failure')
      })
    }
    if (status === 'save') {
      // TODO: validate inputs
      const education = []
      if (value.primaryEducation.name) {
        education.push(value.primaryEducation)
      }
      if (value.additionalEdu1.name) {
        education.push(value.additionalEdu1)
      }
      if (value.additionalEdu2.name) {
        education.push(value.additionalEdu2)
      }
      const speciality = [...value.addspecialty, ...value.othspecialty]

      const body = {
        education,
        specialties: speciality,
        therapyTypes: value.therapyType,
        modalities: value.modality,
      }
      updateProfile(user.id, body, 'educationAndQualifications')
        .then(() => {
          enqueueSnackbar('Update successful', { variant: 'success' })
          if (canceled) return
          setStatus('reloading')
        })
        .catch(() => {
          enqueueSnackbar('Error updating profile', { variant: 'error' })
          if (canceled) return
          setStatus('failure')
        })
    }
    return () => (canceled = true)
  }, [enqueueSnackbar, reloadUser, status, user.id, value])

  const handleInputChange = (event, type) => {
    if (type === 'primary') {
      setValue({
        ...value,
        primaryEducation: Object.assign(value.primaryEducation, {
          [event.target.name]: event.target.value,
        }),
      })
    } else if (type === 'additional1') {
      setValue({
        ...value,
        additionalEdu1: Object.assign(value.additionalEdu1, {
          [event.target.name]: event.target.value,
        }),
      })
    } else if (type === 'additional2') {
      setValue({
        ...value,
        additionalEdu2: Object.assign(value.additionalEdu2, {
          [event.target.name]: event.target.value,
        }),
      })
    } else {
      setValue({ ...value, [event.target.name]: event.target.value })
    }
  }
  const handleClose = () => {
    setAddSpecHidden(true)
    setOthSpecHidden(true)
    setModHidden(true)
    setTypeHidden(true)
  }

  // Determines what content to display in the Dialog
  const getContent = (contentType) => {
    if (!addspecHidden && contentType === 'addspec') {
      return <AddictionSpecialtyWithOtherOption value={value.addspecialty} handleInputChange={handleInputChange} />
    }    
    if (!othspecHidden && contentType === 'othspec') {
      return <OtherSpecialtyWithOtherOption value={value.othspecialty} handleInputChange={handleInputChange} />
    }
    if (!typeHidden && contentType === 'type') {
      return <TherapyTypesWithOtherOption value={value.therapyType} handleInputChange={handleInputChange} />
    }
    if (!modHidden && contentType === 'mod') {
      return <ModalitiesWithOtherOption value={value.modality} handleInputChange={handleInputChange} />
    }
    return null
  }
  function displayAddSpec() {
    if (addspecHidden) {
      setAddSpecHidden(false)
      setOthSpecHidden(true)
      setModHidden(true)
      setTypeHidden(true)
    } else {
      handleClose()
    }
  }
  function displayOthSpec() {
    if (othspecHidden) {
      setOthSpecHidden(false)
      setAddSpecHidden(true)
      setModHidden(true)
      setTypeHidden(true)
    } else {
      handleClose()
    }
  }
  function displayModality() {
    if (modHidden) {
      setModHidden(false)
      setAddSpecHidden(true)
      setOthSpecHidden(true)
      setTypeHidden(true)
    } else {
      handleClose()
    }
  }
  function displayType() {
    if (typeHidden) {
      setTypeHidden(false)
      setAddSpecHidden(true)
      setOthSpecHidden(true)
      setModHidden(true)
    } else {
      handleClose()
    }
  }
  const handleDeleteItem = (key, item) => {
    const index = value[key].indexOf(item)
    const newValue = value[key].filter((_, i) => i !== index)
    setValue({ ...value, [key]: newValue })
  }

  return (
    <>
      <Typography variant="h3" align="left" gutterBottom>
        Education and Qualifications
      </Typography>
      <Grid container spacing={2}>
        <Grid item>{`License #: ${user.licenseNumber}`}</Grid>
        <Grid item>{`License Type: ${user.professionName}`}</Grid>
        <Grid item>{`State Issued: ${user.licenseState}`}</Grid>
      </Grid>
      <Container maxWidth="sm" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PrimaryEducation value={value.primaryEducation} handleInputChange={handleInputChange} />
            <AdditionalEducation id={1} value={value.additionalEdu1} handleInputChange={handleInputChange} />
            <div>
              {addEducation || value.additionalEdu2.name ? (
                <AdditionalEducation id={2} value={value.additionalEdu2} handleInputChange={handleInputChange} />
              ) : null}
            </div>
            <div>
              {!addEducation && (
                <Button onClick={() => setAddEducation(true)} className={classes.addBtn}>
                  Add Additional Qualifications
                </Button>
              )}
            </div>
          </Grid>
          <Grid container direction="row" className={classes.contentType}>
            <Grid item xs={8}>
              <Typography variant="h6" align="left" gutterBottom>
                Addiction Specialties
                <Button id="addspecialtiesBtn" onClick={displayAddSpec}>
                  <AddIcon className={classes.addIcon} />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <form className={classes.form}>
                <FormControl className={classes.formControl} variant="filled">
                  {getContent('addspec')}
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={8}>
              {value.addspecialty.map((addspecialty) => (
                <ListItem key={addspecialty} value={addspecialty}>
                  <ListItemText primary={addspecialty} />
                  <IconButton
                    size="small"
                    className={classes.red}
                    onClick={() => handleDeleteItem('addspecialty', addspecialty)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.contentType}>
            <Grid item xs={8}>
              <Typography variant="h6" align="left" gutterBottom>
                Other Specialties
                <Button id="othspecialtiesBtn" onClick={displayOthSpec}>
                  <AddIcon className={classes.addIcon} />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <form className={classes.form}>
                <FormControl className={classes.formControl} variant="filled">
                  {getContent('othspec')}
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={8}>
              {value.othspecialty.map((othspecialty) => (
                <ListItem key={othspecialty} value={othspecialty}>
                  <ListItemText primary={othspecialty} />
                  <IconButton
                    size="small"
                    className={classes.red}
                    onClick={() => handleDeleteItem('othspecialty', othspecialty)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.contentType}>
            <Grid item xs={8}>
              <Typography variant="h6" align="left" gutterBottom>
                Type of Therapy
                <Button id="therapyTypeBtn" onClick={displayType}>
                  <AddIcon className={classes.addIcon} />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <form className={classes.form}>
                <FormControl className={classes.formControl} variant="filled">
                  {getContent('type')}
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={8}>
              {value.therapyType.map((type) => (
                <ListItem key={type} value={type}>
                  <ListItemText primary={type} />
                  <IconButton
                    size="small"
                    className={classes.red}
                    onClick={() => handleDeleteItem('therapyType', type)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.contentType}>
            <Grid item xs={8}>
              <Typography variant="h6" align="left" gutterBottom>
                Modality
                <Button id="modalityBtn" onClick={displayModality}>
                  <AddIcon className={classes.addIcon} />
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <form className={classes.form}>
                <FormControl className={classes.formControl} variant="filled">
                  {getContent('mod')}
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={8}>
              {value.modality.map((mode) => (
                <ListItem key={mode} value={mode}>
                  <ListItemText primary={mode} />
                  <IconButton size="small" className={classes.red} onClick={() => handleDeleteItem('modality', mode)}>
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.btnContainer}>
        <Button
          disabled={status === 'save'}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => setStatus('save')}
        >
          {status === 'save' ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}
