import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Paper } from '@material-ui/core'
import JotformEmbed from 'react-jotform-embed'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
  },
}))

export default function TherapistForm() {
  const classes = useStyles()
  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container maxWidth="md">
      <Paper className={classes.content}>
        <div>
          <JotformEmbed src="https://form.jotform.com/221145805345047" />
        </div>
      </Paper>
    </Container>
  )
}
