/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react'
import { Link as RouterLink } from '@reach/router'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import {
  Search,
  Check,
  Clear,
  AddBox,
  DeleteOutline,
  ChevronRight,
  ChevronLeft,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ArrowDownward,
  Remove,
  ViewColumn,
  Sms,
  Email,
  Visibility,
} from '@material-ui/icons'
import { getTherapists2 } from '../../utils/admin-api'

const useStyles = makeStyles(theme => ({}))

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Sms: forwardRef((props, ref) => <Sms {...props} ref={ref} />),
  Email: forwardRef((props, ref) => <Email {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
}

const tableRef = React.createRef()

export default function LicensedTherapists() {
  const classes = useStyles()

  return (
    <div>   
      <MaterialTable        
      title="Licensed Therapists"
      tableRef={tableRef}
      icons={tableIcons}
      options={{
        search: true,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100, 200],
        debounceInterval: 400
      }}
      columns={[
        { title: 'ID', field: 'id', type: 'numeric' },
        { title: 'First Name', field: 'searchFirst' },
        { title: 'Last Name', field: 'searchLast' },
        { title: 'License Email', field: 'email' },
        { title: 'Phone', field: 'phone' },
        { title: 'Claimed', field: 'profileClaimed' },
        { title: 'State', field: 'licenseState' },
      ]}
      data={query =>
        new Promise((resolve, reject) => {
          getTherapists2((query.page + 1),query.pageSize,(query.search || "%"))
            .then(result => {
              resolve({
                data: result.data,
                page: result.currentPage -1,
                totalCount: result.resultsCount,
              })
            })
        })
      }
      actions={[
        {
          icon: () => <Visibility />,
          tooltip: 'View Profile',
          onClick: () => {},
        },
      ]}      
      components={{
        Action: props => (
          <IconButton aria-label="View Profile" component={RouterLink} to={`/view/admintherapist/${props.data.id}/`}>
            <Visibility color="primary" />
          </IconButton>
        ),
      }}
      localization={{
        pagination: {
            labelDisplayedRows: '{from}-{to} of {count}'
        },
        toolbar: {
            nRowsSelected: '{0} row(s) selected'
        },
        header: {
            actions: 'View Details'
        },
        body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
                filterTooltip: 'Filter'
            }
        }
    }}
    />
    </div>
  )
}
