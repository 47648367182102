import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    '& > *': {
      marginRight: '20px',
    },
    '&:hover': {
      animationPlayState: 'paused',
    },

    display: 'flex',
    margin: 0,
    animation: '$slide-right-to-left linear infinite',
    animationDuration: (props) => `${props.count * 2}s`,
  },
  '@keyframes slide-right-to-left': {
    from: {
      transform: 'translateX(0)',
    },
    to: {
      transform: 'translateX(-50%)',
    },
  },
}))

const HorizontalSlider = ({ children }) => {
  const count = React.Children.toArray(children).length
  const classes = useStyles({ count })

  return (
    <div className={classes.container}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default HorizontalSlider
