import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Divider, Button } from '@material-ui/core'
import { formatName } from '../../utils/helper'
import { getUserById } from '../../utils/admin-api'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}))

const EditUser = props => {
  const classes = useStyles()
  // const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  // const [reload, setReload] = useState(false)
  // get user data from api based on personId
  const { status, data, error, isFetching, refetch } = useQuery('user', () =>
          getUserById(props.personId).then(({ user }) => {
      return user
    }),
  )
    useEffect(() => {
      
    }, []);

  const handleCloseAvatar = () => {
    setOpen(false)
    refetch()
  }

  return (
    <div>
      {status === 'loading' ? (
        'Loading...'
      ) : status === 'error' ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          {isFetching ? <div>Refreshing...</div> : null}
          <Grid container direction="row" spacing={3}>
            {/* user Profile Information*/}
            <Grid item xs={12} sm={12} md={12}>
              <Paper className={classes.paper}>
                <Grid container direction="column">
                  {/* Profile Header/title */}
                  <Grid item container alignItems="center" justify="space-around">
                    {/* Title */}
                    <Grid item className={classes.titleCenter}>
                      <Typography align="center" className={classes.blueText} variant="h3" component="h1">
                        {`Update ${data.firstName}'s Profile`}
                      </Typography>
                    </Grid>
                    {/* Space Holder right now*/}
                    <Grid item xs={12} sm={2} />
                  </Grid>
                  <Divider />
                  {/* user Name */}
                  <Grid item container>
                    <Typography variant="h5" className={classes.primaryText}>
                      {formatName(data.firstName, data.middleInitial, data.lastName)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EditUser
