import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import homeQuoteImg from "../../assets/img/homeQuote.jpg"

const useStyles = makeStyles((theme) => ({
  quoteSection: {
    backgroundImage: `url(${homeQuoteImg})`,
    width: "100%",
    height: 380,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: "flex",
    padding: "0 18px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up('md')]: {
      padding: "0 88px",
      justifyContent: "right",
      height: 647,
    },
  },
  quoteBox: {
    maxWidth: "600px",
    height: "192px",
    color: "white",
    padding: "18px",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      height: "323px",
    },
  },
  quoteText: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.3,
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      fontSize: 28,
    }
  },
  quoteAuthor: {
    fontsize: 18,
    fontWeight: 600,
    marginTop: "8px",
    [theme.breakpoints.up('md')]: {
      marginTop: "16px",
    }
  },
  marginSection: {
    marginTop: 80,
  },
}))

export default function QuoteSection() {
    const classes = useStyles();
    return (
        <Box className={`${classes.quoteSection} ${classes.marginSection}`}>
            <Box className={classes.quoteBox}>
                <Typography className={classes.quoteText} component="p">
                "Hope is like the sun, which, as we journey towards it, casts the shadow of our burden behind us...It 
                lends promise to the future and purpose to the past. It turns discouragement to determination.”
                <br/> 
                </Typography>
                <Typography className={classes.quoteAuthor}>- Samuel Smiles</Typography>
            </Box>
        </Box>
    )
}
