import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Divider,
  Grid,
  Container,
  Link,
} from '@material-ui/core'
import SimpleDialogSpan from '../SimpleDialogSpan'
import TermsTherapist from '../../views/TermsTherapist'
import Privacy from '../../views/Privacy'

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: 'blue',
  },
  coupon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  couponInput: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 200,
  },
}))

export default function ReviewDetailsForm({value}) {
  const classes = useStyles()
  return (
    <div>
      <Container maxWidth="sm">
        <Typography variant="h6">Account Details</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Display name:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{`${value.firstName} ${value.middleInitial} ${value.lastName}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Account Email:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.email}</Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant="h6">Billing Details</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Name on Card:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{`${value.cardFName} ${value.cardLName}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Card Number:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.cardNumber}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Card Expiration:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.expDate}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Security Code:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.cvc}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Billing Address:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">
              {value.address1}
              <br />
              {value.address2 && `${value.address2}`}
              <br/>
              {`${value.city}, ${value.state} ${value.zip}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant="h6">Billing Summary</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography align="right">Amount:</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="left">{value.amount}</Typography>
          </Grid>
        </Grid>
        <br />
        <Typography variant="caption">
          By clicking &quot;Donate&quot;, you are agree to Lit Path&quot;s{' '}
          <Link className={classes.link}>
            <SimpleDialogSpan title="Terms of Service">
              <TermsTherapist />
            </SimpleDialogSpan>
          </Link>{' '}
          and acknowledge Therapy Review&#39;s{' '}
          <Link className={classes.link}>
            <SimpleDialogSpan title="Privacy Policy">
              <Privacy />
            </SimpleDialogSpan>
          </Link>
          .
        </Typography>
      </Container>
    </div>
  )
}
