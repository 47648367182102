import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
}))

// to center the rating, use className prop with `justifyContent: 'center'`
export default function TherapistRating({
  value,
  quantity,
  className,
  ...props
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, className)}>
      {/*<Rating*/}
      {/*  readOnly*/}
      {/*  value={value || 0}*/}
      {/*  precision={0.5}*/}
      {/*  className={classes.marginRight}*/}
      {/*  {...props}*/}
      {/*/>*/}
      <Typography component="span">({quantity || 0}) Recommendations</Typography>
    </div>
  )
}
