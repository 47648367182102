import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogTitle, DialogContent, Typography, TextField, DialogActions } from '@material-ui/core'
import { addOption } from '../../utils/therapist-api'
import { useUser } from '../../context/user-context'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({}))

function AddOtherDialog(props) {
  const { enqueueSnackbar } = useSnackbar()
  const { onClose, open, area, title } = props
  const {
    user: { id },
  } = useUser()
  const classes = useStyles()
  const [status, setStatus] = React.useState('idle')
  const inputRef = React.useRef()

  useEffect(() => {
    let canceled = false
    if (status === 'success') {
      enqueueSnackbar('Success', { variant: 'success' })
      onClose()
    } else if (status === 'failure') {
      enqueueSnackbar('Error sending request', { variant: 'error' })
    } else if (status === 'submit') {
      const data = { therapistId: id, area, optionsRequested: inputRef.current.value }
      addOption(data)
        .then(() => setStatus('success'))
        .catch(() => setStatus('failure'))
    }
  }, [status])

  const handleSubmit = (e) => {
    e.preventDefault()
    setStatus('submit')
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Add {title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography>Use this form to send a request to admin to add any missing options to the list.</Typography>
          <Typography variant="subtitle1">
            Note: the requested additions must be approved by admin prior to being added to your profile
          </Typography>
          <TextField
            fullWidth
            label="Enter additional options"
            inputRef={inputRef}
            placeholder={'Option 1, Option 2, Option 3'}
            id="options"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Send Request</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
// a higher-order component is a function that takes a component and returns a new component
export default function withOtherOption(WrappedComponent, listType) {
  // functional component
  return (props) => {
    const [open, setOpen] = React.useState(false)
    const { ...passThroughProps } = props
    const formatListType = () => {
      switch (listType) {
        case 'addspecialty':
          return 'Addiction Specialty'
        case 'othspecialty':
          return 'Other Specialty'
        case 'specialty':
          return 'Specialty'
        case 'therapyType':
          return 'Therapy Type'
        case 'modality':
          return 'Modality'
        case 'language':
          return 'Language'
        case 'paymentMethod':
          return 'Payment Method'
        case 'focusAge':
          return 'Focus Age'
        case 'insurance':
          return 'Insurance'
        default:
          return 'Option'
      }
    }
    const formattedType = formatListType()
    return (
      <>
        <WrappedComponent {...passThroughProps} />
        <div>
          {formattedType} not listed?{' '}
          <Button component="span" onClick={() => setOpen(true)}>
            Add {formattedType}
          </Button>
        </div>
        <AddOtherDialog area={listType} open={open} onClose={() => setOpen(false)} title={formattedType} />
      </>
    )
  }
}
