// Import Icons
import {
  EnhancedEncryption as ForgotPasswordIcon,
  LockOpen as SignUpIcon,
  Lock as LoginIcon,
  AccountBox as AccountIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons'

import LoginPage from './views/Login'
import SignUpPage from './views/SignUp'
import HomePage from './views/Home'
import Donate from './views/Donate'
import Scholarships from './views/Scholarships'
import ParticipatingTherapists from './views/ParticipatingTherapists'
import FindPartnerPage from './views/FindPartners'
import ContactPage from './views/Contact'
import TherapistInfoPage from './views/TherapistInfo'
import AdminTherapistInfoPage from './views/AdminTherapistInfo'
import ClaimProfilePage from './views/ClaimProfile'
import WriteReview from './views/WriteReview'
import TherapistDashboard from './views/TherapistDashboard'
import UpdatePassword from './views/UpdatePassword'
import ForgotPassword from './views/ForgotPassword'
import AdminDashboard from './views/AdminDashboard'
import TherapistApplication from './views/TherapistApplication'
import InviteToRegister from './views/InviteToRegister'
import TermsTherapist from './views/TermsTherapistWithLayout'
import TermsScholarship from './views/TermsScholarshipWithLayout'
import Terms from './views/TermsWithLayout'
import Privacy from './views/PrivacyWithLayout'
import About from './views/AboutUs'
import MoreResources from './views/MoreResources'
import ScholarshipFormPage from './components/ScholarshipPage/ScholarshipFormPage'
import DonateForm from './components/DonateForm'
import TherapistFormPage from './components/TherapistApplication/TherapistFormPage'

// Main Routes show up in main navigation bar for any website visitor
const mainRoutes = [
  {
    path: '/',
    // name: 'Home',
    component: HomePage,
    layout: 'general',
  },
  {
    path: '/about',
    name: 'About Us',
    component: About,
    layout: 'general',
  },
  {
    path: '/find-all',
    // param: 'query',
    // name: 'Find',
    component: FindPartnerPage,
    layout: 'general',
  },
  {
    path: '/find',
    // param: 'query',
    name: 'Participating Therapists',
    component: ParticipatingTherapists,
    layout: 'general',
  },
  {
    path: '/more-resources',
    // param: 'query',
    name: 'More Resources',
    component: MoreResources,
    layout: 'general',
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: ContactPage,
    layout: 'general',
  },
  {
    path: '/scholarships',
    // name: 'Scholarships',
    component: Scholarships,
    layout: 'general',
  },
]

const mobileRoutes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    layout: 'general',
  },
  {
    path: '/about',
    name: 'About Us',
    component: About,
    layout: 'general',
  },
  {
    path: '/find',
    name: 'Participating Therapists',
    component: ParticipatingTherapists,
    layout: 'general',
  },
  {
    path: '/more-resources',
    name: 'More Resources',
    component: MoreResources,
    layout: 'general',
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: ContactPage,
    layout: 'general',
  },
  {
    path: '/therapist-application',
    name: 'Therapist Application',
    component: TherapistApplication,
    layout: 'general',
  },
  {
    path: '/scholarships',
    name: 'Scholarship Application',
    component: Scholarships,
    layout: 'general',
  },
  {
    path: '/login/:display',
    name: 'Login',
    component: LoginPage,
    layout: 'general',
  },
]

const otherPublicRoutes = [
  {
    path: '/terms/termsofservice',
    name: 'Terms of Service',
    component: Terms,
    layout: 'general',
  },
  {
    path: '/terms/privacypolicy',
    name: 'Privacy Policy',
    component: Privacy,
    layout: 'general',
  },
  {
    path: '/terms/therapist',
    name: 'Participating Therapist Agreement',
    component: TermsTherapist,
    layout: 'general',
  },
  {
    path: '/terms/scholarship',
    name: 'Scholarship Recipient Agreement',
    component: TermsScholarship,
    layout: 'general',
  },
  {
    path: '/view/therapist',
    param: 'therapistId',
    name: 'Therapist Profile',
    component: TherapistInfoPage,
    layout: 'general',
  },
  {
    path: '/view/admintherapist',
    param: 'therapistId',
    name: 'Admin Therapist Profile',
    component: AdminTherapistInfoPage,
    layout: 'general',
  },
  {
    path: '/therapist-application',
    name: 'Therapist Application',
    component: TherapistApplication,
    layout: 'general',
  },
  {
    path: '/claim-profile',
    name: 'Claim Profile',
    component: ClaimProfilePage,
    layout: 'general',
  },
  {
    path: '/invite-to-register',
    param: 'therapistId',
    name: 'Invite To Register',
    component: InviteToRegister,
    layout: 'general',
  },
  {
    path: '/donate',
    name: 'Donate',
    component: Donate,
    layout: 'general',
  },
  {
    path: '/scholarship-form',
    component: ScholarshipFormPage,
    layout: 'general',
  },
  {
    path: '/therapist-form',
    component: TherapistFormPage,
    layout: 'general',
  },
  {
    path: '/donate-form',
    component: DonateForm,
    layout: 'general',
  },
]

const unAuthenticatedRoutes = [
  {
    path: '/login/:display',
    name: 'Login',
    icon: LoginIcon,
    component: LoginPage,
    layout: 'general',
  },
  {
    path: '/signup',
    name: 'Sign Up',
    icon: SignUpIcon,
    component: SignUpPage,
    layout: 'general',
  },
  {
    path: '/forgotpassword',
    name: 'Forgot Password',
    icon: ForgotPasswordIcon,
    component: ForgotPassword,
    layout: 'general',
  },
  {
    path: '/password',
    name: 'Update Password',
    icon: LoginIcon,
    component: UpdatePassword,
    layout: 'general',
  },
  {
    path: '/review',
    name: 'Write a Review',
    param: 'therapistId',
    component: WriteReview,
    layout: 'general',
  },
  // move this into authenicated section
  {
    path: '/dashboard/profile/update',
    name: 'Dashboard',
    // icon: Dashboard,
    component: TherapistDashboard,
    layout: 'admin',
  },
]

const authenticatedRoutes = [
  {
    path: '/me',
    name: 'Profile',
    icon: AccountIcon,
    component: TherapistDashboard,
    layout: 'user',
  },
  {
    path: '/review',
    name: 'Write a Review',
    param: 'therapistId',
    component: WriteReview,
    layout: 'general',
  },
  {
    path: '/me/profile/update',
    name: 'Dashboard',
    // icon: Dashboard,
    component: TherapistDashboard,
    layout: 'admin',
  },
]

const adminRoutes = [
  {
    path: 'admin/*',
    name: 'Admin Dashboard',
    icon: DashboardIcon,
    component: AdminDashboard,
  },
]
export { mainRoutes, mobileRoutes, unAuthenticatedRoutes, authenticatedRoutes, otherPublicRoutes, adminRoutes }
