import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useState } from 'react'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  upperCaseTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.6px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      fontWeight: 600,
    },
  },
  storyContainer: {
    display: 'flex',
    width: '100%',
    padding: '80px 112px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 16px',
      gap: '16px',
    },
  },
  storyText: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    letterSpacing: '0.2px',
    fontFamily: 'Inter',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  storyTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    minWidth: '100%',
    transform: (index) => `translateX(${-index * 100}%)`,
    transition: 'all 0.5s ease',
    [theme.breakpoints.down('sm')]: {
      gap: '8px',
    },
  },
  overflowContainer: {
    display: 'flex',
    width: '90%',
    overflowX: 'hidden',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  active: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    flexShrink: 0,
    border: 'none',
    background: '#D49F02',
    cursor: 'pointer',
    filter:
      'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.10)) drop-shadow(0px 9px 9px rgba(0, 0, 0, 0.09)) drop-shadow(0px 21px 13px rgba(0, 0, 0, 0.05)) drop-shadow(0px 38px 15px rgba(0, 0, 0, 0.01)) drop-shadow(0px 59px 16px rgba(0, 0, 0, 0.00))',
  },
  inactive: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    flexShrink: 0,
    background: '#F0F0F0',
    border: 'none',
    cursor: 'pointer',
    filter:
      'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.10)) drop-shadow(0px 9px 9px rgba(0, 0, 0, 0.09)) drop-shadow(0px 21px 13px rgba(0, 0, 0, 0.05)) drop-shadow(0px 38px 15px rgba(0, 0, 0, 0.01)) drop-shadow(0px 59px 16px rgba(0, 0, 0, 0.00))',
  },
  arrowButton: {
    background: '#FFF',
    border: 'none',
    cursor: 'pointer',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  container: {
    display: 'flex',
    width: '95%',
  },
}))

const storyTextContent = [
  [
    'At Lit Path, we understand the challenges faced by individuals seeking recovery therapy. We have personally experienced the weight of debt incurred while trying to access the crucial support needed to overcome compulsive behavior(s). We realized that the decision to seek help is not as simple as it may seem. Balancing financial responsibilities with the cost of therapy can make recovery feel unattainable, perpetuating the cycle of chaos and despair.',
    'Our journey led us to the realization that not all therapists possess the specialized training required to effectively treat compulsive behavior(s). Through trial and error, we discovered the transformative power of certified professionals who have dedicated themselves to compulsive behavior(s) treatment.',
  ],
  [
    'Driven by our own journey and empowered by our personal transformation, we were determined to break down the barriers that hindered others struggling with compulsive behavior(s) from getting the right support. So we founded Lit Path. We personally funded the creation of this 501(c)(3) nonprofit organization, providing the necessary legal, administrative, and functional support, as well as funding our initial scholarships. Our goal is to "light the path out of the darkness of compulsive behavior(s)" through partial scholarships for psychotherapy with certified professionals.',
    "Lit Path strives to make recovery therapy more affordable and accessible for everyone. We accomplish this by offering scholarships of $65 per session for 16 sessions, supporting individuals on their path to recovery from both process compulsive behavior(s) (such as gambling, food, sex, pornography, shopping, etc.) and substance compulsive behavior(s). Unlike scholarship programs with traditional income-based caps, we look for a thin net margin in an individual's income and expenses, identifying those who still face financial obstacles in accessing treatment.",
  ],
  [
    'Through our program, we enable certified therapists to participate at no cost, empowering them to offer scholarships to their clients and continue the awesome work they do. Together, we strive to eliminate the financial barriers that impede recovery.',
    'While we are a relatively new nonprofit organization, we envision becoming one of the largest providers of recovery therapy scholarships in the nation. We believe that every therapist should have the opportunity to offer scholarships allowing their clients to access care, and we are committed to making this a reality. By supporting Lit Path, either through donations, spreading awareness of our mission, or registering as a participating therapist, you join us in transforming lives and lighting the path to recovery.',
  ],
  [
    'At Lit Path, we are driven by our passion for supporting those struggling with compulsive behavior(s). We recognize that they often face judgment and are left feeling isolated, with society mistakenly believing that they can simply overcome their challenges on their own. However, the guilt, shame, and trauma they endure are immense. We firmly believe that compassion and assistance are crucial in helping them rise above their circumstances and find the path to recovery.',
    'Join us in illuminating the path to recovery by donating to our organization, sharing our mission with others, or registering as a participating therapist. Together, we can make a difference and bring hope to those who need it most. Together, we can light the path to recovery.',
  ],
]

const OurStory = () => {
  const [index, setIndex] = useState(0)
  const classes = useStyles(index)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.storyContainer}>
      <Typography component="h2" className={classes.upperCaseTitle}>
        Our Story: Illuminating the Journey to Recovery
      </Typography>
      <div className={classes.container}>
        {!isMobile && (
          <button
            className={index > 0 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index - 1)}
          >
            <ArrowBackIos fontSize="medium" />
          </button>
        )}
        <div className={classes.overflowContainer}>
          {storyTextContent.map((storyText, i) => (
            <div key={i} className={classes.storyTextContainer}>
              {storyText.map((text) => (
                <Typography key={text.substring(0, 10)} className={classes.storyText}>
                  {text}
                </Typography>
              ))}
            </div>
          ))}
        </div>
        {!isMobile && (
          <button
            className={index < 3 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index + 1)}
          >
            <ArrowForwardIos fontSize="medium" />
          </button>
        )}
      </div>

      <div className={classes.buttonContainer}>
        {isMobile && (
          <button
            className={index > 0 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index - 1)}
          >
            <ArrowBackIos fontSize="medium" />
          </button>
        )}

        <button className={index == 0 ? classes.active : classes.inactive} onClick={() => setIndex(0)} />
        <button className={index == 1 ? classes.active : classes.inactive} onClick={() => setIndex(1)} />
        <button className={index == 2 ? classes.active : classes.inactive} onClick={() => setIndex(2)} />
        <button className={index == 3 ? classes.active : classes.inactive} onClick={() => setIndex(3)} />
        {isMobile && (
          <button
            className={index < 3 ? classes.arrowButton : clsx(classes.arrowButton, classes.hidden)}
            onClick={() => setIndex(index + 1)}
          >
            <ArrowForwardIos fontSize="medium" />
          </button>
        )}
      </div>
    </div>
  )
}

export default OurStory
