import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Divider, Button } from '@material-ui/core'
import { formatName } from '../utils/helper'
import Avatar from './TherapistAvatar'
import VerticalUpdateTabs from './VerticalUpdateTabs'
import UploadAvatar from './UploadAvatar'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}))

export default function UpdateProfile({ data }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  if (!data) return null
  // console.log(data)
  return (
    <>
      <Grid container direction="row" spacing={3}>
        {/* Therapist Profile Information*/}
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.paper}>
            <Grid container direction="column">
              {/* Profile Header/title */}
              <Grid item container alignItems="center" justify="space-around">
                {/* Therapist Avatar and Image upload Button*/}
                <Grid item>
                  <Avatar src={data.Person.avatar} />
                  <Button
                    className={classes.submitBtn}
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => setOpen(true)}
                  >
                    Edit Image
                  </Button>
                  <br />
                  <br />
                </Grid>

                {/* Title */}
                <Grid item className={classes.titleCenter}>
                  <Typography
                    align="center"
                    className={classes.blueText}
                    variant="h2"
                    component="h1"
                  >
                    Update Your Profile
                  </Typography>
                </Grid>

                {/* Space Holder right now*/}
                <Grid item xs={12} sm={2} />
              </Grid>
              <Divider />
              {/* Therapist Name */}
              <Grid item container>
                <Typography variant="h5" className={classes.primaryText}>
                  {formatName(
                    data.Person.firstName,
                    data.Person.middleInitial,
                    data.Person.lastName,
                  )}
                </Typography>
                {/* Tab List */}
                <Grid item xs={12} sm={12} className={classes.leftDetails}>
                  <VerticalUpdateTabs />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <UploadAvatar
        id={data.Person.id}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  )
}
