import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  text: {
    fontStyle: 'italic',
  },
}))

export default function TherapistDistance({ distance }) {
  const classes = useStyles()

  if (!distance) return null
  return (
    <Typography className={classes.text} component="span" variant="body2">
      {distance} miles
    </Typography>
  )
}
