import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.grey[500],
  },
}))

export default function TherapistSpecialties({ specialties }) {
  const classes = useStyles()
  if (!specialties || !Array.isArray(specialties)) return null
  return (
    <Typography variant="body1" className={classes.text}>
      {specialties.join(', ')}
    </Typography>
  )
}
