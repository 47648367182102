import React from 'react'
import { Link as RouterLink, navigate } from '@reach/router'
import validator from 'validator'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/Copyright'
import { useAuth } from '../context/auth-context'
import FormErrors from '../components/FormErrors'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignUp({ location, successRedirect = '/login' }) {
  const classes = useStyles()
  const { register } = useAuth()
  const [formErrors, setFormErrors] = React.useState([])

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormErrors([])
    const errors = []
    const { firstName, lastName, email, password } = event.target.elements
    const formData = {
      firstName: firstName.value.trim(),
      lastName: lastName.value.trim(),
      email: email.value.trim(),
      password: password.value.trim(),
    }
    // Validate input
    if (validator.isEmpty(formData.firstName)) {
      errors.push('First name is required')
    }
    if (validator.isEmpty(formData.lastName)) {
      errors.push('Last name is required')
    }
    if (!validator.isEmail(formData.email)) {
      errors.push('Email address is not valid')
    }
    if (!validator.isLength(formData.password, { min: 8 })) {
      errors.push('Password must be at least 8 characters')
    }

    // set errors
    if (errors.length > 0) {
      setFormErrors(errors)
      return
    }

    // no errors...continue on
    register(formData).then(
      async () => {
        await navigate(location.state?.successRedirect || successRedirect)
      },
      () => {
        // console.log('error')
      },
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up to leave a review
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/login/all" variant="body2" component={RouterLink}>
                Already have an account? Login
              </Link>
            </Grid>
          </Grid>
        </form>
        <br />
        <FormErrors errors={formErrors} />
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}
