import React from 'react'
import { Router } from '@reach/router'
import AdminDashboardLayout from '../layouts/AdminDashboardLayout'
// Admin views
import ReviewsView from '../components/Admin/Reviews'
import AccountsView from '../components/Admin/Accounts'
import LicensedTherapistsView from '../components/Admin/LicensedTherapists'
import SystemLogs from '../components/Admin/SystemLogs'
import EditTherapist from "../components/Admin/EditTherapist";
import EditUser from "../components/Admin/EditUser";

export default function AdminDashboard() {
  function Main() {
    return <div>Admin Dashboard</div>
  }

  return (
    <AdminDashboardLayout>
      <Router>
        <Main path="/" />
        <AccountsView path="accounts"/>
        <EditUser path="accounts/:personId/edit" />
        <ReviewsView path="reviews" />
        <SystemLogs path="logs" />
        <LicensedTherapistsView path="licensed-therapists" />
        <EditTherapist path="licensed-therapists/:personId/edit" />
      </Router>
    </AdminDashboardLayout>
  )
}
