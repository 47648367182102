import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Container } from '@material-ui/core/'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import MainLayout from '../layouts/MainLayout'
const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
  },
}))

export default function ForgotPassword() {
  const classes = useStyles()

  return (
    <MainLayout>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <ForgotPasswordForm title="Forgot Password" />
        </Paper>
      </Container>
    </MainLayout>
  )
}
