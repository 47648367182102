import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Messages from '../views/Messages'
import { Typography, IconButton } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

function SimpleDialog(props) {
  const { onClose, open, messageInfo } = props
  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <Messages messageInfo={messageInfo} />
    </Dialog>
  )
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  messageInfo: PropTypes.object.isRequired,
}

export default function MessageDialog({ messageInfo }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <span>
      <Typography color="inherit" variant="inherit" onClick={handleClickOpen}>
        Send Me a Message
      </Typography>
      <SimpleDialog open={open} onClose={handleClose} messageInfo={messageInfo} />
    </span>
  )
}

export function MessageDialogIcon({ messageInfo }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <MailOutlineIcon color="primary" />
      </IconButton>
      <SimpleDialog open={open} onClose={handleClose} messageInfo={messageInfo} />
    </>
  )
}
