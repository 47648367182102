/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import{
  Sms,
  Email,
} from '@material-ui/icons'
import { saveWebsiteVisit, getPublicLicensedTherapist } from '../../utils/client-api'
import { formatTherapistData } from '../../utils/helper'
import FacebookIcon from '@material-ui/icons/Facebook'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import LanguageIcon from '@material-ui/icons/Language'
import { MessageDialogIcon } from '../MessageDialog'
import CustomCategoryList from '../CustomCategoryList'
import { sendVerifyEmail, sendVerifyText } from '../../utils/auth-api'
import { useSnackbar } from 'notistack'
import Avatar from '../TherapistAvatar'

const useStyles = makeStyles((theme) => ({
  profileBanner: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: theme.palette.grey[600],
  },
  rating: {
    justifyContent: 'center',
  },
  autoMargin: {
    margin: 'auto',
  },
  socialLink: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // color: theme.palette.primary.light,
  },
  facebook: {
    color: '#4267B2',
  },
  linkedin: {
    color: '#2867B2',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  leftDetails: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  divider: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hoursDetails: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flex: 1,
  },
  hoursList: {
    display: 'flex',
  },
  unclaimed: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    height: '200px',
  },
  text: {
    textAlign: 'center',
  },
  aboutSection: {
    padding: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 500,
  },
  nolink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  bio: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  socialLinks: {},
  invite:{
    color:'red',
  },
  inviteText:{
    color:'darkblue',
    fontWeight: 400,
  }
}))

export default function TherapistProfile({ id }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [data, setData] = useState()
  const [messageInfo, setMessageInfo] = useState({})
  const [status, setStatus] = useState('loading')
  const { hash } = useLocation()
  const serverName = window.location.hostname

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getPublicLicensedTherapist(id)
        if (res) {
          try {
            setData(formatTherapistData(res))
            setStatus('success')
          } catch (error) {
            // console.log(error)
          }
        }
        setMessageInfo({ id: id, toName: `${res.Person.firstName} ${res.Person.lastName}`, toEmail: res.email })
      } catch (err) {
        setStatus('error')
      }
    }
    if (id) {
      fetchData()
    }
  }, [id])

  // scroll to review section
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.slice(1))
      if (element) element.scrollIntoView()
    }
  }, [hash])

  if (status === 'loading') {
    return null
  }
  if (status === 'error') {
    return (
      <>
        <Typography variant="h5">
          Uh oh! There were problems retrieving the therapist.
          <br />
          Please refresh the page or search for a new therapist.
        </Typography>
        <Link to="/find" component={RouterLink}>
          <Button variant="outlined" color="primary">
            Find Therapist
          </Button>
        </Link>
      </>
    )
  }

  if (!data) {
    return (
      <Typography variant="h5">
        Profile does not exist for that therapist. <br />
      </Typography>
    )
  }
  const addictionSpecialities =  data.specialties.length > 0 && data.specialties.category ? (data.specialties || [{title: "dummy",category: "dummy"}]).filter(obj => (obj.category || "dummy").includes("Addiction")) : []
  const otherSpecialities =  data.specialties.length > 0 && data.specialties.category ? (data.specialties || [{title: "dummy",category: "dummy"}]).filter(obj => (obj.category || "dummy").includes("Other")) : []
  // assumes all values in items are objects containing a "title" key
  const detailCategories = [
    { name: 'Language', items: data.languages },
    { name: 'Accepted Insurance', items: data.insurances },
    { name: 'Accepted Payment Methods', items: data.paymentMethods },
    { name: 'Addiction Specialty', items: addictionSpecialities},
    { name: 'Other Specialty', items: otherSpecialities },
    { name: 'Client Focus Age', items: data.ages },
    { name: 'Treatment Approach', items: data.therapyTypes },
    { name: 'Modality', items: data.modalities },
    { name: 'Remote Sessions', items: data.remoteSession },
  ]

  function tConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
      time = time.slice(1)
      time[5] = +time[0] < 12 ? 'AM' : 'PM'
      time[0] = +time[0] % 12 || 12
    }
    return time.join('')
  }

  function formatHours(day) {
    if (!day) {
      return 'Closed'
    }
    let open = day.slice(0, 1)
    open = tConvert(open)
    let close = day.slice(1, 2)
    close = tConvert(close)
    if (day[0].length < 1 && day[1].length < 1) {
      return 'Closed'
    }
    return (
      <span>
        <span>{open} -</span>
        <br />
        <span>{close}</span>
      </span>
    )
  }

  const hoursList = [
    { name: 'Monday', items: data.hours ? formatHours(data.hours.monday) : null },
    { name: 'Tuesday', items: data.hours ? formatHours(data.hours.tuesday) : null },
    { name: 'Wednesday', items: data.hours ? formatHours(data.hours.wednesday) : null },
    { name: 'Thurday', items: data.hours ? formatHours(data.hours.thursday) : null },
    { name: 'Friday', items: data.hours ? formatHours(data.hours.friday) : null },
    { name: 'Saturday', items: data.hours ? formatHours(data.hours.saturday) : null },
    { name: 'Sunday', items: data.hours ? formatHours(data.hours.sunday) : null },
  ]
  // Hours Section
  function HoursDetails() {
    return (
      <Grid container justify="center">
        {hoursList.map((day) => {
          return (
            <Grid item key={day.name}>
              <ListItem className={classes.text}>
                <ListItemText primary={day.name} secondary={day.items} primaryTypographyProps={{ variant: 'body1' }} />
              </ListItem>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  function EducationDetails() {
    return (
      <Grid container justify="center">
        {data.educations.map((education) => {
          return (
            <Grid item key={education.source}>
              <ListItem className={classes.text}>
                <ListItemText
                  primary={education.name}
                  secondary={education.source + ', ' + education.completedOn}
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            </Grid>
          )
        })}
      </Grid>
    )
  }
  // Left side bar
  function TherapistDetails() {
    return detailCategories.map((category) => {
      if (category.name === 'Remote Sessions') {
        return (
          <React.Fragment key={category.name}>
            <ListItem>
              <ListItemText
                primary={category.name}
                className={classes.primaryText}
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItem>
            <ListItem className={classes.nested} dense>
              <ListItemText primary={`${category.items ? 'Yes' : 'No'}`} />
            </ListItem>
          </React.Fragment>
        )
      }
      return (
        <React.Fragment key={category.name}>
          <CustomCategoryList category={category} />
        </React.Fragment>
      )
    })
  }
  const handleSendEmail = (id) => {
    sendVerifyEmail(id)
      .then(async () => {
        enqueueSnackbar('Invite email sent', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Email failed, has this account already been claimed?', { variant: 'error' })
      })
  }

  const handleSendText = (id) => {
    sendVerifyText(id)
      .then(async () => {
        enqueueSnackbar('Invite text sent', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Text message failed, has this account already been claimed?', { variant: 'error' })
      })
  }
  return (
    <Grid container direction="column" spacing={3}>
      {/* Therapist Profile Information*/}
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            {/* Profile Header/title */}
            <Grid
              item
              container
              alignItems="center"
              alignContent="center"
              justify="space-around"
              className={classes.profileBanner}
            >
              {/* Therapist Name, Profession */}
              <Grid item>
                <Typography variant="h5" className={classes.primaryText}>
                  {data.name}
                </Typography>
                <Typography variant="body1" className={classes.greyText}>
                  {data.profession}
                </Typography>

                  <>
                {/* <Typography variant="body1" className={classes.inviteText}>
                  Invite to claim profile
                </Typography>
                  <Tooltip title="Invite by email">
                        <IconButton
                          onClick={() => {handleSendEmail(data.id)}}
                          className={classes.invite}
                        >
                          <Email />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Invite by text">
                        <IconButton
                          onClick={() => {handleSendText(data.id)}}
                          className={classes.invite}
                        >
                          <Sms />
                        </IconButton>
                      </Tooltip> */}
                      <Typography variant="body1" className={classes.inviteText}>
                  Invite Link: {`https://${serverName}/invite-to-register/${data.id}/`}
                </Typography>
                    {data.website && (
                      <Tooltip title="Open Therapist Website">
                        <IconButton
                          component="a"
                          aria-label="open website"
                          href={data.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            saveWebsiteVisit(data.website)
                          }}
                        >
                          <LanguageIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {data.facebook && (
                      <Tooltip title="Open Facebook Page">
                        <IconButton
                          component="a"
                          aria-label="open facebook"
                          href={data.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon className={classes.facebook} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {data.linkedin && (
                      <Tooltip title="Open LinkedIn Page">
                        <IconButton
                          component="a"
                          aria-label="open linkedin"
                          href={data.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon className={classes.linkedin} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
              </Grid>
              {/* Therapist Badge - avatar & rating */}
              <Grid item className={classes.titleCenter}>
                <Avatar className={classes.autoMargin} src={data.avatar} />
                {/* <a href="#reviews" className={classes.nolink}>
                  <Rating value={data.rating} quantity={data.numRatings} className={classes.rating} />
                  <Typography align="center" className={classes.greyText} variant="body2">
                    Overall Rating
                  </Typography>
                </a> */}
              </Grid>
              {/* Therapist Practice Info */}
              <Grid item>
                  <>
                    <Typography variant="h5" className={classes.primaryText}>
                      {data.companyAddress.organizationName}
                    </Typography>
                    <Typography variant="body1" className={classes.greyText}>
                      {data.companyAddress.streetAddress1}
                      <br />
                      {data.companyAddress.streetAddress2 && (
                        <>
                          {data.companyAddress.streetAddress2} <br />
                        </>
                      )}
                      {data.companyAddress.location}
                      <br />
                      {data.companyPhone}
                      <br />
                      <a href={"mailto:"+data.companyAddress.email}>{data.companyAddress.email}</a> 
                      <br />
                    </Typography>
                  </>
              </Grid>
            </Grid>
            <Divider />
            {/* Therapist Details */}

              <Grid item container>
                {/* Left side bar - Services */}
                <Grid item xs={12} sm={4} className={classes.leftDetails}>
                  <List dense>
                    <TherapistDetails />
                  </List>
                </Grid>

                {/* Right side content */}
                <Grid item xs={12} sm={8} container direction="column" spacing={2} className={classes.aboutSection}>
                  <Grid item className={classes.divider}>
                    <Typography align="center" variant="h6" color="primary">
                      About Me
                    </Typography>
                    <Typography className={classes.bio}>{data.bio}</Typography>
                  </Grid>

                  {data.educations.length > 0 ? (
                    <Grid item className={classes.divider}>
                      <Typography align="center" variant="h6" color="primary">
                        Qualifications
                      </Typography>
                      <EducationDetails />
                    </Grid>
                  ) : null}

                  <Grid item>
                    <Typography align="center" variant="h6" color="primary">
                      Business Hours
                    </Typography>
                    <HoursDetails />
                  </Grid>
                </Grid>
              </Grid>

          </Grid>
        </Paper>
      </Grid>


    </Grid>
  )
}
