import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, TextField, MenuItem, Typography } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { states } from '../../variables/states'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  coupon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  couponInput: {
    margin: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
}))

// eslint-disable-next-line max-lines-per-function
export default function BillingInfoForm({ value, handleInputChange }) {
  const classes = useStyles()
  // TODO: VALIDATE FIELDS
  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <form className={classes.form}>
          <Grid container spacing={2} justify="center" align="flex-start">
            <Grid item xs={12}>
              <Typography align="left">Credit Card Details</Typography>
              <TextField
                required
                fullWidth
                id="cardFName"
                name="cardFName"
                label="First Name (as it appears on card)"
                onChange={handleInputChange}
                value={value.cardName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="cardLName"
                name="cardLName"
                label="Last Name  (as it appears on card)"
                onChange={handleInputChange}
                value={value.cardName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="cardNumber"
                name="cardNumber"
                label="Card number (no dashes or spaces)"
                fullWidth
                onChange={handleInputChange}
                value={value.cardNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask mask="99/99" maskPlaceholder="MM/YY" onChange={handleInputChange} value={value.expDate}>
                <TextField required id="expDate" name="expDate" label="Expiry date" fullWidth />
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="cvc"
                name="cvc"
                label="Security Code"
                fullWidth
                onChange={handleInputChange}
                value={value.cvc}
              />
            </Grid>
            <Grid item xs={12} />
            <div className={classes.divider} />
            <Grid item xs={12}>
              <Typography align="left">Billing Address</Typography>
              <TextField
                required
                id="address1"
                name="address1"
                label="Address line 1"
                fullWidth
                onChange={handleInputChange}
                value={value.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address2"
                name="address2"
                label="Address line 2"
                fullWidth
                onChange={handleInputChange}
                value={value.address2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                fullWidth
                onChange={handleInputChange}
                value={value.city}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                fullWidth
                id="state"
                name="state"
                label="State"
                onChange={handleInputChange}
                value={value.state}
                align="left"
              >
                {states.map((state) => (
                  <MenuItem key={state.name} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="zip"
                name="zip"
                label="Zipcode"
                fullWidth
                onChange={handleInputChange}
                value={value.zip}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="amount"
                name="amount"
                label="Amount"
                type="number"
                fullWidth
                onChange={handleInputChange}
                value={value.amount}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
