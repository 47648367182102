import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, Grid, IconButton, Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HelpLineBox from './HelpLineBox'
import landingImage from '../../assets/img/karsten-winegeart-urbseZKkXLY-unsplash.jpg'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: '95%',
    height: '95vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#FFF',
    background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${landingImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    gap: '5px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
    },
  },
  transparentContainer: {
    opacity: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '5%',
  },
  modalContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '95%',
    height: '95%',
    padding: '0px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  crisisTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '20px',
  },
  crisisTitle: {
    color: '#2E416B',
    fontSize: '60px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '133%',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      width: '100%',
    },
  },
  crisisDescription: {
    color: '#2E416B',
    fontSize: '20px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  helplineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
  },
  helplineText: {
    color: '#E6E6E6',
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
}))

const CrisisModal = ({ open, handleClose }) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={handleClose}>
      <div id="crisis" className={classes.transparentContainer}>
        <div className={classes.modalContainer}>
          <div className={classes.closeButtonContainer}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.modalContentContainer}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6}>
                <div className={classes.crisisTextContainer}>
                  <Typography className={classes.crisisTitle}>Experiencing a Crisis?</Typography>
                  <Typography className={classes.crisisDescription}>
                    If you are experiencing a crisis, please dial 911 or call/text one of the listed numbers, Remember,
                    you are not alone in this journey, and there is a network of support waiting to guide you towards
                    lasting healing and positive transformation.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.helplineContainer}>
                  <HelpLineBox>
                    <Typography className={classes.helplineText}>National Suicide & Crisis Helpline</Typography>
                    <Typography className={classes.helplineText}>24 Hrs / 7 Days</Typography>
                    <Typography className={classes.helplineText}>Call or Text 988</Typography>
                  </HelpLineBox>
                  <HelpLineBox>
                    <Typography className={classes.helplineText}>United Way Helpline</Typography>
                    <Typography className={classes.helplineText}>Dial 211 for assistance</Typography>
                  </HelpLineBox>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CrisisModal
