import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SvgIcon, Typography, makeStyles, useTheme } from '@material-ui/core'
import React from 'react'

const primary = "#1B3666";

const useStyles = makeStyles(theme => ({
    select: {
      margin: "0 auto",
      width: "100%",
      maxWidth: 600,
      maxHeight: 40,
      overflow: "hidden",
      padding: "0 !important",
      outline: 0,
      border: "1px solid black",
      borderRadius: "24px",
      "&.Mui-focused": {
        outline: 0,
        backgroundColor: "white",
      },
      "& .MuiSelect-root:focus": {
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-input": {
          paddingTop: 10,
          paddingBottom: 10,
      },
      "&:hover": {
        border: "2px solid black",
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 150,
        maxWidth: 200,
      },
    },
    active: {
      backgroundColor: `${primary} !important`,
      color: "white",
    },
    inputLabel: {
        color: "black",
    },
    formControl: {
        maxHeight: "200px",
    },
    selectedText: {
        textAlign: "left",
    },
    filterSubtitle: {
        padding: "10px 0",
        marginLeft: "20px",
        marginBottom: "10px"
    },
    filterPlaceholder: {
        textAlign: "left",
        width: "100%",
        color: "black",
        fontSize: 16,
        padding: "0 10px"
    },
    menuItem: { 
      display: "inline-flex", 
      width: "100%",
      [theme.breakpoints.up('lg')]: {
        width: "50%",
      },
    },
    icon: {
      "&.active": {
       fill: "white",
      },
    },
}))

const SessionMenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: "min(100%, 350px)",
      maxHeight: 400,
      borderRadius: 10,
      overflowY: "auto",
      marginTop: 50,
    },
  },
};


export default function FilterSelect({options, setValue, selectedValue, filterOption}) {
  const theme = useTheme();
  const MenuProps = {
    getContentAnchorEl: ()=>null,
    PaperProps: {
      style: {
        maxWidth: "min(100%, 700px)",
        maxHeight: 400,
        borderRadius: 10,
        overflowY: "auto",
        marginTop: 50,
      },
    },
  };
  const handleChange = (event) => 
  {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const classes = useStyles();  
  return (
    <FormControl className={classes.formControl} variant="outlined">
        {/* <InputLabel id={`filter-${filterOption}`} className={classes.inputLabel}
                    variant='outlined' notched={true}>
                        {filterOption}
        </InputLabel> */}
        <Select
          className={`${classes.select} ${selectedValue.length > 0 ? classes.active : ""}`}
          // className={`${classes.select} ${classes.active}`}
          displayEmpty
          multiple
        //   labelId={`filter-${filterOption}`}
          id="demo-multiple-checkbox"
          value={selectedValue}
          renderValue={selectedValue.length > 0 ? (selected) => selected.join(', ') : () =>{return filterOption}}
          onChange={handleChange}
          inputProps={{
            classes: {
                icon: selectedValue.length > 0 ? `${classes.icon} active` : classes.icon,
            },
          }}
          MenuProps={filterOption === "Session Options" ? SessionMenuProps : MenuProps}
        >
          <MenuItem disabled value="" className={classes.filterPlaceholderItem}>
            <Typography className={classes.filterPlaceholder}>{filterOption}</Typography>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={`${option} ${index}`} value={option} className={filterOption==="Session Options" ? "" : classes.menuItem}>
                <Box sx={{display: "flex", alignItems: "center", maxHeight: 25}}>
                    <Checkbox checked={selectedValue.indexOf(option) > -1} />
                    <ListItemText className={classes.selectedText} primary={option} />
                </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}
