import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider } from './auth-context'
import { UserProvider } from './user-context'
import { FilterProvider } from './filter-context'

export default function AppProviders({ children }) {
  return (
    <AuthProvider>
      <HelmetProvider>
        <UserProvider>
          <FilterProvider>{children}</FilterProvider>
        </UserProvider>
      </HelmetProvider>
    </AuthProvider>
  )
}
