import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper, Grid } from '@material-ui/core'
import MessageForm from '../components/MessageForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  margin: {
    height: theme.spacing(2),
  },
  subTitle: {
    color: theme.palette.grey[600],
    marginBottom: 4,
  },
  formContainer: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(3),
    background: `rgba(255,255,255,0.8)`,
  },
}))

export default function Message({ messageInfo }) {
  const classes = useStyles()
  return (
    <>
      <Grid container>
        <Grid item component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography align="center" variant="h4" component="h1">
              Send a message:
            </Typography>
            <Typography align="center" variant="subtitle1">
              (All fields are required)
            </Typography>
            <div className={classes.margin} />
            <MessageForm messageInfo={messageInfo} />
          </div>
        </Grid>
      </Grid>
    </>
  )
}
