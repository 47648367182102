import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  resourceCatgegoryContainer: {
    display: 'flex',
    position: 'relative',
    width: '350px',
    height: '350px',
    gap: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    perspective: '1000px',
    transformStyle: 'preserve-3d',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
    },
  },
  frontContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
    backfaceVisibility: 'hidden',
    transform: (isFlipped) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'),
    transition: 'transform 1s',
  },
  backContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
    backfaceVisibility: 'hidden',
    transform: (isFlipped) => (isFlipped ? 'rotateY(0deg)' : 'rotateY(-180deg)'),
    transition: 'transform 1s',
    paddingTop: 10,
  },
  resourceImage: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
    borderRadius: '6px 6px 0px 0px',
  },
  resourceTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    paddingBottom: '30px',
  },
  resourceTitle: {
    color: '#000',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '132%',
    letterSpacing: '-0.312px',
    textAlign: 'center',
  },
  button: {
    padding: '10px',
    borderRadius: '50%',
    flexShrink: 0,
    background: '#E6E6E6',
    border: 'none',
    justifyContent: 'center',
  },
  linksContainer: {
    display: 'flex',
    width: '100%',
    height: '95%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  link: {
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    fontFamily: 'Inter',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  backResourceTitle: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '132%',
    letterSpacing: '-0.312px',
    textAlign: 'center',
  },
}))

const Resource = ({ title, image, links }) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleFlip = () => {
    setIsFlipped(!isFlipped)
  }

  const classes = useStyles(isFlipped)
  return (
    <div className={classes.resourceCatgegoryContainer} onClick={handleFlip}>
      <div className={classes.frontContainer}>
        <img src={image} alt={title} className={classes.resourceImage} />
        <div className={classes.resourceTitleContainer}>
          <div>
            <Typography className={classes.resourceTitle}>{title}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.backContainer}>
        <Typography className={classes.backResourceTitle}>{title}</Typography>
        <div className={classes.linksContainer}>
          {links.map((link) => (
            <div key={link.name} className={classes.link}>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Resource
