import { Machine, assign } from 'xstate'

// Guards
const tooManyAttempts = (context, _) => context.attempts >= 3

// Actions
const incrementAttempts = assign({
  attempts: (context, _) => context.attempts + 1,
})

const resetAttempts = assign({ attempts: 0 })

const claimProfileMachine = Machine(
  {
    id: 'claimProfile',
    initial: 'info',
    context: {
      attempts: 0,
    },
    states: {
      info: {
        on: {
          CONTINUE: {
            target: 'verify',
          },
        },
      },
      verify: {
        on: {
          SUBMIT: 'code',
          GOBACK: 'info',
        },
      },
      code: {
        on: {
          RESOLVE: 'claimed',
          REJECT: [
            {
              target: 'failure',
              // Only transition to 'failure' if the guard (cond) evaluates to true
              cond: 'tooManyAttempts',
            },
            {
              target: 'code',
              actions: ['incrementAttempts'],
            },
          ],
        },
      },
      claimed: {
        type: 'final',
      },
      failure: {
        on: {
          RETRY: {
            target: 'verify',
            actions: 'resetAttempts',
          },
        },
      },
    },
  },
  {
    guards: {
      tooManyAttempts,
    },
    actions: { resetAttempts, incrementAttempts },
  },
)

export default claimProfileMachine
