import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Logo from '../../assets/img/lit-logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },
  comingSoonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  comingSoonLogo: {
    width: '150px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
  },
  comingSoonText: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    fontFamily: 'Inter',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
}))

const ComingSoon = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.comingSoonContainer}>
        <img src={Logo} alt="Litpath logo" className={classes.comingSoonLogo} />
        <Typography className={classes.comingSoonText}>Coming soon!</Typography>
        <img src={Logo} alt="Litpath logo" className={classes.comingSoonLogo} />
      </div>
    </div>
  )
}

export default ComingSoon
