import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TopNavBar from '../components/TopNavBar'
import Footer from '../components/Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'white',
  },
  main: {
    // marginTop: theme.spacing(6),
  },
  footer: {
    // padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: 'white',
    color: theme.palette.common.white,
  },
}))

export default function HelpLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopNavBar />
      <div className={classes.main}>{children}</div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}
