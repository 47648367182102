/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Container, Grid, List, TablePagination, Divider } from '@material-ui/core'
import * as queryString from 'query-string'
import MainLayout from '../layouts/MainLayout'
import SearchBarTherapist from '../components/SearchBarTherapist'
import FilterBox from '../components/FilterBox'
import TherapistListItem from '../components/TherapistListItem'
import { getAllLicensedTherapists, searchLicensedTherapists } from '../utils/client-api'
import { calcAverageRating, calcDistance } from '../utils/helper'
import geoData from '../variables/latlng.json'

const useStyles = makeStyles(theme => ({
  main: {
    padding: 15,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  resultContainer: {
    marginTop: theme.spacing(3),
    background: `rgba(255,255,255,0.8)`,
    borderRadius: 5,
  },
  left: {
    // margin: theme.spacing(3),
    // width: 250,
  },
  right: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },

    // flexGrow: 1,
  },
  pagination: {
    flex: 0,
  },
  divider: {
    margin: theme.spacing(1),
  },
}))

function getLatLng(zipcode) {
  const entry = geoData.find(item => {
    if (item.fields.zip === zipcode) {
      return true
    }
    return false
  })
  if (entry) {
    return {
      latitude: entry.fields.latitude,
      longitude: entry.fields.longitude,
    }
  }
  return null
}

function getDistance(zipcode, lat, lng) {
  if (!zipcode || !lat || !lng) return null
  const zip = getLatLng(zipcode)
  if (zip) {
    const distance = calcDistance(zip.latitude, zip.longitude, lat, lng)
    return distance
  }
  return null
}

export default function FindPartners({ location }) {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [status, setStatus] = useState('idle')
  // all data entries returned from search without any modifications or filtering or sorting
  const [data, setData] = useState([])
  // data displayed in list of therapists after applying any filters if applicable
  const [results, setResults] = useState([])
  const [page, setPage] = useState(0)
  const [resultsPerPage, setResultsPerPage] = useState(25)
  const [pageReset, setPageReset] = useState(0)
  const [searching, setSearching] = useState(true)

  useEffect(() => {
    //  On load, retrieve a snippet of therapists
    async function fetchData() {
      const res = await getAllLicensedTherapists()
      // only display therapists who have a Person table
      const resFiltered = res.filter(t => t.Person)
      setData(resFiltered)
      setResults(resFiltered)
      setSearching(false)
      setPage(0)
    }
    fetchData()
  }, [pageReset])

  // update search value if forwarded here from home page search bar
  useEffect(() => {
    if (location) {
      const { q } = queryString.parse(location.search)
      if (q) {
        setValue(q)
        setStatus('search')
      }
    }
  }, [location])

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let canceled = false

    if (status === 'search') {
      searchLicensedTherapists(value)
        .then(res => {
          if (canceled) return
          if (res) {
            setPage(0)
            setData(res)
            setSearching(false)
            setResults(res)
          }
        })
        .catch(err => {
          if (canceled) return
          if (err) {
            // console.log(err.response)
          }
        })
        .finally(() => {
          setStatus('idle')
        })
    }

    return () => {
      canceled = true
    }
  }, [status, value])

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    // verify search isn't empty
    if (value.length !== 0) {
      setStatus('search')
    } else {
      setPageReset(pageReset + 1)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setResultsPerPage(parseInt(event.target.value, 10))
  }

  const handleUpdateFilters = filterItems => {
    // console.log(filterItems) // rating, insurance (array), specialty (array), zipcode, distance
    let isWithinDistance = true
    let isWithinRating = true
    let isInInsurance = true
    let isInSpecialty = true

    const filteredData = data.filter(therapist => {
      if (!therapist.WorkLocation) return null
      if (filterItems.rating) {
        const aveRating = calcAverageRating(therapist.Reviews)
        // check if passes rating filter
        isWithinRating = parseInt(filterItems.rating, 10) <= parseInt(aveRating, 10)
      }
      // if no insurance filters selected automatically pass the insurance filter
      // checks to see if any of the selected insurance item in the filter is met by the therapist.
      if (filterItems.insurance.length > 0) {
        isInInsurance = filterItems.insurance.some(insFilter => {
          return therapist.Person.Insurances.some(insPerson => insPerson.title === insFilter)
        })
      }
      // if no specialty filters selected automatically pass the speciatlty filter
      // checks to see if any of the selected specialties in the filter is met by the therapist.
      if (filterItems.specialty.length > 0) {
        isInSpecialty = filterItems.specialty.some(insFilter => {
          return therapist.Person.Specialties.some(insPerson => insPerson.title === insFilter)
        })
      }
      if (filterItems.zipcode && filterItems.distance) {
        let d
        if (filterItems.zipcode === therapist.WorkLocation.postalCode) {
          d = 0
        } else {
          d = getDistance(filterItems.zipcode, therapist.WorkLocation.latitude, therapist.WorkLocation.longitude)
        }

        // eslint-disable-next-line no-negated-condition
        if (d !== null) {
          isWithinDistance = d <= parseInt(filterItems.distance, 10)
        } else {
          isWithinDistance = false
        }
      }
      return isWithinRating && isInInsurance && isInSpecialty && isWithinDistance
    })
    setResults(filteredData)
  }
  const handleClearFilters = () => {
    setResults(data)
  }

  return (
    <MainLayout>
      <Container maxWidth="md" justify="center" align="center" className={classes.main}>
        <div className={classes.title}>
          <Typography variant="h4" component="h1">
            Give Hope
          </Typography>
        </div>

        <SearchBarTherapist
          value={value}
          handleSearch={handleSearch}
          handleChange={e => setValue(e.target.value)}
          handleKeyPress={handleKeyPress}
        />

        <Grid container className={classes.resultContainer} spacing={3}>
          <Grid item xs={12}>
            <FilterBox onUpdate={handleUpdateFilters} onClear={handleClearFilters} />
          </Grid>
          <Grid item className={classes.right}>
            {searching ? (
              <Typography align="center">Searching ...</Typography>
            ) : results.length === 0 ? (
              <Typography align="center">No results to display</Typography>
            ) : (
              <>
                <List>
                  {/* Display results based on pagination */}
                  {results.slice(page * resultsPerPage, page * resultsPerPage + resultsPerPage).map(t => (
                    <div key={t.id}>
                      <TherapistListItem key={t.id} data={t} />
                      <div className={classes.divider} />
                    </div>
                  ))}
                </List>
                <TablePagination
                  classes={{ spacer: classes.pagination }}
                  rowsPerPageOptions={[25, 50, 300]}
                  component="div"
                  count={results.length}
                  rowsPerPage={resultsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  )
}
