import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
// Material UI core components
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
// Material UI icons
import { ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon, FilterList as FilterListIcon } from '@material-ui/icons'
// Custom Components
import EnhancedTableHead from '../Table/EnhancedTableHead'
// Utils
import { getFlaggedReviews, deleteReviews, approveReviews } from '../../utils/admin-api'
import { getComparator, stableSort } from '../../utils/tableHelper'

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
  { id: 'rating', numeric: false, disablePadding: false, label: 'Rating' },
]

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected, handleDelete, handleAccept } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="textPrimary" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Flagged Reviews
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Approve Review">
            <IconButton aria-label="approve" onClick={handleAccept}>
              <ThumbUpIcon className={classes.success} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Review">
            <IconButton aria-label="delete" onClick={handleDelete}>
              <ThumbDownIcon className={classes.error} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}))

// eslint-disable-next-line max-lines-per-function
export default function Reviews() {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [status, setStatus] = useState('idle')
  // retrieve flagged reviews on page load
  useEffect(() => {
    let canceled = false

    getFlaggedReviews()
      .then(res => {
        if (canceled) return
        setRows(res)
      })
      .catch(() => {
        // TODO: SYSTEM LOG
      })
    return () => {
      canceled = true
    }
  }, [])

  // handle api calls to update review status
  useEffect(() => {
    let canceled = false

    function removeSelectedReviews() {
      setRows(rows.filter(r => !selected.includes(r.id)))
    }

    if (status === 'idle') {
      return
    }
    if (status === 'delete') {
      deleteReviews(selected)
        .then(() => {
          if (canceled) return
          // update rows
          removeSelectedReviews()
        })
        .catch(() => {
          // TODO: SYSTEM LOG
        })
        .finally(() => {
          setStatus('idle')
        })
    } else if (status === 'accept') {
      approveReviews(selected)
        .then(() => {
          if (canceled) return
          // update rows
          removeSelectedReviews()
        })
        .catch(() => {
          // TODO: SYSTEM LOG
        })
        .finally(() => {
          setStatus('idle')
        })
    }
    // eslint-disable-next-line consistent-return
    return () => {
      canceled = true
    }
  }, [rows, selected, status])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = event => {
    setDense(event.target.checked)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleDelete = () => {
    if (status === 'idle') {
      setStatus('delete')
    }
  }
  const handleAccept = () => {
    if (status === 'idle') {
      setStatus('accept')
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} handleDelete={handleDelete} handleAccept={handleAccept} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="flaggedReviews"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              checkbox
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.comment}</TableCell>
                      <TableCell>{row.rating}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  )
}
