import React from 'react'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link, Button, useTheme, useMediaQuery } from '@material-ui/core'
import PropTypes from 'prop-types'

const primaryColor = '#1B3666'
const width = '1100px'

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '80px 200px',
    [theme.breakpoints.down('md')]: {
      padding: '80px 112px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  heroContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 'auto',
    maxWidth: width,
    gap: '10%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    },
  },
  restrictText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    textAlign: 'left',
    justifyContent: 'center',
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headerText: {
    color: primaryColor,
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '62px',
    letterSpacing: '-0.015em',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeaderText: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      color: primaryColor,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '42px',
    },
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#000000',
    fontWeight: 400,
    lineHeight: '27px',
    letterSpacing: '0.01em',
    marinTop: '96px',
  },
  image: {
    width: '454px',
    height: '454px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '320px',
      maxWidth: '420px',
    },
  },
  applyButton: {
    borderRadius: '100px',
    background: primaryColor,
    color: 'white',
    padding: '16px 32px',
    height: '59px',
    width: '300px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#132648',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export default function TitleSlide(props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.container}>
      <div className={classes.heroContent}>
        <div className={classes.restrictText}>
          <Typography variant="h1" className={classes.headerText}>
            {props.header}
          </Typography>
          {isMobile && (
            <div
              style={{
                width: '100%',
                height: '520px',
                padding: '0px 0px 32px 0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '24px',
              }}
            >
              <Typography component="h2" className={classes.mobileHeaderText}>
                {props.header}
              </Typography>
              <img align="center" className={classes.image} alt={props.alt} src={props.image} />
            </div>
          )}
          <Typography className={classes.text}>{props.description}</Typography>
          <Link component={RouterLink} to="/scholarship-form">
            <Button className={clsx(classes.applyButton, classes.resizedButton)}>Scholarship Application </Button>
          </Link>
        </div>
        {!isMobile && <img align="center" className={classes.image} alt={props.alt} src={props.image} />}
      </div>
    </div>
  )
}

TitleSlide.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}
