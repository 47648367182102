/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react'
import { Link as RouterLink } from '@reach/router'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import {
  Search,
  Check,
  Clear,
  AddBox,
  DeleteOutline,
  ChevronRight,
  ChevronLeft,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ArrowDownward,
  Remove,
  ViewColumn,
} from '@material-ui/icons'
import { getUsers } from '../../utils/admin-api'

const useStyles = makeStyles(theme => ({}))

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export default function Accounts() {
  const classes = useStyles()
  const [data, setData] = React.useState([])

  useEffect(() => {
    let canceled = false

    getUsers()
      .then(res => {
        if (canceled) return
        setData(res)
      })
      .catch(() => {
        // TODO: SYSTEM LOG
      })
    return () => {
      canceled = true
    }
  }, [])

  return (
    <div>
      <MaterialTable
        options={{
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100, 200],
        }}
        title="User Accounts"
        icons={tableIcons}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: 'Edit Profile',
            onClick: () => {},
          },
        ]}
        components={{
          Action: props => (
            <IconButton aria-label="edit user" component={RouterLink} to={`${props.data.Person.id}/edit`}>
              <Edit color="primary" />
            </IconButton>
          ),
        }}
        columns={[
          { title: 'ID', field: 'Person.id', type: 'numeric' },
          { title: 'First Name', field: 'Person.firstName' },
          { title: 'Last Name', field: 'Person.lastName' },
          { title: 'Email', field: 'address' },
          //{ title: 'State', field: 'licenseState' },
          { title: 'Role', field: 'Person.role' },
        ]}
        data={data}
      />
    </div>
  )
}
