import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import BeliefCard from './BeliefCard'
import diverseAddictionImage from '../../assets/img/martin-sanchez-1349060-unsplash.jpg'
import empathyImage from '../../assets/img/neil-thomas-SIU1Glk6v5k-unsplash.jpg'
import breakingBarriersImage from '../../assets/img/karsten-winegeart-urbseZKkXLY-unsplash.jpg'
import pyscotherapyImage from '../../assets/img/getty-images-oWArbK9KMIo-unsplash.jpg'
import conversionTherapyImage from '../../assets/img/anaya-katlego-mHdDmdeyVb4-unsplash.jpg'
import universalImpactImage from '../../assets/img/ales-krivec-47HCahjT-cc-unsplash (1).jpg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '80px',
    padding: '80px 160px',
    background: '#FFF',
    [theme.breakpoints.down('md')]: {
      padding: '80px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      gap: '24px',
    },
  },
  upperCaseTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.6px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  titleContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  descriptionText: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.18px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  beliefsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '80px',
    minWidth: '100%',
    padding: '0px 30px',
    paddingBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      gap: '32px',
      padding: '0px',
    },
  },
}))

const beliefs = [
  {
    image: diverseAddictionImage,
    title: 'Diverse Scope of Compulsive behavior(s)',
    text: 'At Lit Path, we recognize that compulsive behavior(s) extend beyond drug and alcohol dependencies. They encompass a wide range of struggles, including but not limited to sex, pornography, gambling, work, shopping, and eating disorders. Each of these challenges is equally destructive to individuals and their families and warrants the same level of importance and attention.',
    altText: 'a person lying on ice',
  },
  {
    image: empathyImage,
    title: 'Empathy and Support',
    text: 'We firmly believe that individuals grappling with compulsive behavior(s), as well as their families, deserve love, compassion, care, and support. We aim to create a safe and nurturing environment where everyone can find the understanding and encouragement they need to embark on their healing journey.',
    altText: 'a tree trunk supported by a structure with that resembles a hand',
  },
  {
    image: breakingBarriersImage,
    title: 'Breaking Barriers',
    text: 'Recognizing the difficulties involved in seeking help, Lit Path strives to dismantle additional obstacles that individuals face, such as financial constraints and the struggle to find qualified therapists. We are committed to making recovery therapy more accessible and attempting to eliminate individuals being denied the opportunity to heal due to these barriers.',
    altText: 'a person standing in front of a window',
  },
  {
    image: pyscotherapyImage,
    title: 'Importance of Psycotherapy',
    text: 'We firmly believe that psychotherapy plays a crucial role in achieving successful recovery. Healing from trauma is an integral part of the process, and we advocate for affordable and accessible psychotherapy services for anyone seeking to break free from compulsive behavior(s).',
    altText: 'an empty grey couch with white pillows and a small table in front of a grey wall',
  },
  {
    image: conversionTherapyImage,
    title: 'Opposing Reparative or Conversion Therapy',
    text: 'Lit Path firmly opposes the practice of reparative or conversion therapy. As a result, we do not provide scholarships to therapists practicing these harmful approaches.',
    altText: 'a person looking out a window',
  },
  {
    image: universalImpactImage,
    title: 'Universal Impact',
    text: 'Compulsive behavior(s) can affect anyone, regardless of race, age, sexual orientation, gender expression, nationality, religion, creed, ethnicity, or ability. At Lit Path, we embrace diversity and recognize that these struggles can impact individuals from all walks of life. We are dedicated to providing inclusive and equitable support to everyone in need.',
    altText: 'a sun shining through the mountains',
  },
]

const CoreBeliefs = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography className={classes.upperCaseTitle} component="h2">
          Our Core Beliefs
        </Typography>
        <Typography className={classes.descriptionText}>
          Our core beliefs guide our work at Lit Path, shaping our commitment to compassionate care, accessible therapy,
          and a supportive community for those seeking recovery.
        </Typography>
      </div>
      <div className={classes.beliefsContainer}>
        {beliefs.map((belief, i) => (
          <BeliefCard key={belief.title} {...belief} alt={mobile ? false : i % 2 != 0} />
        ))}
      </div>
    </div>
  )
}

export default CoreBeliefs
