import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// Material-UI Core Components
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
// Custom Components
import EnhancedTableHead from '../Table/EnhancedTableHead'
import SystemLogRow from '../Table/SystemLogRow'
// Utils and helpers
import { getSystemLogs } from '../../utils/admin-api'
import { getComparator, stableSort } from '../../utils/tableHelper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
}))

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
  { id: 'meta', numeric: false, disablePadding: false, label: 'Meta' },
  // { id: 'personId', numeric: false, disablePadding: false, label: 'User Id' },
  // { id: 'Person', numeric: false, disablePadding: false, label: 'User' },
]

export default function SystemLogs() {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [selected, setSelected] = React.useState()
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  // const [status, setStatus] = useState('idle')
  // retrieve flagged reviews on page load
  useEffect(() => {
    let canceled = false
    getSystemLogs()
      .then(res => {
        if (canceled) return
        console.log(res[1])
        setRows(res)
      })
      .catch(err => {
        console.log(err)
      })

    return () => {
      canceled = true
    }
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event, id) => {
    if (selected === id) {
      setSelected()
    } else {
      setSelected(id)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleChangeDense = event => {
    setDense(event.target.checked)
  }
  const isSelected = id => selected === id

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            System Logs
          </Typography>
          <Tooltip title="Filter List">
            <IconButton aria-label="filter list">
              <FilterList />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="sytem logs"
            size={dense ? 'small' : 'medium'}
            aria-label="sytem logs table"
          >
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `system-log-table-${index}`

                  return (
                    <SystemLogRow
                      key={labelId}
                      id={labelId}
                      onClick={event => handleClick(event, row.id)}
                      isItemSelected={isSelected(row.id)}
                      row={row}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  )
}
