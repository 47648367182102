/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { useSnackbar } from 'notistack'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Container,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
import { sendVerifyEmail, sendVerifyText } from '../utils/auth-api'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  radioGroup: {
    // flexWrap: 'nowrap',
  },
  textField: {
    // maxWidth: 240,
  },
  button: {
    margin: theme.spacing(2),
  },
}))

export default function VerifyProfile({ data, onSubmit, onGoBack }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('none')
  const [phone, setPhone] = React.useState('')
  const [error, setError] = React.useState()
  const [status, setStatus] = React.useState('idle') // idle, send, success, failure
  const emailRef = React.useRef()

  useEffect(() => {
    let canceled = false
    if (status === 'send') {
      if (value === 'email') {
        sendVerifyEmail(data.id)
          .then((_res) => {
            if (canceled) return
            setStatus('success')
          })
          .catch((_err) => {
            if (canceled) return
            setStatus('failure')
          })
      } else if (value === 'text') {
        sendVerifyText(data.id)
          .then((_res) => {
            if (canceled) return
            setStatus('success')
          })
          .catch((_err) => {
            if (canceled) return
            setStatus('failure')
          })
      }
    } else if (status === 'success') {
      enqueueSnackbar('Verification code sent', { variant: 'success' })
      onSubmit()
    } else if (status === 'failure') {
      enqueueSnackbar('Error sending verification code', { variant: 'error' })
    }
    return () => {
      canceled = true
    }
  }, [data.id, enqueueSnackbar, onSubmit, status, value])

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  // only allow numbers to be included in input
  const onHandleChangeNumeric = (e) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      setPhone(e.target.value)
    }
  }
  const hideEmail = (e) => {
    if (!e) return null
    // format email to be ke*****@gm***.com
    const str = e.split('@')
    const end = str[1].split('.')
    return `${str[0].slice(0, 2)}******@${end[0].slice(0, 2)}****.${end[1]}`
  }
  const hidePhone = (p) => {
    if (!p) return null
    // format phone to be ***-***-**55
    return `***-***-**${p.slice(8, 10)}`
    // Disable phone by returning null
    //return null
  }
  const handleSubmit = () => {
    setError()
    let err
    switch (value) {
      case 'email':
        if (emailRef.current.value.trim().toLowerCase() === data.email.toLowerCase()) {
          setStatus('send')
        } else {
          err = 'Email does not match'
        }
        break
      case 'text':
        if (phone === data.phone) {
          setStatus('send')
        } else {
          err = 'Phone number does not match'
        }
        break
      case 'none':
        setOpen(true)
        break
      default:
        break
    }

    if (err) {
      setError(err)
    }
  }

  const displayEmail = hideEmail(data.email)
  const displayPhone = hidePhone(data.phone)
  return (
    <Container maxWidth="sm">
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Select Verification Method</FormLabel>
        <RadioGroup
          aria-label="verification method"
          name="method"
          value={value}
          onChange={handleChange}
          className={classes.radioGroup}
          align="left"
        >
          <FormControlLabel
            disabled={!displayEmail}
            value="email"
            control={<Radio />}
            label={displayEmail ? `Email ${displayEmail}` : 'Email option not available'}
          />
          {value === 'email' && (
            <TextField
              id="email"
              variant="outlined"
              placeholder="Confirm email address"
              inputRef={emailRef}
              className={classes.textField}
            />
          )}
          <FormControlLabel
            disabled={!displayPhone}
            value="text"
            control={<Radio />}
            label={displayPhone ? `Text ${displayPhone}` : 'Text option not available'}
          />
          {value === 'text' && (
            <TextField
              id="phone"
              variant="outlined"
              placeholder="Confirm phone number"
              value={phone}
              onChange={onHandleChangeNumeric}
              className={classes.textField}
            />
          )}
          <FormControlLabel
            value="none"
            control={<Radio />}
            label="I no longer have access to those verification methods"
          />
        </RadioGroup>
        {value === 'text' && (
          <Typography variant="caption">
            Note: by selecting text, you will be sent a text message and may be charged by your carrier for any messages
            sent or received.
          </Typography>
        )}
        <div>
          <Button className={classes.button} color="secondary" variant="contained" onClick={onGoBack}>
            GOBACK
          </Button>
          <Button className={classes.button} color="secondary" variant="contained" onClick={handleSubmit}>
            SUBMIT
          </Button>
        </div>
        {error && (
          <Typography variant="body1" color="error">
            * {error}
          </Typography>
        )}
      </FormControl>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Alternate Registration Verification</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
          Please email us at <a href="mailto:info@litpath.org?subject=Alternate Registration Verification">info@litpath.org</a> with your name, updated email address, and telephone number to link to your account to complete the registration process. You may also click the “Go To Contact Us Page” below and submit the information using request type “General Question”.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button component={RouterLink} to="/contact">
            Go to Contact Us Page
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
