import React from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ChangeImage from '../../assets/img/christina-wocintechchat-com-0Nfqp0WiJqc-unsplash.jpg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '80px',
    padding: '80px 112px',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px',
      gap: '24px',
    },
  },
  lowercaseTitle: {
    color: '#1B3666',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.6px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },

  changeContainer: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '90px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  changeTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '8px',
    flexShrink: 0,
    width: '50%',
    gap: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: '16px',
      textAlign: 'left',
      alignItems: 'center',
    },
  },
  changeText: {
    color: '#000',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '150%',
    fontFamily: 'Inter',
    letterSpacing: '0.18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  changeImage: {
    width: '40%',
    height: '400px',
    objectFit: 'cover',
    flexShrink: 0,
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 11px 24px 0px rgba(0, 0, 0, 0.10), 0px 45px 45px 0px rgba(0, 0, 0, 0.09), 0px 100px 60px 0px rgba(0, 0, 0, 0.05), 0px 178px 71px 0px rgba(0, 0, 0, 0.01), 0px 278px 78px 0px rgba(0, 0, 0, 0.00)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '300px',
      maxWidth: '400px',
    },
  },
  contactButton: {
    display: 'flex',
    height: '50px',
    padding: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '#1B3666',
    fontSize: '18px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontWeight: 700,
    textTransform: 'capitalize',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: '#132648',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
}))

const ParticipateInChange = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <div className={classes.container}>
      <Typography className={classes.lowercaseTitle} component="h2">
        Participate in the Path to Change
      </Typography>
      {isMobile && (
        <img
          src={ChangeImage}
          className={classes.changeImage}
          alt="a group of people sitting around a table with laptops"
        />
      )}
      <div className={classes.changeContainer}>
        <div className={classes.changeTextContainer}>
          <Typography className={classes.changeText} component="p">
            We welcome individuals from diverse backgrounds and perspectives who are eager to use their skills,
            knowledge, networks, and passion to make a profound impact on those struggling with compulsive behavior(s).
          </Typography>
          <Typography className={classes.changeText} component="p">
            At Lit Path, we believe in the power of inclusivity and encourage everyone to support the journey of
            recovery. If you are driven to make a difference and want to contribute to our mission, we invite you to ask
            about joining our leadership team. Reach out to us to explore how you can play a vital role in lighting the
            path to recovery for all individuals.
          </Typography>
          <a href="/contact" style={{ textDecoration: 'none' }}>
            <button className={classes.contactButton}>Contact Us</button>
          </a>
        </div>
        {!isMobile && <img src={ChangeImage} className={classes.changeImage} />}
      </div>
    </div>
  )
}

export default ParticipateInChange
