/* eslint-disable complexity */
/* eslint-disable no-negated-condition */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button, Container, IconButton, ListItem, ListItemText } from '@material-ui/core'
import { Add as AddIcon, Clear as ClearIcon } from '@material-ui/icons'
import { updateProfile } from '../../utils/therapist-api'
import { mapToTitlesArray, getPhoneNumberByType } from '../../utils/helper'
import {
  BusinessLocationForm,
  ClientAgeWithOtherOption,
  SessionFeeForm,
  AcceptedInsuranceWithOtherOption,
  PaymentMethodWithOtherOption,
  BusinessHoursForm,
  CheckRemoteSesssion,
  CheckSlidingScale,
} from './UpdateBusinessForms'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  topSpacing: {
    marginTop: theme.spacing(3),
    marginRight: 0,
  },
  red: {
    color: 'red',
  },
  addIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.dark,
    height: '25px',
    borderRadius: '50px',
  },
}))

const convertUserToValues = (user) => {
  const hours = user.WorkLocation.hours
  return {
    businessEmail: user.WorkLocation.email || '',
    businessName: user.WorkLocation.organizationName || '',
    address1: user.WorkLocation.streetAddress1 || '',
    address2: user.WorkLocation.streetAddress2 || '',
    city: user.WorkLocation.city || '',
    state: user.WorkLocation.province || '',
    zip: user.WorkLocation.postalCode || '',
    sessionFeeMin: user.Person.SessionOption?.sessionFeeMin || '',
    sessionFeeMax: user.Person.SessionOption?.sessionFeeMax || '',
    slidingScale: user.Person.SessionOption?.slidingScale ? 'yes' : 'no' || 'no',
    remoteSession: user.Person.SessionOption?.remoteSession ? 'yes' : 'no' || 'no',
    phoneNumber: getPhoneNumberByType(user.Person.Phones, 'Business Phone') || '',
    faxNumber: getPhoneNumberByType(user.Person.Phones, 'Business Fax') || '',
    focusAge: mapToTitlesArray(user.Person.FocusAges) || [],
    insurance: mapToTitlesArray(user.Person.Insurances) || [],
    paymentMethod: user.Person.SessionOption?.paymentMethod || [],
    hours: {
      monday: [hours?.monday[0] || '', hours?.monday[1] || ''],
      tuesday: [hours?.tuesday[0] || '', hours?.tuesday[1] || ''],
      wednesday: [hours?.wednesday[0] || '', hours?.wednesday[1] || ''],
      thursday: [hours?.thursday[0] || '', hours?.thursday[1] || ''],
      friday: [hours?.friday[0] || '', hours?.friday[1] || ''],
      saturday: [hours?.saturday[0] || '', hours?.saturday[1] || ''],
      sunday: [hours?.sunday[0] || '', hours?.sunday[1] || ''],
    },
  }
}
export default function BusinessContactInfo({ user, reloadUser,nextTab }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [value, setValue] = useState(convertUserToValues(user))
  const [status, setStatus] = useState('idle')
  const [focusAgeHidden, setFocusAgeHidden] = useState(true)
  const [paymentHidden, setPaymentHidden] = useState(true)
  const [insuranceHidden, setInsuranceHidden] = useState(true)
  const [sessionOptionsHidden, setSessionOptionsHidden] = useState(true)

  useEffect(() => {
    let canceled = false
    if (status === 'save') {
      const data = value

      data.sessionOption = {
        remoteSession: value.remoteSession === 'yes',
        sessionFeeMin:
          typeof value.sessionFeeMin === 'string' && value.sessionFeeMin.trim() === '' ? null : value.sessionFeeMin, // db expects integer so empty string will error. set to null
        sessionFeeMax:
          typeof value.sessionFeeMax === 'string' && value.sessionFeeMax.trim() === '' ? null : value.sessionFeeMax,
        slidingScale: value.slidingScale === 'yes',
        paymentMethod: value.paymentMethod,
      }
      updateProfile(user.id, data, 'businessContact')
        .then(() => {
          enqueueSnackbar('Update successful', { variant: 'success' })
          if (canceled) return
          setStatus('success')
          reloadUser()
          nextTab(2)
          window.scrollTo(0, 0)
        })
        .catch(() => {
          enqueueSnackbar('Error updating profile', { variant: 'error' })
          if (canceled) return
          setStatus('failure')
        })
    }

    return () => (canceled = true)
  }, [enqueueSnackbar, reloadUser, status, user.id, value])

  const handleInputChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }

  const handleClose = () => {
    setFocusAgeHidden(true)
    setInsuranceHidden(true)
    setPaymentHidden(true)
  }
  const getContent = (contentType) => {
    if (!paymentHidden && contentType === 'paymentMethod') {
      return <PaymentMethodWithOtherOption value={value.paymentMethod} handleInputChange={handleInputChange} />
    }
    if (!focusAgeHidden && contentType === 'age') {
      return <ClientAgeWithOtherOption value={value.focusAge} handleInputChange={handleInputChange} />
    }
    if (!insuranceHidden && contentType === 'insurance') {
      return <AcceptedInsuranceWithOtherOption value={value.insurance} handleInputChange={handleInputChange} />
    }
    return null
  }

  function displayPaymentMethod() {
    if (paymentHidden) {
      setPaymentHidden(false)
    } else {
      handleClose()
    }
  }
  function displayFocusAge() {
    if (focusAgeHidden) {
      setFocusAgeHidden(false)
    } else {
      handleClose()
    }
  }
  function displayInsurance() {
    if (insuranceHidden) {
      setInsuranceHidden(false)
    } else {
      handleClose()
    }
  }
  const handleDeleteItem = (key, item) => {
    const index = value[key].indexOf(item)
    const newValue = value[key].filter((_, i) => i !== index)
    setValue({ ...value, [key]: newValue })
  }

  const handleHoursInputChange = (event) => {
    const name = event.target.name.split('-')
    const day = name[0]
    const index = name[1]
    const newHours = value.hours[day].slice()
    newHours.splice(index, 1, event.target.value)
    const hours = { ...value.hours, [day]: newHours }
    setValue({ ...value, hours })
  }

  return (
    <>
      <Typography variant="h3" align="left" gutterBottom>
        Business Information
      </Typography>
      <Container maxWidth="sm" className={classes.container}>
        <Grid container alignItems="flex-start" justify="space-evenly" spacing={3}>
          <Grid item xs={12}>
            <BusinessLocationForm value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12}>
            <BusinessHoursForm value={value} handleInputChange={handleHoursInputChange} />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={8} className={classes.topSpacing}>
            <Typography variant="h6" align="left" gutterBottom>
              Client Focus Age
              <Button id="focusAgeBtn" onClick={displayFocusAge}>
                <AddIcon className={classes.addIcon} />
              </Button>
            </Typography>

            {getContent('age')}
            {value.focusAge.map((focusAge) => (
              <ListItem key={focusAge} value={focusAge}>
                <ListItemText primary={focusAge} />
                <IconButton size="small" className={classes.red} onClick={() => handleDeleteItem('focusAge', focusAge)}>
                  <ClearIcon />
                </IconButton>
              </ListItem>
            ))}
          </Grid>
          <Grid item xs={12} sm={8} className={classes.topSpacing}>
            <Typography variant="h6" align="left" gutterBottom>
              Accepted Insurances
              <Button id="insuranceBtn" onClick={displayInsurance}>
                <AddIcon className={classes.addIcon} />
              </Button>
            </Typography>

            {getContent('insurance')}
            {value.insurance.map((insurance) => (
              <ListItem key={insurance} value={insurance}>
                <ListItemText primary={insurance} />
                <IconButton
                  size="small"
                  className={classes.red}
                  onClick={() => handleDeleteItem('insurance', insurance)}
                >
                  <ClearIcon />
                </IconButton>
              </ListItem>
            ))}
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.topSpacing}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" align="left">
              Session Options
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <SessionFeeForm value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckRemoteSesssion value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CheckSlidingScale value={value} handleInputChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={8} className={classes.topSpacing}>
            <Typography variant="h6" align="left" gutterBottom>
              Payment Methods Accepted
              <Button id="paymentBtn" onClick={displayPaymentMethod}>
                <AddIcon className={classes.addIcon} />
              </Button>
            </Typography>

            {getContent('paymentMethod')}
            {value.paymentMethod.map((payment) => (
              <ListItem key={payment} value={payment}>
                <ListItemText primary={payment} />
                <IconButton
                  size="small"
                  className={classes.red}
                  onClick={() => handleDeleteItem('paymentMethod', payment)}
                >
                  <ClearIcon />
                </IconButton>
              </ListItem>
            ))}
          </Grid>
        </Grid>
      </Container>

      <div className={classes.btnContainer}>
        <Button
          disabled={status === 'save'}
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => setStatus('save')}
        >
          {status === 'save' ? 'Saving...' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}
