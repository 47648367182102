import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  bodCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
    width: '260px',
    padding: '32px',
    borderRadius: '6px',
    background: '#FFF',
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 38px 15px 0px rgba(0, 0, 0, 0.01), 0px 59px 16px 0px rgba(0, 0, 0, 0.00)',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      padding: '16px',
    },
  },
  bodCardImage: {
    width: '120px',
    height: '120px',
    objectFit: 'cover',
    borderRadius: '50%',
    border: '4px solid #FFF',
  },
  bodCardName: {
    color: '#000',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '132%',
    letterSpacing: '-0.312px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  bodCardText: {
    color: '#000',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
}))

const PersonCard = ({ image, name, text }) => {
  const classes = useStyles()
  return (
    <div className={classes.bodCard}>
      <img src={image} className={classes.bodCardImage} alt={name} />
      <Typography className={classes.bodCardName}>{name}</Typography>
      <Typography className={classes.bodCardText}>{text}</Typography>
    </div>
  )
}

export default PersonCard
