/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import Filter from 'bad-words'
import { useSnackbar } from 'notistack'
import validator from 'validator'
import { navigate, useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, TextField, ListItem, Typography, Dialog, Link } from '@material-ui/core'
import MainLayout from '../layouts/MainLayout'
import ReviewFormRadioButtons from '../components/ReviewFormRadioButtons'
import SetStarRating from '../components/SetStarRating'
import TherapistNameTag from '../components/TherapistNameTag'
import TherapistRating from '../components/TherapistRating'
import TherapistAvatar from '../components/TherapistAvatar'
import LoginForm from '../components/LoginForm'
import FormErrors from '../components/FormErrors'
import { formatName, formatLocation, calcAverageRating, mapToTitlesArray } from '../utils/helper'
import { useUser } from '../context/user-context'
import { getPublicLicensedTherapist, postReview } from '../utils/client-api'
import SimpleDialogSpan from '../components/SimpleDialogSpan'
import Privacy from './Privacy'
import Terms from './Terms'

const filter = new Filter()

const useStyles = makeStyles((theme) => ({
  greyText: {
    color: theme.palette.grey[600],
  },
  margin: {
    margin: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
    background: `rgba(255,255,255,0.8)`,
    borderRadius: 5,
    padding: theme.spacing(4),
  },
  rating: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    border: '2px solid grey',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  link: {
    color: 'blue',
  },
  caption: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
}))

export default function WriteReview({ therapistId }) {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const location = useLocation()

  const { user } = useUser()
  const initialInput = () =>
    JSON.parse(window.localStorage.getItem('tr-review-form-data')) || {
      rating: '5',
      comment: '',
      age: '1',
      gender: 'f',
      displayName: 'yes',
      userType: 'client',
    }
  const [data, setData] = useState()
  const [open, setOpen] = useState(user ? false : true)
  const [input, setInput] = useState(initialInput)
  const [status, setStatus] = useState('idle')
  const [formErrors, setFormErrors] = useState([])
  const [reviewerIds, setReviewerIds] = useState([])
  const [loadingTherapist, setLoadingTherapist] = useState(true)

  useEffect(() => {
    let canceled = false
    if (status === 'post') {
      // format age to string representation
      let age
      switch (parseInt(input.age, 10)) {
        case 1:
          age = '< 25'
          break
        case 2:
          age = '25 - 45'
          break
        case 3:
          age = '45 +'
          break

        default:
          break
      }

      if (input.comment) {
        const urls = input.comment.split(' ').filter((word) => validator.isURL(word))
        if (urls.length > 0) {
          setFormErrors(['Recommendation cannot contain urls or other website links'])
          setStatus('idle')
          return
        }

        if (filter.isProfane(input.comment)) {
          setFormErrors(['Recommendation cannot contain curse words'])
          setStatus('idle')
          return
        }
        // Determine which part of the user is the one that is stored in the Review model as
        //  reviewerId
        let tmpReviewerId = null
        // If user.Person.id exists then use it (user.id in this case is licensedTherapist.id and
        //  not what is stored as the Review -> reviewerId). However, if there is just a user.id then
        //  use that since it is what will be stored as the Review -> reviewerId. This area should
        //  be restricted to logged in users only, but just in case someone makes it past the login,
        //  tmpReviewerId remains null and we through the error
        if (user?.Person?.id) {
          tmpReviewerId = user.Person.id
        } else if (user?.id) {
          tmpReviewerId = user.id
        }

        if (tmpReviewerId === null) {
          // Not logged in so go away :(
          setFormErrors(['This feature is restricted to logged in users only.'])
          setStatus('idle')
          return
        } else {
          if (reviewerIds.includes(tmpReviewerId)) {
            // Already left a review for this therapist so go away :(
            setFormErrors(['You have already left a ecommendation for this Therapist'])
            setStatus('idle')
            return
          }
          // Otherwise -- good to continue with a new review :)
        }
        const name = user.Person
          ? `${user.Person.firstName} ${user.Person.lastName}`
          : `${user.firstName} ${user.lastName}`

        // Call API endpoint to post review
        postReview({ ...input, age, name, therapistId })
          .then(() => {
            enqueueSnackbar('Recommendation added', { variant: 'success' })
            if (canceled) return
            setStatus('success')
          })
          .catch(() => {
            enqueueSnackbar('Error submitting review', { variant: 'error' })
            setStatus('error')
          })
      }
      // Clear local storage on success
    } else if (status === 'login') {
      // store form data so it's there after they login or sign in
      window.localStorage.setItem('tr-review-form-data', JSON.stringify(input))
    } else if (status === 'success') {
      window.localStorage.removeItem('tr-review-form-data')
      navigate(`/view/therapist/${therapistId}`)
    }
    // eslint-disable-next-line consistent-return
    return () => (canceled = true)
  }, [enqueueSnackbar, input, status, therapistId, user])

  useEffect(() => {
    async function fetchData() {
      const res = await getPublicLicensedTherapist(therapistId)
      if (res) {
        const obj = {
          id: res.id,
          name: formatName(res.Person.firstName, res.Person.middleInitial, res.Person.lastName),
          profession: res.Person.Professions.length > 0 ? res.Person.Professions[0].title : res.professionName,
          location: formatLocation(res.WorkLocation),
          rating: calcAverageRating(res.Reviews),
          numRatings: res.Reviews.length,
          avatar: res.Person.avatar,
          specialties: mapToTitlesArray(res.Person.Specialties),
        }

        setData(obj)
        setLoadingTherapist(false)
        // Get list of previous reviews (res.Reviews[x].reviewerId
        setReviewerIds(res.Reviews.map((review) => review.reviewerId))
      }
    }
    if (therapistId) {
      fetchData()
    }
  }, [therapistId])

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value })
  }

  // check if user is already logged in. if so, call api endpoint to post review
  // If no user is logged in, prompt user to login before writing review
  const handlePostReview = () => {
    if (user) {
      setStatus('post')
    } else {
      setStatus('login')
      setOpen(true)
    }
  }
  if (!data) {
    return (
      <MainLayout>
        <Typography variant="h5">
          {loadingTherapist ? 'Loading Therapist Information' : 'Therapist Not Found'}
        </Typography>
      </MainLayout>
    )
  }

  if (user && user.id === data.id) {
    return (
      <MainLayout>
        <Typography variant="h5">You are not allowed to leave a recommendation for yourself</Typography>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <Grid container className={classes.container} spacing={3}>
        <Grid container item xs={12} sm={6} md={4} direction="column" alignItems="center">
          <div className={classes.margin} />
          <Grid item>
            <TherapistAvatar src={data.avatar} />
            <TherapistRating value={data.rating} quantity={data.numRatings} className={classes.rating} />
            <Typography align="center" className={classes.greyText} variant="body2">
              Overall Rating
            </Typography>
          </Grid>
          <div className={classes.margin} />
          <Grid item>
            <ListItem>
              <TherapistNameTag
                name={data.name}
                profession={data.profession}
                location={data.location}
                specialties={data.specialties}
              />
            </ListItem>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} md={8}>
          <Grid item xs={12} className={classes.box}>
            {/*<SetStarRating rating={input.rating} handleInputChange={handleInputChange} />*/}
            <TextField
              multiline
              required
              id="comment"
              name="comment"
              value={input.comment}
              onChange={handleInputChange}
              InputProps={{ classes: { underline: classes.underline } }}
              placeholder="Your recommendation helps others learn about great therapists.
                    Please tell how this therapist was helpful to you."
              rows="8"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} align="center" className={classes.caption}>
            <Typography component="div" variant="caption">
              By submitting your recommendation you agree to Lit Path&apos;s{' '}
              <Link className={classes.link}>
                <SimpleDialogSpan title="Terms of Service">
                  <Terms/>
                </SimpleDialogSpan>
              </Link>{' '}
              and acknowledge Lit Path&apos;s{' '}
              <Link className={classes.link}>
                <SimpleDialogSpan title="Privacy Policy">
                  <Privacy/>
                </SimpleDialogSpan>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReviewFormRadioButtons age={input.age} gender={input.gender} displayName={input.displayName} userType={input.userType} handleInputChange={handleInputChange} />
            <FormErrors errors={formErrors} />
            <div className={classes.buttons}>
              <Button className={classes.button} color="primary" variant="contained" onClick={handlePostReview}>
                Post Recommendation
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <LoginForm
          title="Sign in to post your review"
          handleClose={() => {
            setOpen(false)
          }}
          display="reviewer"
          successRedirect={location.pathname}
        />
      </Dialog>
    </MainLayout>
  )
}
