import React from 'react'
import { Box, Button, Typography, Link, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from '@reach/router'
import { Share, Group } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  moreHelpSection: {
    padding: '70px 0px',
    backgroundColor: '#495E85',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    gap: '56px',
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: '0px 40px',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    width: '40%',
  },
  header: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.015em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      padding: '0px 16px',
    },
  },
  subheader: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '130%',
    letterSpacing: '-0.013em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      padding: '0px 16px',
    },
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px',
    },
  },
  button: {
    borderRadius: '100px',
    background: 'white',
    color: '#1B3666',
    padding: '16px 32px',
    height: '59px',
    width: '265px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      padding: '12px 24px',
    },
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      gap: '32px',
      padding: '0px',
    },
  },
}))

export default function MoreHelpSection() {
  const classes = useStyles()
  return (
    <Box className={classes.moreHelpSection}>
      <Box className={clsx(classes.contentContainer, classes.mobileContainer)}>
        <Typography component="h2" className={classes.header}>
          More Ways to Help
        </Typography>
        <div className={clsx(classes.gridContainer, classes.mobileContainer)}>
          <div className={clsx(classes.gridItem, classes.mobileContainer)}>
            <Group className={classes.icon} />
            <Typography component="h5" className={classes.subheader}>
              Register As a Therapist
            </Typography>
            <Typography className={classes.body}>
              Join us in making a significant impact on the lives of those struggling with compulsive behavior(s).
            </Typography>
          </div>
          <div className={clsx(classes.gridItem, classes.mobileContainer)}>
            <Share className={classes.icon} />
            <Typography component="h5" className={classes.subheader}>
              Share Us on Social Media
            </Typography>
            <Typography className={classes.body}>
              Let’s find hope for more lives! Together, we can make a difference!
            </Typography>
          </div>
        </div>
        <Link component={RouterLink} to="/more-resources">
          <Button className={classes.button}>See More Resources</Button>
        </Link>
      </Box>
    </Box>
  )
}
