import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import App from './App'
import AppProviders from './context'
import theme from './theme'
const sitemap = require('../public/sitemap.xml')
const robots = require('../public/sitemap.xml')

// add action to all snackbars
const notistackRef = React.createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        preventDuplicate
        hideIconVariant
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
      >
        <AppProviders>
          <App />
        </AppProviders>
      </SnackbarProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root'),
)
