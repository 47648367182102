import { call } from './client-api'

export function uploadAvatar(id, file) {
  return call(`/api/v1/person/${id}/avatar`, {
    body: file,
    headers: { 'content-type': 'multipart/form-data' },
  })
}
export function getProfile(id) {
  return call(`/api/v1/therapist/${id}/profile`)
}
export function updateProfile(id, body, page) {
  return call(`/api/v1/therapist/${id}/profile?page=${page}`, {
    body,
    method: 'PUT',
  })
}
export function updateBio(id, body) {
  return call(`/api/v1/person/${id}/bio`, { body, method: 'PUT' })
}
export function addOption(data) {
  return call(`/api/v1/options`, { body: data })
}

export function addReviewResponse(data) {
  return call(`/api/v1/therapist/review`, { body: data, method: 'PUT' })
}
