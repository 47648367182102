import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import TherapistName from './TherapistName'
import TherapistProfession from './TherapistProfession'
import TherapistLocation from './TherapistLocation'
import TherapistSpecialties from './TherapistSpecialties'
import TherapistRating from './TherapistRating'
import TherapistDistance from './TherapistDistance'

const useStyles = makeStyles(theme => ({
  rating: {
    justifyContent: 'center',
  },
}))

export default function TherapistNameTag({
  name, // string
  profession, // string
  location, // string
  specialties, // array of strings
  rating, // object with value, quantity
  distance,
  center,
}) {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid item>
        <TherapistName name={name} />
      </Grid>
      <Grid item>
        <TherapistSpecialties specialties={specialties} />
      </Grid>
      {rating && (
        <Grid item container alignItems="center">
          <TherapistRating
            value={rating.value}
            quantity={rating.quantity}
            className={center && classes.rating}
          />
          <TherapistDistance distance={distance} />
        </Grid>
      )}
      <Grid item>
        <TherapistProfession profession={profession} />
      </Grid>
      <Grid item>
        <TherapistLocation location={location} />
      </Grid>
    </Grid>
  )
}
