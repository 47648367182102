import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Link,
} from '@material-ui/core'
import { Visibility, VisibilityOff, VpnKeyOutlined as KeyOutlinedIcon } from '@material-ui/icons'
import queryString from 'query-string'
import jwt from 'jsonwebtoken'
import { updatePassword } from '../utils/auth-api'
import Copyright from '../components/Copyright'
import MainLayout from '../layouts/MainLayout'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    backgroundColor: theme.palette.common.white,
    textAlign: 'left',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helperText: {
    marginLeft: 12,
  },
}))

// eslint-disable-next-line max-lines-per-function
export default function UpdatePassword({ location, successRedirect = '/login/therapist' }) {
  const { search } = location
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [renderExpired, setRenderExpired] = useState(false)
  const [decoded, setDecoded] = useState()
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    // Check if Access token has expired
    function isExpired(tokenExp) {
      const now = Date.now() / 1000
      return tokenExp < now
    }
    if (search) {
      const q = queryString.parse(search)
      const d = jwt.decode(q.token)

      setDecoded(d)

      if (isExpired(d.exp)) {
        setRenderExpired(true)
      }
    }
  }, [search])

  const getToken = () => {
    const q = queryString.parse(search)
    return q.token
  }

  const handleClickShow = (type) => {
    if (type === 'password') {
      setShowPassword(!showPassword)
    } else {
      setShowConfirm(!showConfirm)
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  function passwordIsValid() {
    return password.length >= 8
  }

  const passwordsMatch = () => {
    return password === confirm
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (!decoded) {
      // eslint-disable-next-line no-alert
      return alert('Missing the token. Make sure to click the link provided in your email.')
    }
    setError('')
    if (!passwordIsValid()) {
      setError("The password you entered doesn't meet the requirements.")
      // eslint-disable-next-line no-negated-condition
    } else if (!passwordsMatch()) {
      setError("The passwords you entered don't match.")
    } else {
      // submit data
      const data = {
        password,
        token: getToken(),
      }
      updatePassword(decoded.id, data)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          enqueueSnackbar('Password updated', { variant: 'success' })
          setRedirect(true)
        })
        .catch(() => {
          enqueueSnackbar('Error updating password', { variant: 'error' })
        })
    }
  }

  function ExpiredMessage() {
    return (
      <Box mt={5}>
        <Typography>
          Email link has expired! Go to <Link href="/forgotpassword">Forgot Password</Link> to request another.
        </Typography>
      </Box>
    )
  }

  if (redirect) {
    navigate(successRedirect)
  }

  return (
    <MainLayout>
      <Container component="main" maxWidth="xs" className={classes.root}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <KeyOutlinedIcon color="primary" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter New Password
          </Typography>
          {renderExpired ? (
            <ExpiredMessage />
          ) : (
            <Box>
              <Typography align="center" variant="body1">
                Your new password must have at least 8 characters.
              </Typography>

              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  error={error.length > 0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShow('password')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="confirm"
                  label="Confirm Password"
                  type={showConfirm ? 'text' : 'password'}
                  id="confirm"
                  autoComplete="new-password"
                  onChange={(e) => setConfirm(e.target.value)}
                  error={error.length > 0}
                  helperText={error.length > 0 ? error : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShow('confirm')}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Update Password
                </Button>
              </form>
              <Grid container>
                <Grid item xs>
                  <Link to="/login/therapist" variant="body2">
                    Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </MainLayout>
  )
}
