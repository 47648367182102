import React from 'react'
import validator from 'validator'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Grid, MenuItem, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useNavigate } from '@reach/router'
import { sendMessage } from '../utils/client-api'
import FormErrors from './FormErrors'

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: 'center',
  },
  submitBtn: {
    marginTop: theme.spacing(3),
  },
}))

export default function MessageForm({ messageInfo }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [formErrors, setFormErrors] = React.useState([])

  const handleSubmit = (e) => {
    e.preventDefault()

    const body = {
      therapistId: messageInfo.id,
      toName: messageInfo.toName,
      anotherOne: 'yes please',
      toEmail: messageInfo.toEmail,
      fromName: e.target.name.value.trim(),
      fromEmail: e.target.email.value.trim(),
      subject: e.target.subject.value.trim(),
      message: e.target.message.value,
    }

    // Validate input
    const errors = []
    if (validator.isEmpty(body.fromName)) {
      errors.push('Name is required')
    }
    if (!validator.isEmail(body.fromEmail)) {
      errors.push('Email address is not valid')
    }
    if (validator.isEmpty(body.subject)) {
      errors.push('Subject is required')
    }
    if (validator.isEmpty(body.message)) {
      errors.push('A message is required')
    }

    // set errors
    setFormErrors(errors)
    // don't submit form if errors
    if (errors.length > 0) return

    sendMessage(body)
      .then(() => {
        enqueueSnackbar('Request sent', { variant: 'success' })
        navigate('/')
      })
      .catch(() => {
        enqueueSnackbar('Request failed', { variant: 'error' })
      })
  }
  return (
    <form className={classes.main} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField required variant="outlined" id="name" name="name" label="Name" fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField required variant="outlined" id="email" name="email" label="Email Address" fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField required id="subject" name="subject" variant="outlined" label="Subject" fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            variant="outlined"
            id="message"
            name="message"
            label="Message"
            multiline
            rows="6"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormErrors errors={formErrors} />
        </Grid>
      </Grid>
      <Button type="submit" className={classes.submitBtn} variant="contained" size="large" color="secondary">
        Send it!
      </Button>
    </form>
  )
}
