import React, {useRef, useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import  TimelineComponent from './TimelineComponent'

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '1110px',
    height: '2288px',
    position: 'relative',
    margin: 'auto',
    overflow: 'clip',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '2480px',
    },
  },
  mainBranch: {
    width: '2px',
    background: '#1B3666',
    height: '100%',
    zIndex: 1,
    marginLeft: 'calc(50% - 1px)',
    position: 'absolute',
    marginTop: (props) => `${props.marginTop}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileBranch: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '1px',
      background: '#1B3666',
      height: '100%',
      zIndex: 1,
      marginLeft: 'calc(50% - 0.5px)',
      position: 'absolute',
      marginTop: '0px',
    },
  },
  textbox: {
    width: '50%',
    borderRadius: '10px',
    border: '2px solid #D49F02',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '26px 44px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px',
      border: '1px solid #D49F02',
      marginBottom: '40px',
      maxWidth: '312px',
    },
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.005em',
    textAlign: 'center',
    color: '#1B3666',
  },
  firstStep: {
    width: '100%',
    marginTop: '0px',
  },
}))

const FirstStep = React.forwardRef(({ className }, ref) => (
  <div className={className} ref={ref}>
    <TimelineComponent
      number="1"
      header="Review and Submit"
      description="Review the participation requirements above and submit your application through our website. Our team will carefully review your application."
      side="left"
    />
  </div>
))

FirstStep.displayName = 'FirstStep'

export default function ProcessTimeline() {
    const firstStepRef = useRef(null)
    const [marginTop, setMarginTop] = useState(0)

    const handleResize = () => {
      if (firstStepRef.current) {
        const firstStepHeight = firstStepRef.current.offsetHeight / 2
        setMarginTop(firstStepHeight)
      }
    }

    useEffect(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    const classes = useStyles({ marginTop })

  return (
    <div className={classes.timelineContainer}>
      <div className={classes.mainBranch} />
      <div className={classes.mobileBranch} />
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 2,
          justifyContent: 'space-between',
        }}
      >
        <FirstStep className={classes.firstStep} ref={firstStepRef} />
        <TimelineComponent
          number="2"
          header="Submit Documentation"
          description="After the preliminary review, we will request supporting documentation, such as proof of completion of an intensive/inpatient program or a recommendation letter from your therapist, as well as 2 months of bank statements."
          side="right"
        />
        <TimelineComponent
          number="3"
          header="Complete Document"
          description="Complete and return the Recovery Capital Scale document."
          side="left"
        />
        <div className={classes.textbox}>
          <Typography className={classes.text}>
            Our team will thoroughly review your supporting documents and reach out to you if there are any questions.
            <br />
            You will receive a decision on your application, along with subsequent communication from us.
          </Typography>
        </div>
        <TimelineComponent
          number="4"
          header="Choose a Therapist"
          description="Choose a therapist from our website and notify us of your selection."
          side="left"
        />
        <div className={classes.textbox}>
          <Typography className={classes.text}>
            Our team will inform you and your chosen therapist about the scholarship approval and requirements.
          </Typography>
        </div>
        <TimelineComponent
          number="5"
          header="Attend 16 Sessions"
          description="Attend 16 sessions with the therapist you selected from our website. The therapist will invoice Lit Path either after each session or on a monthly basis."
          side="right"
        />
        <TimelineComponent
          number="6"
          header="Complete Document"
          description="Upon completion of the 16-session scholarship, complete and return the Recovery Capital Scale document."
          side="left"
        />
        <div className={classes.textbox} style={{ marginBottom: '0px' }}>
          <Typography className={classes.text}>
            Lit Path will remit payment to the therapist, deducting the amount for each session from the scholarship
            total.
          </Typography>
        </div>
      </div>
    </div>
  )
}

