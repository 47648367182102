import React from 'react'
import { useAsync } from 'react-async'
import { bootstrapAppData } from '../utils/bootstrap'
import * as authAPI from '../utils/auth-api'
import Loading from '../components/Loading'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
  // Pre-load the user's information if we have their token code
  const { data = { user: null }, error, isRejected, isPending, isSettled, reload } = useAsync({
    promiseFn: bootstrapAppData,
  })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
  }, [isSettled])

  if (!firstAttemptFinished) {
    if (isPending) {
      return <Loading />
    }
    if (isRejected) {
      return (
        <div css={{ color: 'red' }}>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  // Authentication API calls that updates user value in state after complete
  const login = form => authAPI.login(form).then(reload)
  const register = form => authAPI.register(form).then(reload)
  const logout = () => authAPI.logout().then(reload)
  const checkToken = token => authAPI.checkToken(token).then(reload)

  return (
    <AuthContext.Provider
      value={{
        data: { uid: data.id, user: data.user, loading: isPending },
        login,
        logout,
        register,
        checkToken,
      }}
      {...props}
    />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
