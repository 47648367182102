import { Box, Button, Menu, Slider, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ArrowDropUp } from '@material-ui/icons';

const secondary500 ="#D49F02";
const primary = "#1B3666";

const useStyles = makeStyles(theme => ({
    distance: {
        width: "100%",
        maxWidth: 600,
        height: 39,
        fontSize: 16,
        textAlign: "left",
        overflow: "hidden",
        paddingTop: 10,
        paddingBottom: 10,
        outline: 0,
        textTransform: "none",
        border: "1px solid black",
        borderRadius: 24,
        "&.MuiButton-outlined": {
            paddingRight: 8,
        },
        "& .MuiButton-label": {
            fontWeight: 400,
            display: "flex",
            justifyContent: "space-between",
        },
        "&:hover": {
            backgroundColor: "white",
            borderWidth: 2,
        },
        "&.active": {
            backgroundColor: primary,
            color: "white",
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 150,
            maxWidth: 200,
        },
    },
    menu: {
        borderRadius: 10,
        overflowY: "auto",
        marginTop: 120,
    },
    distanceSlider: {
        color: secondary500,
        height: 5,
        width: 268,
        '& .MuiSlider-rail': {
            color: "#EDEEF1",
        },
        alignSelf: "center"
    },
    dropdownIcon: {
        color: '#757575',
        "&.active": {
            color: "white",
        }
    }
}))

export default function DistanceFilter({value, setValue, filterLocation}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
    }
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <div>
            <Button
                id="distance-filter-button"
                aria-controls={open ? 'distance-filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant='outlined'
                className={filterLocation && value != 50 ? `${classes.distance} active` :classes.distance}
                disableRipple
            >
                Distance
                {anchorEl ? <ArrowDropUp className={`${classes.dropdownIcon} ${filterLocation && value != 50 ? "active" : ""}`}/>
                          : <ArrowDropDownIcon className={`${classes.dropdownIcon} ${filterLocation && value != 50 ? "active" : ""}`}/>}
            </Button>
            <Menu
                className={classes.menu}
                id="distance-filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'distance-filter-button',
                }}
                MenuProps
            >
                <Box sx={{width: "min(100%, 410px)", padding: 24, display: "flex", flexDirection: "column", gap: 16}}>
                    <Typography style={{fontSize: 16}}>See therapists based on your current location</Typography>
                    <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                        <Typography style={{fontSize: 16}}>Within {value} miles</Typography>
                        <Typography style={{fontSize: 16}}>From {filterLocation.fields.zip}</Typography>
                    </Box>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        aria-label="Temperature"
                        defaultValue={30}
                        step={10}
                        onChange={handleSliderChange}
                        min={10}
                        max={50}
                        className={classes.distanceSlider}
                    />
                </Box>
            </Menu>
        </div>
    )
}
