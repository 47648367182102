import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { useSnackbar } from 'notistack'
import validator from 'validator'
// Material-UI Core components
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Tabs,
  Tab,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  IconButton,
  Icon,
  Box,
  Container,
  Typography,
} from '@material-ui/core'
// Material-UI Icons
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import FacebookIcon from '@material-ui/icons/Facebook'
// Other Icons
import GoogleIcon from '../assets/img/btn_google_light_normal_ios.svg'
// Context
import { useAuth } from '../context/auth-context'
// Custom Components
import FormErrors from './FormErrors'
import SimpleDialogSpan from './SimpleDialogSpan'
import TermsTherapist from '../views/TermsTherapist'
import Terms from '../views/Terms'
import Privacy from '../views/Privacy'

const IS_PROD = process.env.NODE_ENV === 'production'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  socialLine: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  terms: {
    marginTop: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  subtitle: {
    alignSelf: 'flex-start',
  },
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
  },
  facebook: {
    color: '#4267B2',
  },
  twitter: {
    color: '#1DA1F2',
  },
  link: {
    color: 'blue',
  },
  forgot: {
    textAlign: 'right',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`login-tabpanel-${index}`}
      aria-labelledby={`login-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}
function a11yProps(index) {
  return {
    id: `login-tab-${index}`,
    'aria-controls': `login-tabpanel-${index}`,
  }
}
export default function LoginForm({ title, handleClose, successRedirect, display }) {
  const classes = useStyles()
  const { login } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [status, setStatus] = useState('idle')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formErrors, setFormErrors] = useState([])
  const [value, setValue] = React.useState(() => (display === 'therapist' ? 0 : 0))

  useEffect(() => {
    let canceled = false
    if (status === 'success') {
      enqueueSnackbar('Login success', { variant: 'success' })
      if (handleClose) {
        handleClose()
      } else {
        navigate(successRedirect)
      }
      // reloadUser()
    } else if (status === 'error') {
      enqueueSnackbar('Login failed', { variant: 'error' })
    } else if (status === 'submit') {
      // Validate input
      const errors = []
      if (!validator.isEmail(email)) {
        errors.push('Email address is not valid')
      }
      // set errors
      setFormErrors(errors)
      // don't submit form if errors
      if (errors.length > 0) return

      login({ email, password })
        .then(() => {
          if (canceled) return
          setStatus('success')
        })
        .catch(() => {
          if (canceled) return
          setStatus('error')
        })
    }
    // eslint-disable-next-line consistent-return
    return () => (canceled = true)
  }, [status, email, password, login, handleClose, successRedirect, enqueueSnackbar])

  // for selecting the tab panel
  const handleChange = (event, newValue) => {
    //setValue(newValue)
  }

  function handleSubmit(event) {
    event.preventDefault()
    // eslint-disable-next-line no-shadow
    const { email, password } = event.target.elements
    setEmail(email.value.trim())
    setPassword(password.value.trim())
    setStatus('submit')
  }

  const facebookUrl = `${REACT_APP_SERVER_URL}/auth/facebook?state=${successRedirect}`
  const googleUrl = `${REACT_APP_SERVER_URL}/auth/google?state=${successRedirect}`

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="login tabs" centered>
              {/* <Tab label="Users" {...a11yProps(0)} /> */}
              <Tab label="Therapists" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          {/* <TabPanel value={value} index={0}>
            <Typography className={classes.subtitle} variant="h6">
              Login as a user
            </Typography>
            <Typography className={classes.subtitle}>
              Single Sign-On - click on an icon to sign in with that account
            </Typography>

            <div className={classes.socialLine}>
              {[
                {
                  icon: <FacebookIcon className={classes.facebook} fontSize="large" />,
                  url: facebookUrl,
                },
                // {
                //   icon: 'fab fa-twitter',
                //   url: 'http://localhost:3001/auth/twitter',
                // },
                {
                  icon: (
                    <Icon classes={{ root: classes.iconRoot }} fontSize="large">
                      <img className={classes.imageIcon} src={GoogleIcon} />
                    </Icon>
                  ),
                  url: googleUrl,
                },
              ].map((prop, key) => {
                return (
                  <IconButton key={key} href={prop.url}>
                    {prop.icon}
                  </IconButton>
                )
              })}
            </div>
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <Typography className={classes.subtitle} variant="h6">
              Login as a therapist
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid container>
              <Grid item xs className={classes.forgot}>
                <Link href="/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Therapist Sign In
            </Button>
          </TabPanel>
          <Grid container className={classes.terms}>
            <Grid item align="center">
              <Typography variant="caption">
                By clicking &quot;Sign In&quot; or using any Single Sign-On method, you are agreeing to Lit
                Path&#39;s{' '}
                <Link className={classes.link}>
                  <SimpleDialogSpan title="Terms of Service">
                    {value ? <TermsTherapist /> : <Terms />}
                  </SimpleDialogSpan>
                </Link>{' '}
                and acknowledge LitPath&#39;s{' '}
                <Link className={classes.link}>
                  <SimpleDialogSpan title="Privacy Policy">
                    <Privacy />
                  </SimpleDialogSpan>
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
          <br />
          <FormErrors errors={formErrors} />
        </form>
      </div>
    </Container>
  )
}
