/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from '@reach/router'
import { Typography, Link, Button } from '@material-ui/core'
import HelpLayout from '../layouts/HelpLayout'
import JotformEmbed from 'react-jotform-embed'
import becomingAParticipatingTherapist from '../assets/img/jason-goodman-6hDWAajuDv8-unsplash.jpg'
import experienceRegisteringParticipatingTherapist from '../assets/img/pexels-photo-4100661.jpeg'
import helpUsImage from '../assets/img/youssef-naddam-iJ2IG8ckCpA-unsplash.jpg'
import FaqBox from '../components/FaqBox'
import RequirementBox from '../components/ScholarshipPage/RequirementBox'
import TherapistProcess from '../components/TherapistApplication/TherapistProcess'
import TherapistAppMobile from './TherapistAppMobile'
import Headers from '../components/Headers'

const width = '1110px'
const primaryColor = '#1B3666'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heroTitle: {
    color: primaryColor,
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '62px',
    letterSpacing: '-0.015em',
  },
  sectionTitle: {
    color: primaryColor,
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '52px',
    letterSpacing: '-0.015em',
  },
  sectionContent: {
    fontFamily: 'Inter',
    color: '#000000',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  sectionBoldContent: {
    color: primaryColor,
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '-0.013em',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    textAlign: 'left',
    justifyContent: 'center',
    width: '55%',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(8),
    textAlign: 'left',
    maxWidth: width,
  },
  requirementSection: {
    display: 'flex',
    maxWidth: width,
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '40px',
    justifyContent: 'center',
    margin: 'auto',
  },
  splitContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10%',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: width,
    width: '100%',
  },
  centerContainer: {
    display: 'flex',
    marginTop: 'auto',
  },
  section: {
    padding: '80px 200px',
    gap: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '80px 112px',
    },
  },
  button: {
    borderRadius: '100px',
    background: primaryColor,
    color: 'white',
    padding: '16px 32px',
    height: '59px',
    width: '300px',
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
    '&:hover': {
      backgroundColor: '#132648',
    },
  },
  listContainer: {
    paddingInlineStart: '10px',
  },
  benefitsText: {
    color: 'black',
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: 600,
    letterSpacing: '-0.015em',
  },
  heroImg: {
    width: '454px',
    height: '454px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  experienceImg: {
    width: '440px',
    height: '280px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  helpUsImg: {
    width: '400px',
    height: '462px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '6px',
    boxShadow:
      '0px 0px 0px 0px #0000001A,0px 4px 8px 0px #0000001A,0px 14px 14px 0px #00000017,0px 32px 19px 0px #0000000D,0px 56px 23px 0px #00000003,0px 88px 25px 0px #00000000',
  },
  restrictWidth: {
    width: '100%',
    maxWidth: width,
  },
}))

/**
 * @type {import('../components/Faq').FaqItem[]}
 */
const faqItems = [
  {
    number: '01',
    question: 'Can any therapist apply to participate in the scholarship program?',
    answer:
      'No, only therapists who are licensed/registered in the state(s) they practice in and hold an additional certification (CAP, CSAT, CEDS, CGAC, etc) specializing in the compulsive behaviors they treat should apply.',
  },
  {
    number: '02',
    question: 'How often are applications reviewed?',
    answer: 'Normally within 48 hours of submission, however it can take up to a week.',
  },
  {
    number: '03',
    question: 'Do I have to pay anything to participate in the scholarship program?',
    answer:
      'No, we are a 501(c)(3) and funded solely by donations and sponsorships.  It is our honor to have the opportunity to support you by offering these scholarships so your clients can benefit from the awesome work you do.',
  },
  {
    number: '04',
    question: 'If my client applies for the scholarship and is approved, how will I get reimbursed for the sessions?',
    answer:
      'If granted a scholarship, Lit Path will pay up to $65 a session directly to your practice after the completion of each session. Invoicing monthly is preferred as this reduces our costs associated with completing payment. The scholarship recipient is responsible for the remaining cost for each session, and you must collect the remaining balance of each session from them. Lit Path makes payment via credit card or ACH only. You will need to provide ACH direct payment details if that is your preference.',
  },
  {
    number: '05',
    question: 'My client asked me for a recommendation letter for the program. What is needed?',
    answer:
      'One of the requirements for our scholarship program is commitment to recovery.  For evidence of this we request documentation of recent completion of an: || li[intensive program] || li[inpatient program] || li[or a recommendation letter from a therapist] ||br|| ||br|| When your client has not attended an inpatient or intensive program (or soon will), then a letter from you can be submitted instead. The letter should outline: || li[Your name and contact info] || li[The duration of your sessions with them (how long they have been coming to you)] || li[Your understanding of your clients level of engagement in their recovery program] ||  li[Your belief in how our scholarship program can benefit them]',
  },
]

export default function TherapistApplication() {
  const classes = useStyles()

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HelpLayout>
      <Headers
        title="Therapist Application"
        type="website"
        description="Are you a therapist with specialized addiction certification(s)? Register today and begin offering addiction recovery scholarships to your clients for FREE in as little as a few days. Our short application process only takes a few minutes."
      />
      <div className={classes.mainContainer}>
        <div className={classes.section}>
          <div className={classes.splitContainer}>
            <div className={classes.leftColumn}>
              <Typography component="h1" className={classes.heroTitle}>
                Become a Participating Therapist
              </Typography>
              <Typography className={classes.sectionContent} component="p">
                Join us in making a significant impact on the lives of those struggling with compulsive behavior(s).
                Participate today and become a vital part of our nationwide network of compassionate therapists.
                Together, we can make therapy more accessible and provide hope to those in need.
              </Typography>
              <Link component={RouterLink} to="/therapist-form" style={{ marginTop: 'auto' }}>
                <Button className={classes.button}>Register as a Therapist </Button>
              </Link>
            </div>
            <img
              src={becomingAParticipatingTherapist}
              className={classes.heroImg}
              alt="a white coffee table with two yellow chairs in the background"
            />
          </div>
        </div>
        <div className={classes.section} style={{ gap: '64px' }}>
          <div className={classes.splitContainer}>
            <img
              src={experienceRegisteringParticipatingTherapist}
              className={classes.experienceImg}
              alt="a person sitting on a couch talking to a therapist"
            />
            <div className={classes.leftColumn} style={{ justifyContent: 'unset' }}>
              <Typography component="h2" className={classes.sectionTitle}>
                Experience the Advantages
              </Typography>
              <Typography className={classes.sectionContent}>
                By joining our program, you have the opportunity to make a significant impact and support individuals in
                need by alleviating the financial strain of therapy. Take part in our mission to ensure equal access to
                therapeutic services and enable individuals to receive the care they deserve.
              </Typography>
              <Typography component="h3" className={classes.benefitsText}>
                These are the benefits of registering as a participating therapist with Lit Path:
              </Typography>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <div>
              <Typography component="h4" className={classes.sectionBoldContent}>
                Cost-Free Involvement
              </Typography>
              <ul className={classes.listContainer}>
                <li>
                  <Typography className={classes.sectionContent}>
                    Join our program as a participating therapist at no cost to you.
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.sectionContent}>
                    Benefit from the opportunity to contribute to the community without any financial obligations.
                  </Typography>
                </li>
              </ul>
            </div>
            <div>
              <Typography component="h4" className={classes.sectionBoldContent}>
                Empower Your Practice with a Scholarship Program
              </Typography>
              <ul className={classes.listContainer}>
                <li>
                  <Typography className={classes.sectionContent}>
                    Enhance your practice by offering a scholarship program.
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.sectionContent}>
                    Provide valuable financial support to your clients, opening doors to their recovery journey.
                  </Typography>
                </li>
              </ul>
            </div>
            <div>
              <Typography component="h4" className={classes.sectionBoldContent}>
                Align with Our 501(c)(3) Mission
              </Typography>
              <ul className={classes.listContainer}>
                <li>
                  <Typography className={classes.sectionContent}>
                    Associate your practice with Lit Path's 501(c)(3) nonprofit mission.
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.sectionContent}>
                    Join a network of therapists who actively support our cause and make a difference in the lives of
                    individuals struggling with compulsive behavior(s).
                  </Typography>
                </li>
              </ul>
            </div>
            <div>
              <Typography component="h4" className={classes.sectionBoldContent}>
                Reduce the Financial Burden of Therapy
              </Typography>
              <ul className={classes.listContainer}>
                <li>
                  <Typography className={classes.sectionContent}>
                    Become part of a nationwide network of therapists dedicated to alleviating the financial burden of
                    therapy for their clients.
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.sectionContent}>
                    Help individuals access the necessary treatment they require to overcome compulsive behavior(s).
                  </Typography>
                </li>
              </ul>
            </div>
            <div>
              <Typography component="h4" className={classes.sectionContent} style={{ color: primaryColor }}>
                Join Lit Path today as a participating therapist and be a catalyst for positive change in the lives of
                those seeking recovery. Together, we can create a more accessible and supportive environment for
                therapy.
                <br />
                <br />
                Register now and make a difference!
              </Typography>
            </div>
            <div className={classes.centerContainer}>
              <Link component={RouterLink} to="/therapist-form">
                <Button className={classes.button}>Register as a Therapist </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <Typography component="h2" className={classes.sectionTitle}>
            Requirements for Participation
          </Typography>
          <div style={{ maxWidth: '868px' }}>
            <Typography className={classes.sectionContent}>
              We are seeking individuals who possess a deep passion for helping individuals struggling with compulsive
              behavior(s). This requirement emphasizes the significance of empathy, compassion, and a genuine commitment
              to supporting individuals on their recovery journey. Our program values therapists who are driven by a
              strong desire to make a positive impact and contribute to the well-being of those facing compulsive
              behavior(s) challenges.
            </Typography>
          </div>
          <div className={classes.requirementSection}>
            <RequirementBox
              header="Licensure and Registration"
              description="Hold a valid license/registration in the state where you practice."
            />
            <RequirementBox
              header="Passion for Recovery"
              description="Possess a deep passion for helping individuals struggling with compulsive behavior(s)."
            />
            <RequirementBox
              header="Expertise and Professional Development"
              description="Hold a specialized certification in treating a compulsive behavior such as CAP, CSAT, CGAC, CEDS, ICGC, etc., or be actively working towards certification."
            />
            <RequirementBox
              header="Experience and Empathy"
              description="The ideal candidate should be able to empathize with their clients. Although not required, preferred candidates should have at least 5 years in recovery themselves."
            />
          </div>
        </div>
        <div className={classes.section}>
          <Typography component="h2" className={classes.sectionTitle}>
            Registration Process
          </Typography>
          <div className={classes.restrictWidth}>
            <TherapistProcess />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.splitContainer}>
            <Typography className={classes.sectionContent} align="left">
              Help us spread the word about the amazing work of Lit Path by sharing your experience with others.
              Together, we can inspire hope and support more individuals on their recovery journeys.
            </Typography>
            <img
              align="center"
              className={classes.helpUsImg}
              src={helpUsImage}
              alt="a hand reaching out to another hand"
            />
          </div>
          <div>
            <Link component={RouterLink} to="/therapist-form">
              <Button className={classes.button}>Register as a Therapist </Button>
            </Link>
          </div>
        </div>
        <div className={classes.section}>
          <Typography component="h2" className={classes.sectionTitle}>
            Frequently Asked Questions
          </Typography>
          <div className={classes.restrictWidth}>
            {faqItems.map((faq, index) => (
              <FaqBox key={index} {...faq} />
            ))}
            <div style={{ width: '100%', height: '2px', color: 'white', marginTop: '-2px', zIndex: '2' }} />
          </div>
        </div>

        <div className={classes.section}>
          <Typography component="h2" className={classes.sectionTitle}>
            Still Have Questions?
          </Typography>
          <Link key="/contact" component={RouterLink} to="/contact" style={{ margin: 'auto' }}>
            <Button className={classes.button} style={{ width: '165px' }}>
              Contact Us
            </Button>
          </Link>
        </div>
      </div>
      <TherapistAppMobile />
    </HelpLayout>
  )
}
