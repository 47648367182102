import { Avatar, Box, Button, Modal, Typography, makeStyles } from '@material-ui/core'
import phoneIcon from '../../assets/img/phone.svg'
import mailIcon from '../../assets/img/mail.svg'
import copyIcon from '../../assets/img/copy.svg'
import closeIcon from '../../assets/img/close.svg'
import React, { useState, useEffect } from 'react'
import { getPublicLicensedTherapist } from '../../utils/client-api'
import { formatTherapistData } from '../../utils/helper'

const primary500 = '#1B3666'
const secondary500 = '#D49F02'
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  closeButton: {
    height: 14,
    width: 14,
    position: 'absolute',
    right: 30,
    top: 42,
    cursor: 'pointer',
  },
  contactCard: {
    position: 'relative',
    width: '100%',
    height: 350,
    padding: '60px 24px',
    backgroundColor: 'white',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      width: 600,
      padding: '60px 50px',
    },
  },
  avatar: {
    width: 90,
    height: 90,
  },
  therapistName: {
    fontSize: 28,
    fontWeight: 700,
    color: secondary500,
    marginBottom: 8,
  },
  icon: {
    width: 21,
    height: 21,
  },
  copyButton: {
    width: 21,
    height: 21,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    backgroundColor: 'transparent',
    marginLeft: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
}))

const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }

  return null
}

const copyToClipboard = (string) => {
  navigator.clipboard.writeText(string)
}

export default function ContactCardModal({ handleClose, therapist, open, name }) {
  const classes = useStyles()
  const [publicLicensedTherapist, setPublicLicensedTherapist] = useState()
  const [messageInfo, setMessageInfo] = useState({})
  const [status, setStatus] = useState('loading')
  const location = `${therapist.WorkLocation.city}, ${therapist.WorkLocation.province} ${therapist.WorkLocation.postalCode}`
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getPublicLicensedTherapist(therapist.id)
        if (res) {
          try {
            setPublicLicensedTherapist(formatTherapistData(res))
            setStatus('success')
          } catch (error) {
            // console.log(error)
          }
        }
        setMessageInfo({
          id: therapist.id,
          toName: `${res.Person.firstName} ${res.Person.lastName}`,
          toEmail: res.email,
        })
      } catch (err) {
        setStatus('error')
      }
    }
    if (therapist.id) {
      fetchData()
    }
  }, [therapist])

  if (status === 'loading') {
    return null
  }
  if (status === 'error') {
    return (
      <>
        <Typography variant="h5">
          Uh oh! There were problems retrieving the therapist. <br />
          Please try again.
        </Typography>
      </>
    )
  }

  if (!publicLicensedTherapist) {
    return (
      <Typography variant="h5">
        Profile does not exist for that therapist. <br />
        Please try again.
      </Typography>
    )
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.contactCard}>
        <img src={closeIcon} alt={'close'} className={classes.closeButton} onClick={handleClose} />
        <Box sx={{ display: 'flex', gap: 25 }}>
          <Avatar src={therapist.Person.avatar} className={classes.avatar} />
          <Box>
            <Typography className={classes.therapistName}>{name}</Typography>
            <Typography style={{ fontSize: 14 }}>{therapist.professionName}</Typography>
            <Typography style={{ fontSize: 14 }}>{location}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          {therapist.phone && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={phoneIcon} alt={'phone'} className={classes.icon} style={{ marginRight: 6 }} />
              <Typography style={{ marginRight: 14, width: 50 }}>Phone</Typography>
              <Typography style={{ fontSize: 16, fontWeight: 700 }}>
                {formatPhoneNumber(publicLicensedTherapist.companyPhone)}
              </Typography>
              <button
                className={classes.copyButton}
                onClick={() => copyToClipboard(formatPhoneNumber(publicLicensedTherapist.companyPhone))}
              >
                <img src={copyIcon} className={classes.icon} style={{ width: 15, height: 15 }} />
              </button>
            </Box>
          )}
          {therapist.email && (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <img src={mailIcon} alt={'mail'} className={classes.icon} style={{ marginRight: 6 }} />
              <Typography style={{ marginRight: 14, width: 50 }}>Email</Typography>
              <Typography style={{ fontSize: 14, fontWeight: 700, textOverflow: 'wrap' }}>
                {publicLicensedTherapist.companyAddress.email}
              </Typography>
              <button
                className={classes.copyButton}
                onClick={() => copyToClipboard(publicLicensedTherapist.companyAddress.email)}
              >
                <img src={copyIcon} alt={'copy'} className={classes.icon} style={{ width: 15, height: 15 }} />
              </button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  )
}
